import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';

const GeneralModerator = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Game Links</div>
          </div>
          <div className='infosBoxSub'>
            <a href={routeNames.arenatournament} rel='noreferrer'>
              <Button className='btnStandardReverse'>Tournament</Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralModerator;

import * as React from 'react';
import { ButtonGroup, Button, Slider } from '@mui/material';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { Modal, Button as ReactButton } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { getTokensList, getTokens } from 'apiRequests';
import { jcorpWallet, tokensList, tokensWallet, tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import InventoriesDataService from '../../../services/inventories.service';
import ItemsDataService from '../../../services/items.service';
import SettingDataService from '../../../services/setting.service';
import ShopsDataService from '../../../services/shops.service';
import UserDataService from '../../../services/user.service';
import WalletsDataService from '../../../services/wallets.service';
import { IItemCreationData } from '../../../types/inventories.type';
import { ItemsStateType, IItemData } from '../../../types/items.type';
import { IShopData } from '../../../types/shops.type';
import { TokensStateType } from '../../../types/token.type';
import { WalletsStateType } from '../../../types/wallets.type';

const Marketplace = () => {
  const { userSession, userInfos, setUserInfos } = useAuthContext();

  const navigate = useNavigate();

  function MyTimerClaim({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { days, seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshClaim()
    });

    return (
      <span>
        {days > 0 && <span>{days}:</span>}
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  const maxItems = 50;

  const [selectedItem, setSelectedItem] = React.useState({
    divStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties,
    rarity: '',
    attack: false,
    defense: false,
    id_item: 0,
    item_type: 0,
    item_identifier: '',
    item_img: '',
    item_name_1: '',
    item_name_2: '',
    item_label: '',
    item_value: '',
    item_qty: 0
  });

  const [itemClaimable, setItemClaimable] = React.useState(0);
  const [claimTimerInfos, setClaimTimerInfos] = React.useState({
    nextClaim: new Date()
  });

  const [isTransaction, setIsTransaction] = React.useState(false);
  const [flgItemCreation, setFlgItemCreation] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [message, setMessage] = React.useState<string>();
  const [messageSuccess, setMessageSuccess] = React.useState<string>();
  const [itemQty, setItemQty] = React.useState(0);
  const [itemCreate, setItemCreate] = React.useState(0);

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setIsProcessing(false);
    setMessage('');
    setMessageSuccess('');
    setItemQty(0);
    setItemCreate(0);
    //if (isBlockchainAccount) fetchTokensBalanceData();
    fetchWalletsData();
  };

  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);
  const [transactionItem, setTransactionItem] =
    React.useState<IItemData | null>(null);

  const [shopAvailable, setShopAvailable] = React.useState<boolean>();

  const [showConquest, setShowConquest] = React.useState(false);
  const [showArena, setShowArena] = React.useState(false);
  const handleShowConquest = () => {
    setShowConquest(true);
    setShowArena(false);
  };
  const handleShowArena = () => {
    setShowConquest(false);
    setShowArena(true);
  };

  const [showRankedArena, setShowRankedArena] = React.useState(true);
  const [showChallengerArena, setShowChallengerArena] = React.useState(false);
  const handleShowRankedArena = () => {
    setShowRankedArena(true);
    setShowChallengerArena(false);
  };
  const handleShowChallengerArena = () => {
    setShowRankedArena(false);
    setShowChallengerArena(true);
  };

  const [listTokens, setListTokens] = React.useState<TokensStateType>({
    tokens: [],
    tokensFetched: undefined
  });
  const [accountBalance, setAccountBalance] = React.useState<any>();
  const [balanceTokens, setBalanceTokens] = React.useState<TokensStateType>({
    tokens: [],
    tokensFetched: undefined
  });
  const [isBalance, setIsBalance] = React.useState<boolean>();

  const [isFaction, setIsFaction] = React.useState<boolean>();
  const [walletsInfos, setWalletsInfos] = React.useState<WalletsStateType>({
    wallets: [],
    message: '',
    status: ''
  });
  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });

  function numHex(s: any) {
    let a = s.toString(16);
    if (a.length % 2 > 0) {
      a = '0' + a;
    }
    return a;
  }

  function strHex(s: any) {
    let a = '';
    for (let i = 0; i < s.length; i++) {
      a = a + numHex(s.charCodeAt(i));
    }
    return a;
  }

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'conquest';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setClaimTimerInfos({
          nextClaim: data.setting.nextPhase
        });
      }
    });

    params['name'] = 'idItemClaim';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setItemClaimable(data.setting.value);
      }
    });

    params['name'] = 'shopAvailable';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.setting.value == 0) {
          setShopAvailable(false);
        } else {
          setShopAvailable(true);
        }
      }
    });
  };

  const fetchTokensInfosData = () => {
    getTokensList({
      tokensList
    }).then(({ data, success }) => {
      setListTokens({
        tokens: data,
        tokensFetched: success
      });
    });
  };

  const fetchTokensBalanceData = async () => {
    const refreshAccountData = await refreshAccount();
    let refreshBalance = '0';
    if (refreshAccountData != null) {
      refreshBalance = refreshAccountData.balance;
    }

    let balance = '';
    const decimals = 10 ** 18;
    const balanceValue = parseInt(refreshBalance) / decimals;
    balance = balanceValue.toLocaleString('en-GB', {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    });
    setAccountBalance(balance);

    getTokens({
      address: userSession.userAccount,
      tokensWallet
    }).then(({ data, success }) => {
      setBalanceTokens({
        tokens: data,
        tokensFetched: success
      });
    });
  };

  const fetchWalletsData = () => {
    WalletsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setWalletsInfos({
          wallets: data.wallets,
          status: data.status
        });
      }
    });
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const handleChangeQuantity = (e: any, selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity = e.target.value;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  const handleIncrementQuantity = (selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity += 1;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  const handleDecrementQuantity = (selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity -= 1;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  //CheckStatus();

  async function CheckStatus() {
    const transactionStatus = useTrackTransactionStatus({
      transactionId: transactionSessionId
    });

    if (
      transactionSessionId != null &&
      transactionItem != null &&
      transactionStatus.transactions != null
    ) {
      if (
        transactionStatus.status == 'signed' ||
        transactionStatus.status == 'sent'
      ) {
        if (!isTransaction) {
          setIsTransaction(true);
          setShowModal(true);
          txCreation(transactionStatus.transactions[0]);
        }
      } else {
        if (isTransaction) {
          setIsTransaction(false);
          txUpdate(transactionStatus.transactions[0]);
        }
      }
    }
  }

  function txCreation(transaction: any) {
    if (transactionItem != null) {
      const amount = transactionItem.item_cost * transactionItem.item_quantity;

      const dataShopCreation: IShopData = {
        id_user: userSession.userID,
        id_item: transactionItem.id_item,
        quantity: transactionItem.item_quantity,
        amount: amount,
        token_identifier: transactionItem.token_identifier,
        tx: transaction.hash,
        status: transaction.status,
        flg_blockchain: true
      };

      ShopsDataService.create(dataShopCreation);
    }
  }

  function txUpdate(transaction: any) {
    if (transactionItem != null) {
      const params: any = {};
      params['txhash'] = transaction.hash;

      ShopsDataService.findOneByTxHash(params).then(async function ({ data }) {
        if (data.status == 'success') {
          if (data.shop.status == 'pending') {
            const dataShop: any = {
              status: transaction.status
            };

            await ShopsDataService.update(dataShop, data.shop.id_shop);

            if (transaction.status == 'success') {
              setFlgItemCreation(true);
              setItemQty(transactionItem.item_quantity);

              let itemsCreated = 0;
              const itemsQuantity = transactionItem.item_quantity;

              let num_item = userInfos.user.num_item;
              const new_num_item = num_item + itemsQuantity;

              const dataUser: any = {
                num_item: new_num_item
              };

              await UserDataService.update(dataUser, userSession.userID).then(
                ({ data }) => {
                  if (data.status == 'success') {
                    setUserInfos({
                      user: data.user,
                      status: data.status
                    });
                  }
                }
              );

              for (let j = 0; j < itemsQuantity; j++) {
                num_item++;

                const identifier =
                  transactionItem.item_identifier +
                  '-' +
                  userSession.userID +
                  '-' +
                  num_item;

                const dataItem: IItemCreationData = {
                  id_user: userSession.userID,
                  id_shop: data.shop.id_shop,
                  id_item: transactionItem.id_item,
                  identifier: identifier,
                  flg_used: false,
                  flg_delivered: true,
                  flg_claimed: false
                };

                await InventoriesDataService.create(dataItem);

                itemsCreated++;
                setItemCreate(itemsCreated);
              }
            } else {
              handleCloseModal();
            }
          }
        }
      });
    }
  }

  const handleBuyItem = async (item: IItemData) => {
    if (item.token_identifier == 'EGLD') {
      handleBuyItemEGLD(item);
    } else {
      //handleBuyItemESDT(item);
      handleShowConfirmModal(item);
    }
  };

  const handleBuyItemEGLD = async (item: IItemData) => {
    // ==> MAINNET
    const amount = item.item_cost * 10 ** 18;
    const amountEGLD = amount * item.item_quantity;

    // ==> TESTNET
    /*
    const devWallet =
      'erd1hyz6cv458yld6spfmnj7tnd62c5xapxqnh6vpg99ypf843ar5tzs9hmrg8';
      */

    const amountEGLDX = amountEGLD.toString();

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: [
        {
          value: amountEGLDX,
          gasLimit: 50000,
          receiver: jcorpWallet // ==> MAINNET
          //receiver: devWallet // ==> TESTNET
        }
      ],
      //callbackRoute: routeNames.wallet,
      //signWithoutSending: true,
      sessionInformation: {
        userId: userSession.userID,
        type: 'item',
        data: item
      },
      transactionsDisplayInfo: {
        processingMessage: `Purchase of ${item.item_name}`,
        successMessage: `${item.item_name} purchased`
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
      setTransactionItem(item);
    }
  };

  const [errorBalance, setErrorBalance] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = (item: IItemData) => {
    setShowConfirmModal(true);
    setTransactionItem(item);

    const divStyle = {
      '--rarityColor': item.item_rarityColor
    } as React.CSSProperties;
    const rarity = item.item_rarity;

    let attack = false;
    let defense = false;
    if (item.item_type == 2) {
      switch (item.flg_use) {
        case 1:
          attack = true;
          break;
        case 2:
          defense = true;
          break;
        default:
          attack = true;
          defense = true;
          break;
      }
    }

    const id_item = item.id_item;
    const item_type = item.item_type;
    const item_identifier = item.item_identifier;
    const item_img = item.item_img;

    const nameT = item.item_name.split(' ');

    let item_name_1 = '';
    let item_name_2 = '';
    switch (nameT.length) {
      case 1:
        item_name_1 = nameT[0];
        break;
      case 2:
        item_name_1 = nameT[0];
        item_name_2 = nameT[1];
        break;
      default:
        item_name_1 = nameT[0] + ' ' + nameT[1];
        item_name_2 = nameT[2];
        break;
    }

    let item_label = '';
    let item_value = '';
    if (item.item_type == 2) {
      item_label = 'Power';
      item_value = item.item_power.toFixed(2);
    }
    if (item.item_type == 4) {
      if (item.bonus_value > 0) {
        item_label = 'Faction Power';
        item_value = '+' + item.bonus_value + '%';
      } else {
        item_label = 'Enemy Power';
        item_value = item.bonus_value + '%';
      }
    }
    if (item.item_type == 5) {
      item_label = item_name_1;
      item_value = item.item_power.toString();

      if (item.id_item == 12) {
        item_label = 'HP';
      }
    }

    const item_qty = item.item_quantity;

    setSelectedItem({
      divStyle: divStyle,
      rarity: rarity,
      attack: attack,
      defense: defense,
      id_item: id_item,
      item_type: item_type,
      item_identifier: item_identifier,
      item_img: item_img,
      item_name_1: item_name_1,
      item_name_2: item_name_2,
      item_label: item_label,
      item_value: item_value,
      item_qty: item_qty
    });
  };

  const handleBuyItemWallet = () => {
    if (transactionItem != null) {
      setShowConfirmModal(false);
      setShowModal(true);
      txCreationWallet(transactionItem);
    }
  };

  const txCreationWallet = (item: IItemData) => {
    setIsProcessing(true);

    const dataShop: any = {
      id_user: userSession.userID,
      id_item: item.id_item,
      quantity: item.item_quantity
    };

    const dataWalletEvent: any = {
      group_operation: 200,
      type_operation: 210
    };

    const dataShopEvent: any = {
      shop: dataShop,
      walletEvent: dataWalletEvent
    };

    ShopsDataService.createEvent(dataShopEvent).then(({ data }) => {
      if (data.status == 'success') {
        setMessageSuccess(data.message);
      } else {
        setMessage(data.message);
      }
      setIsProcessing(false);
    });
  };

  const handleBuyItemESDT = async (item: IItemData) => {
    let tokenIdentifier = '';
    let tokenDecimals = 0;
    listTokens.tokens.map((token) => {
      if (item.token_identifier == token.identifier) {
        tokenIdentifier = token.identifier;
        tokenDecimals = token.decimals;
      }
    });

    // ==> MAINNET
    const amount = item.item_cost * item.item_quantity;
    const number = amount * 10 ** tokenDecimals;

    // ==> TESTNET
    /*
    const devWallet =
      'erd1hyz6cv458yld6spfmnj7tnd62c5xapxqnh6vpg99ypf843ar5tzs9hmrg8';
    const amount = 1 * item.item_quantity;
    const number = amount * 10 ** 18;
    tokenIdentifier = 'XSUPER-c19331';
    */

    const ESDT_hex = strHex(tokenIdentifier);
    const amount_hex = numHex(number);
    const dataESDT = 'ESDTTransfer' + '@' + ESDT_hex + '@' + amount_hex;

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: [
        {
          value: '0',
          gasLimit: 500000,
          data: btoa(dataESDT),
          receiver: jcorpWallet // ==> MAINNET
          //receiver: devWallet // ==> TESTNET
        }
      ],
      //callbackRoute: routeNames.wallet,
      //signWithoutSending: true,
      sessionInformation: {
        userId: userSession.userID,
        type: 'item',
        data: item
      },
      transactionsDisplayInfo: {
        processingMessage: `Purchase of ${item.item_name}`,
        successMessage: `${item.item_name} purchased`
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
      setTransactionItem(item);

      const divStyle = {
        '--rarityColor': item.item_rarityColor
      } as React.CSSProperties;
      const rarity = item.item_rarity;

      let attack = false;
      let defense = false;
      switch (item.flg_use) {
        case 1:
          attack = true;
          break;
        case 2:
          defense = true;
          break;
        default:
          attack = true;
          defense = true;
          break;
      }

      const id_item = item.id_item;
      const item_type = item.item_type;
      const item_identifier = item.item_identifier;
      const item_img = item.item_img;

      const nameT = item.item_name.split(' ');

      let item_name_1 = '';
      let item_name_2 = '';
      switch (nameT.length) {
        case 1:
          item_name_1 = nameT[0];
          break;
        case 2:
          item_name_1 = nameT[0];
          item_name_2 = nameT[1];
          break;
        default:
          item_name_1 = nameT[0] + ' ' + nameT[1];
          item_name_2 = nameT[2];
          break;
      }

      let item_label = '';
      let item_value = '';
      if (item.item_type == 2) {
        item_label = 'Power';
        item_value = item.item_power.toFixed(2);
      }
      if (item.item_type == 4) {
        if (item.bonus_value > 0) {
          item_label = 'Faction Power';
          item_value = '+' + item.bonus_value + '%';
        } else {
          item_label = 'Enemy Power';
          item_value = item.bonus_value + '%';
        }
      }

      const item_qty = item.item_quantity;

      setSelectedItem({
        divStyle: divStyle,
        rarity: rarity,
        attack: attack,
        defense: defense,
        id_item: id_item,
        item_type: item_type,
        item_identifier: item_identifier,
        item_img: item_img,
        item_name_1: item_name_1,
        item_name_2: item_name_2,
        item_label: item_label,
        item_value: item_value,
        item_qty: item_qty
      });
    }
  };

  const handleClaimItem = (item: IItemData) => {
    const dataItemClaim: any = {
      id_user: userSession.userID,
      id_item: item.id_item
    };

    ShopsDataService.createClaim(dataItemClaim).then(({ data }) => {
      if (data.status == 'success') {
        setUserInfos({
          user: data.user,
          status: data.status
        });
      }
    });
  };

  /*const handleCheckBalance = () => {
    if (
      listTokens.tokensFetched != undefined &&
      balanceTokens.tokensFetched != undefined
    ) {
      if (listTokens.tokensFetched && balanceTokens.tokensFetched) {
        setIsBalance(true);
      } else {
        setIsBalance(false);
      }
    }
  };*/

  /*const handleRefreshBalance = () => {
    fetchTokensInfosData();
    fetchTokensBalanceData();
  };*/

  const handleRefreshClaim = () => {
    fetchSettingData();
  };

  React.useEffect(() => {
    switch (userSession.gameMode) {
      case 'conquest':
        handleShowConquest();
        break;
      case 'arenaranked':
        handleShowArena();
        break;
      default:
        handleShowConquest();
        break;
    }
  }, []);

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (itemClaimable != 0) {
      /*
      if (isBlockchainAccount) {
        fetchTokensInfosData();
        fetchTokensBalanceData();
      } else {
        setAccountBalance(0);
      }
      */
      if (userInfos.user.id_faction == 0) {
        setIsFaction(false);
      } else {
        setIsFaction(true);
      }
      setAccountBalance(0);
      fetchItemsData();
    }
  }, [itemClaimable]);

  React.useEffect(() => {
    fetchWalletsData();
  }, []);

  /*React.useEffect(() => {
    handleCheckBalance();
  }, [listTokens, balanceTokens]);*/

  const { user } = userInfos;
  const { wallets } = walletsInfos;
  const { items } = itemsInfos;

  return (
    <>
      {/*<div className='card boxContent'>
            <div className='card-body text-center p-2'>
              <div className='titleContent'>
                <h3 className='py-2'>
                  <span className='mr-1 fantasy'>Market</span>
                </h3>
              </div>
            </div>
            {isBlockchainAccount ? (
              <React.Fragment>
                <div className='card-body text-center p-2'>{user.address}</div>
                <div className='card-body text-center p-2'>
                  Balance
                  <br />
                  {isBalance ? (
                    <div className='balanceInfos fantasy'>
                      {accountBalance} EGLD
                      {wallets.map((wallet, i) => {
                        let tokenName = '';

                        tokensListNames.map((token) => {
                          if (
                            token.token_identifier == wallet.token_identifier
                          ) {
                            tokenName = token.name;
                          }
                        });

                        const balance = wallet.amount.toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        return (
                          <span key={i}>
                            &nbsp;&nbsp; | &nbsp;&nbsp;{balance} {tokenName}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <React.Fragment>
                      {isBalance == false && (
                        <div className='balanceInfos fantasy'>
                          <div
                            className='buttonContentTab buttonRed fantasy'
                            onClick={() => handleRefreshBalance()}
                          >
                            Refresh Balance
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <div className='card-body text-center p-2'>
                Balance
                <br />
                <div className='balanceInfos fantasy'>
                  {wallets.map((wallet, i) => {
                    let tokenName = '';

                    tokensListNames.map((token) => {
                      if (token.token_identifier == wallet.token_identifier) {
                        tokenName = token.name;
                      }
                    });

                    const balance = wallet.amount.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    });

                    if (i == 0) {
                      return (
                        <span key={i}>
                          {balance} {tokenName}
                        </span>
                      );
                    } else {
                      return (
                        <span key={i}>
                          &nbsp;&nbsp; | &nbsp;&nbsp;{balance} {tokenName}
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </div>*/}

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Rise of Factions Balance</div>
            <div className='infosBalance'>
              {wallets.map((wallet, i) => {
                let tokenName = '';

                tokensListNames.map((token) => {
                  if (token.token_identifier == wallet.token_identifier) {
                    tokenName = token.name;
                  }
                });

                const balance = wallet.amount.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });

                if (i == 0) {
                  return (
                    <span key={i}>
                      {balance} {tokenName}
                    </span>
                  );
                } else {
                  return (
                    <span key={i}>
                      &nbsp;&nbsp; | &nbsp;&nbsp;{balance} {tokenName}
                    </span>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      {/*
          {showArena ? (
            <div className='boxContainer'>
              <div className='card-body text-center p-2'>
                {showRankedArena ? (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowRankedArena}
                    >
                      Ranked Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowChallengerArena}
                    >
                      Challenger Mode
                    </ReactButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowRankedArena}
                    >
                      Ranked Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowChallengerArena}
                    >
                      Challenger Mode
                    </ReactButton>
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : null}
          */}

      {shopAvailable == false && (
        <div className='boxContainer'>
          <div className='card-body p-2'>
            <div className='statusWarning'>
              The Market is currently unavailable.
              <br />
              Thank you for your understanding.
            </div>
          </div>
        </div>
      )}

      {shopAvailable && showConquest && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosClaim'>
              New item to claim in&nbsp;
              <MyTimerClaim
                expiryTimestamp={new Date(claimTimerInfos.nextClaim)}
              />
              &nbsp;!
            </div>
          </div>
        </div>
      )}

      {shopAvailable && showConquest && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='itemBlock'>
              {items.map((item, i) => {
                const divStyle = {
                  '--rarityColor': item.item_rarityColor
                } as React.CSSProperties;

                let attack = false;
                let defense = false;
                switch (item.flg_use) {
                  case 1:
                    attack = true;
                    break;
                  case 2:
                    defense = true;
                    break;
                  default:
                    attack = true;
                    defense = true;
                    break;
                }

                let claimable = false;
                if (item.id_item == itemClaimable) {
                  claimable = true;
                }

                const NFTscoreRounded = item.item_power.toFixed(2);

                const nameT = item.item_name.split(' ');

                let item_name_1;
                let item_name_2;
                switch (nameT.length) {
                  case 1:
                    item_name_1 = nameT[0];
                    break;
                  case 2:
                    item_name_1 = nameT[0];
                    item_name_2 = nameT[1];
                    break;
                  default:
                    item_name_1 = nameT[0] + ' ' + nameT[1];
                    item_name_2 = nameT[2];
                    break;
                }

                if (claimable) {
                  return (
                    <div key={i} style={divStyle} className='cardItemBlockShop'>
                      <div className='cardItemContainer'>
                        <div className='cardItemBox'>
                          <div className='cardItemBoxIn'>
                            <div className='cardItemInfos'>
                              <div className='cardItemHierarchy'>
                                {item.item_rarity}
                              </div>
                              <div className='cardItemPower'>
                                Power : {NFTscoreRounded}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='cardItemPictureBox'>
                          <img
                            src={`../${item.item_img}`}
                            className='cardItemPicture'
                          />
                        </div>
                        <div className='cardItemInfosBis'>
                          {!item_name_2 ? (
                            <div className='cardItemTitle'>{item_name_1}</div>
                          ) : (
                            <React.Fragment>
                              <div className='cardItemNumber'>
                                {item_name_1}
                              </div>
                              <div className='cardItemName'>{item_name_2}</div>
                            </React.Fragment>
                          )}
                        </div>
                        <div className='cardItemUsage'>
                          {attack && (
                            <IconContext.Provider
                              value={{ className: 'cardUsageAttack' }}
                            >
                              <GiCrossedSwords />
                            </IconContext.Provider>
                          )}
                          {defense && (
                            <IconContext.Provider
                              value={{ className: 'cardUsageDef' }}
                            >
                              <GiBorderedShield />
                            </IconContext.Provider>
                          )}
                        </div>
                      </div>
                      <div className='contentShopActions'>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Quantity</div>
                          <ButtonGroup
                            size='small'
                            className='contentShopBoxButtons'
                          >
                            <Button disabled>1</Button>
                          </ButtonGroup>
                        </div>
                        <div>
                          <Slider
                            size='small'
                            value={item.item_quantity}
                            aria-label='Small'
                            min={1}
                            max={1}
                          />
                        </div>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Price</div>
                          <div className='textImportant'>Free</div>
                        </div>
                        {!user.flg_claim ? (
                          <ReactButton
                            className='actionStandardSmall'
                            onClick={() => handleClaimItem(item)}
                          >
                            Claim
                          </ReactButton>
                        ) : (
                          <ReactButton className='actionStandardSmallDisable'>
                            Claimed
                          </ReactButton>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
              {items.map((item, i) => {
                const divStyle = {
                  '--rarityColor': item.item_rarityColor
                } as React.CSSProperties;

                let token_name = '';
                let balanceValue = 0;

                if (item.token_identifier == 'EGLD') {
                  token_name = 'EGLD';
                  balanceValue = accountBalance;
                } else {
                  tokensListNames.map((token) => {
                    if (item.token_identifier == token.token_identifier) {
                      token_name = token.name;
                    }
                  });

                  wallets.map((wallet) => {
                    if (item.token_identifier == wallet.token_identifier) {
                      balanceValue = wallet.amount;
                    }
                  });
                }

                let attack = false;
                let defense = false;
                switch (item.flg_use) {
                  case 1:
                    attack = true;
                    break;
                  case 2:
                    defense = true;
                    break;
                  default:
                    attack = true;
                    defense = true;
                    break;
                }

                const NFTscoreRounded = item.item_power.toFixed(2);

                const nameT = item.item_name.split(' ');

                let item_name_1;
                let item_name_2;
                switch (nameT.length) {
                  case 1:
                    item_name_1 = nameT[0];
                    break;
                  case 2:
                    item_name_1 = nameT[0];
                    item_name_2 = nameT[1];
                    break;
                  default:
                    item_name_1 = nameT[0] + ' ' + nameT[1];
                    item_name_2 = nameT[2];
                    break;
                }

                const price = item.item_cost * item.item_quantity;
                const itemCost = price.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });

                let buyDisabled = false;
                if (price > balanceValue) {
                  buyDisabled = true;
                }

                let minusDisabled = true;
                if (item.item_quantity > 1) minusDisabled = false;
                let plusDisabled = false;
                if (item.item_quantity >= maxItems) plusDisabled = true;

                let available = false;
                if (item.item_type == 2) {
                  available = true;
                }

                if (available) {
                  return (
                    <div key={i} style={divStyle} className='cardItemBlockShop'>
                      <div className='cardItemContainer'>
                        <div className='cardItemBox'>
                          <div className='cardItemBoxIn'>
                            <div className='cardItemInfos'>
                              <div className='cardItemHierarchy'>
                                {item.item_rarity}
                              </div>
                              <div className='cardItemPower'>
                                Power : {NFTscoreRounded}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='cardItemPictureBox'>
                          <img
                            src={`../${item.item_img}`}
                            className='cardItemPicture'
                          />
                        </div>
                        <div className='cardItemInfosBis'>
                          {!item_name_2 ? (
                            <div className='cardItemTitle'>{item_name_1}</div>
                          ) : (
                            <React.Fragment>
                              <div className='cardItemNumber'>
                                {item_name_1}
                              </div>
                              <div className='cardItemName'>{item_name_2}</div>
                            </React.Fragment>
                          )}
                        </div>
                        <div className='cardItemUsage'>
                          {attack && (
                            <IconContext.Provider
                              value={{ className: 'cardUsageAttack' }}
                            >
                              <GiCrossedSwords />
                            </IconContext.Provider>
                          )}
                          {defense && (
                            <IconContext.Provider
                              value={{ className: 'cardUsageDef' }}
                            >
                              <GiBorderedShield />
                            </IconContext.Provider>
                          )}
                        </div>
                      </div>
                      <div className='contentShopActions'>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Quantity</div>
                          <ButtonGroup
                            size='small'
                            className='contentShopBoxButtons'
                          >
                            {minusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                -
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleDecrementQuantity(item)}
                              >
                                -
                              </Button>
                            )}
                            <Button disabled>{item.item_quantity}</Button>
                            {plusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                +
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleIncrementQuantity(item)}
                              >
                                +
                              </Button>
                            )}
                          </ButtonGroup>
                        </div>
                        <div>
                          <Slider
                            size='small'
                            value={item.item_quantity}
                            aria-label='Small'
                            min={1}
                            max={maxItems}
                            onChange={(e) => handleChangeQuantity(e, item)}
                          />
                        </div>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Price</div>
                          <div className='textImportant'>
                            {itemCost} {token_name}
                          </div>
                        </div>
                        {!buyDisabled ? (
                          <ReactButton
                            className='actionStandardSmall'
                            onClick={() => handleBuyItem(item)}
                          >
                            Buy
                          </ReactButton>
                        ) : (
                          <ReactButton className='actionStandardSmallDisable'>
                            Buy
                          </ReactButton>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}

      {shopAvailable && showArena && showRankedArena && (
        <div className='boxContainer'>
          <div className='card-body p-2'>
            <div className='itemBlock'>
              {items.map((item, i) => {
                const divStyle = {
                  '--rarityColor': item.item_rarityColor
                } as React.CSSProperties;

                let token_name = '';
                let balanceValue = 0;

                if (item.token_identifier == 'EGLD') {
                  token_name = 'EGLD';
                  balanceValue = accountBalance;
                } else {
                  tokensListNames.map((token) => {
                    if (item.token_identifier == token.token_identifier) {
                      token_name = token.name;
                    }
                  });

                  wallets.map((wallet) => {
                    if (item.token_identifier == wallet.token_identifier) {
                      balanceValue = wallet.amount;
                    }
                  });
                }

                const nameT = item.item_name.split(' ');

                let item_name_1;
                let item_name_2;
                switch (nameT.length) {
                  case 1:
                    item_name_1 = nameT[0];
                    break;
                  case 2:
                    item_name_1 = nameT[0];
                    item_name_2 = nameT[1];
                    break;
                  default:
                    break;
                }

                if (item.id_item == 12) {
                  item_name_1 = 'HP';
                }

                const price = item.item_cost * item.item_quantity;
                const itemCost = price.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 3
                });

                let buyDisabled = false;
                if (price > balanceValue) {
                  buyDisabled = true;
                }

                let minusDisabled = true;
                if (item.item_quantity > 1) minusDisabled = false;
                let plusDisabled = false;
                if (item.item_quantity >= maxItems) plusDisabled = true;

                let available = false;
                if (item.item_type == 5) {
                  available = true;
                }

                if (available) {
                  return (
                    <div key={i} style={divStyle} className='cardItemBlockShop'>
                      <div className='cardItemContainer'>
                        <div className='cardItemBox'>
                          <div className='cardItemBoxIn'>
                            <div className='cardItemInfos'>
                              <div className='cardArenaItemName'>
                                {item.item_name}
                              </div>
                              <div className='cardItemPower'>
                                <span className='bonusUp'>
                                  +{item.item_power}
                                </span>
                                &nbsp;{item_name_1}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='cardItemPictureBox'>
                          <img
                            src={`../${item.item_img}`}
                            className='cardItemPicture'
                          />
                        </div>
                      </div>
                      <div className='contentShopActions'>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Quantity</div>
                          <ButtonGroup
                            size='small'
                            className='contentShopBoxButtons'
                          >
                            {minusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                -
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleDecrementQuantity(item)}
                              >
                                -
                              </Button>
                            )}
                            <Button disabled>{item.item_quantity}</Button>
                            {plusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                +
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleIncrementQuantity(item)}
                              >
                                +
                              </Button>
                            )}
                          </ButtonGroup>
                        </div>
                        <div>
                          <Slider
                            size='small'
                            value={item.item_quantity}
                            aria-label='Small'
                            min={1}
                            max={maxItems}
                            onChange={(e) => handleChangeQuantity(e, item)}
                          />
                        </div>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Price</div>
                          <div className='textImportant'>
                            {itemCost} {token_name}
                          </div>
                        </div>
                        {!buyDisabled ? (
                          <ReactButton
                            className='actionStandardSmall'
                            onClick={() => handleBuyItem(item)}
                          >
                            Buy
                          </ReactButton>
                        ) : (
                          <ReactButton className='actionStandardSmallDisable'>
                            Buy
                          </ReactButton>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}

      {shopAvailable && showArena && showChallengerArena && (
        <div className='boxContainer'>
          <div className='card-body p-2'>
            <div className='itemBlock'>
              {items.map((item, i) => {
                const divStyle = {
                  '--rarityColor': item.item_rarityColor
                } as React.CSSProperties;

                let token_name = '';
                let balanceValue = 0;

                if (item.token_identifier == 'EGLD') {
                  token_name = 'EGLD';
                  balanceValue = accountBalance;
                } else {
                  tokensListNames.map((token) => {
                    if (item.token_identifier == token.token_identifier) {
                      token_name = token.name;
                    }
                  });

                  wallets.map((wallet) => {
                    if (item.token_identifier == wallet.token_identifier) {
                      balanceValue = wallet.amount;
                    }
                  });
                }

                const nameT = item.item_name.split(' ');

                let item_name_1;
                let item_name_2;
                switch (nameT.length) {
                  case 1:
                    item_name_1 = nameT[0];
                    break;
                  case 2:
                    item_name_1 = nameT[0];
                    item_name_2 = nameT[1];
                    break;
                  default:
                    item_name_1 = nameT[0] + ' ' + nameT[1];
                    item_name_2 = nameT[2];
                    break;
                }

                const price = item.item_cost * item.item_quantity;
                const itemCost = price.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 3
                });

                let buyDisabled = false;
                if (price > balanceValue) {
                  buyDisabled = true;
                }

                let minusDisabled = true;
                if (item.item_quantity > 1) minusDisabled = false;
                let plusDisabled = false;
                if (item.item_quantity >= maxItems) plusDisabled = true;

                let available = false;
                if (
                  item.token_identifier == 'EGLD' &&
                  userSession.isBlockchainAccount
                ) {
                  if (item.item_type == 3) {
                    available = true;
                  }
                }

                if (available) {
                  return (
                    <div key={i} style={divStyle} className='cardItemBlockShop'>
                      <div className='cardItemContainer'>
                        <div className='cardItemBox'>
                          <div className='cardItemBoxIn'></div>
                        </div>
                        <div className='cardItemPictureBox'>
                          <img
                            src={`../${item.item_img}`}
                            className='cardItemPicture'
                          />
                        </div>
                        <div className='cardItemInfosLite'>
                          {!item_name_2 ? (
                            <div className='cardItemTitle'>{item_name_1}</div>
                          ) : (
                            <React.Fragment>
                              <div className='cardItemNumber'>
                                {item_name_1}
                              </div>
                              <div className='cardItemName'>{item_name_2}</div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className='contentShopActions'>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Quantity</div>
                          <ButtonGroup
                            size='small'
                            className='contentShopBoxButtons'
                          >
                            {minusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                -
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleDecrementQuantity(item)}
                              >
                                -
                              </Button>
                            )}
                            <Button disabled>{item.item_quantity}</Button>
                            {plusDisabled ? (
                              <Button className='btnDisabled' disabled>
                                +
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleIncrementQuantity(item)}
                              >
                                +
                              </Button>
                            )}
                          </ButtonGroup>
                        </div>
                        <div>
                          <Slider
                            size='small'
                            value={item.item_quantity}
                            aria-label='Small'
                            min={1}
                            max={maxItems}
                            onChange={(e) => handleChangeQuantity(e, item)}
                          />
                        </div>
                        <div className='contentShopBox'>
                          <div className='textImportant'>Price</div>
                          <div className='textImportant'>
                            {itemCost} {token_name}
                          </div>
                        </div>
                        {!buyDisabled ? (
                          <ReactButton
                            className='actionStandardSmall'
                            onClick={() => handleBuyItem(item)}
                          >
                            Buy
                          </ReactButton>
                        ) : (
                          <ReactButton className='actionStandardSmallDisable'>
                            Buy
                          </ReactButton>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showConfirmModal}
        onHide={handleCloseConfirmModal}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Purchase</div>
          </div>
          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='itemBlock'>
                {selectedItem.item_type == 2 ? (
                  <div style={selectedItem.divStyle} className='cardItemBlock'>
                    <div className='cardItemContainer'>
                      <div className='cardItemBox'>
                        <div className='cardItemBoxIn'>
                          <div className='cardItemInfos'>
                            <div className='cardItemHierarchy'>
                              {selectedItem.rarity}
                            </div>
                            <div className='cardItemPower'>
                              {selectedItem.item_label} :&nbsp;
                              {selectedItem.item_value}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cardItemPictureBox'>
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                        <img
                          src={`../${selectedItem.item_img}`}
                          className='cardItemPicture'
                        />
                      </div>
                      <div className='cardItemInfosBis'>
                        {selectedItem.item_name_2 == '' ? (
                          <div className='cardItemTitle'>
                            {selectedItem.item_name_1}
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className='cardItemNumber'>
                              {selectedItem.item_name_1}
                            </div>
                            <div className='cardItemName'>
                              {selectedItem.item_name_2}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <div className='cardItemUsage'>
                        {selectedItem.attack && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageAttack' }}
                          >
                            <GiCrossedSwords />
                          </IconContext.Provider>
                        )}
                        {selectedItem.defense && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageDef' }}
                          >
                            <GiBorderedShield />
                          </IconContext.Provider>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {selectedItem.item_type == 5 ? (
                  <div style={selectedItem.divStyle} className='cardItemBlock'>
                    <div className='cardItemContainer'>
                      <div className='cardItemBox'>
                        <div className='cardItemBoxIn'>
                          <div className='cardItemInfos'>
                            <div className='cardArenaItemName'>
                              {selectedItem.item_name_1}
                              &nbsp;{selectedItem.item_name_2}
                            </div>
                            <div className='cardItemPower'>
                              <span className='bonusUp'>
                                +{selectedItem.item_value}
                              </span>
                              &nbsp;{selectedItem.item_label}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cardItemPictureBox'>
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                        <img
                          src={`../${selectedItem.item_img}`}
                          className='cardItemPicture'
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {errorBalance ? (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusAlert'>
                  Not enough balance to make the purchase !
                </div>
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleBuyItemWallet}
                >
                  Validate
                </ReactButton>
                <ReactButton
                  className='btnStandardSmall'
                  onClick={handleCloseConfirmModal}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Purchase</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='itemBlock'>
                {selectedItem.item_type == 2 ? (
                  <div style={selectedItem.divStyle} className='cardItemBlock'>
                    <div className='cardItemContainer'>
                      <div className='cardItemBox'>
                        <div className='cardItemBoxIn'>
                          <div className='cardItemInfos'>
                            <div className='cardItemHierarchy'>
                              {selectedItem.rarity}
                            </div>
                            <div className='cardItemPower'>
                              {selectedItem.item_label} :&nbsp;
                              {selectedItem.item_value}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cardItemPictureBox'>
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                        <img
                          src={`../${selectedItem.item_img}`}
                          className='cardItemPicture'
                        />
                      </div>
                      <div className='cardItemInfosBis'>
                        {selectedItem.item_name_2 == '' ? (
                          <div className='cardItemTitle'>
                            {selectedItem.item_name_1}
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className='cardItemNumber'>
                              {selectedItem.item_name_1}
                            </div>
                            <div className='cardItemName'>
                              {selectedItem.item_name_2}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <div className='cardItemUsage'>
                        {selectedItem.attack && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageAttack' }}
                          >
                            <GiCrossedSwords />
                          </IconContext.Provider>
                        )}
                        {selectedItem.defense && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageDef' }}
                          >
                            <GiBorderedShield />
                          </IconContext.Provider>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {selectedItem.item_type == 5 ? (
                  <div style={selectedItem.divStyle} className='cardItemBlock'>
                    <div className='cardItemContainer'>
                      <div className='cardItemBox'>
                        <div className='cardItemBoxIn'>
                          <div className='cardItemInfos'>
                            <div className='cardArenaItemName'>
                              {selectedItem.item_name_1}
                              &nbsp;{selectedItem.item_name_2}
                            </div>
                            <div className='cardItemPower'>
                              <span className='bonusUp'>
                                +{selectedItem.item_value}
                              </span>
                              &nbsp;{selectedItem.item_label}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cardItemPictureBox'>
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                        <img
                          src={`../${selectedItem.item_img}`}
                          className='cardItemPicture'
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {selectedItem.item_type == 3 ? (
                  <div style={selectedItem.divStyle} className='cardItemBlock'>
                    <div className='cardItemContainer'>
                      <div className='cardItemBox'>
                        <div className='cardItemBoxIn'></div>
                      </div>
                      <div className='cardItemPictureBox'>
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                        <img
                          src={`../${selectedItem.item_img}`}
                          className='cardItemPicture'
                        />
                      </div>
                      <div className='cardItemInfosLite'>
                        {selectedItem.item_name_2 != '' ? (
                          <div className='cardItemTitle'>
                            {selectedItem.item_name_1}
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className='cardItemNumber'>
                              {selectedItem.item_name_1}
                            </div>
                            <div className='cardItemName'>
                              {selectedItem.item_name_2}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {isProcessing ? (
            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <div className='textImportant'>Creation in progress...</div>
              </div>
            </div>
          ) : null}

          {!isProcessing && message ? (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{message}</div>
              </div>
            </div>
          ) : null}

          {!isProcessing && messageSuccess ? (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusSuccess'>{messageSuccess}</div>
              </div>
            </div>
          ) : null}

          {!isProcessing ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <ReactButton
                    className='btnStandardSmallReverse'
                    onClick={handleCloseModal}
                  >
                    Close
                  </ReactButton>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Marketplace;

import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as BsNavbar, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import playIcon from 'assets/img/icons/mb_play_icon.svg';
import playIconActive from 'assets/img/icons/mb_play_icon_active.svg';
import inventoryIcon from 'assets/img/icons/mb_inventory_icon.svg';
import inventoryIconActive from 'assets/img/icons/mb_inventory_icon_active.svg';
import marketplaceIcon from 'assets/img/icons/mb_marketplace_icon.svg';
import marketplaceIconActive from 'assets/img/icons/mb_marketplace_icon_active.svg';
import factionIcon from 'assets/img/icons/mb_faction_icon.svg';
import factionIconActive from 'assets/img/icons/mb_faction_icon_active.svg';

const NavbarSubGame = ({
  gameModeTitle,
  gameModePath
}: {
  gameModeTitle: string;
  gameModePath: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const path_array = pathname.split('/');
  let current_path = '';

  switch (pathname) {
    case '/arena/ranked':
      current_path = pathname;
      break;
    default:
      current_path = '/' + path_array[1];
      break;
  }

  const mbNavRoutes = [
    {
      id: 1,
      img: playIcon,
      actImg: playIconActive,
      path: gameModePath,
      title: 'Play'
    },
    {
      id: 2,
      img: inventoryIcon,
      actImg: inventoryIconActive,
      path: routeNames.inventory,
      title: 'Inventory'
    },
    {
      id: 3,
      img: marketplaceIcon,
      actImg: marketplaceIconActive,
      path: routeNames.market,
      title: 'Market'
    },
    {
      id: 4,
      img: factionIcon,
      actImg: factionIconActive,
      path: routeNames.leaderboard,
      title: 'Leaderboard'
    }
  ];

  const rankedArenaWrapperRef = React.useRef<any>();
  const [leftOfDropDown, setleftOfDropDown] = React.useState(0);
  const [dropDownToglle, setDropDownToglle] = React.useState(false);

  const handleNavigate = (url: string) => {
    setDropDownToglle(false);
    navigate(url);
  };

  const handleDropDownLeft = () => {
    const { left } = rankedArenaWrapperRef.current.getBoundingClientRect();
    setleftOfDropDown(left);
  };

  React.useEffect(() => {
    handleDropDownLeft();
  }, []);

  return (
    <>
      <div className='navGroupBottomMainDiv'>
        <BsNavbar expand='lg' id={'navGroupBottomPart'}>
          <div className='navGroupBottomPartIn'>
            <Container className='h-100'>
              <div className='bottom_nav_wrapper'>
                <div
                  ref={rankedArenaWrapperRef}
                  className='ranked_Arena_wrapper h-100'
                  onClick={() => {
                    setDropDownToglle(!dropDownToglle);
                    handleDropDownLeft();
                  }}
                >
                  <h2>{gameModeTitle}</h2>
                  <div className='icon-box'>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className='fa-2x icon'
                    />
                  </div>
                </div>
                <ul className='d-flex navGroupBottomPartNavigation_lists'>
                  <li onClick={() => handleNavigate(gameModePath)}>Play</li>
                  <li onClick={() => handleNavigate(routeNames.inventory)}>
                    Inventory
                  </li>
                  <li onClick={() => handleNavigate(routeNames.market)}>
                    Market
                  </li>
                  <li onClick={() => handleNavigate(routeNames.leaderboard)}>
                    Leaderboard
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </BsNavbar>
        <ul
          style={{ left: leftOfDropDown }}
          className={`navBottomHeadingDropDown ${dropDownToglle && 'active'}`}
        >
          {routeNames.conquest != gameModePath ? (
            <li onClick={() => handleNavigate(routeNames.conquest)}>
              Conquest
            </li>
          ) : null}
          {routeNames.arenaranked != gameModePath ? (
            <li onClick={() => handleNavigate(routeNames.arenaranked)}>
              Ranked Arena
            </li>
          ) : null}
          {/*
          {routeNames.arenatournament != gameModePath ? (
            <li onClick={() => handleNavigate(routeNames.arenatournament)}>
              Tournament Arena
            </li>
          ) : null}
          */}
        </ul>
      </div>

      <div className='navGroupBottomMainDivInMobile'>
        <ul>
          {mbNavRoutes.map((route) => (
            <li
              key={route.id}
              className={`${current_path == route.path && 'active'}`}
              onClick={() => navigate(route.path)}
            >
              <img
                src={current_path === route.path ? route.actImg : route.img}
                alt='routes'
              />
              <h6>{route.title}</h6>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default NavbarSubGame;

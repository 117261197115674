import * as React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, MenuItem } from '@mui/material';
import { OverlayTrigger, Tooltip, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { initScore, leaderboardMaxRanking } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import ArenasRewardsLogsDataService from '../../../services/arenasrewardslogs.service';
import FactionDataService from '../../../services/faction.service';
import LeaderboardsBonusDataService from '../../../services/leaderboardsbonus.service';
import LeaderboardsDatasDataService from '../../../services/leaderboardsdatas.service';
import LeaderboardsLogsDataService from '../../../services/leaderboardslogs.service';
import SettingDataService from '../../../services/setting.service';
import { ArenasRankedRewardsStateType } from '../../../types/arenasrewardslogs.type';
import { FactionsStateType } from '../../../types/faction.type';
import {
  LeaderboardsBonusStateType,
  leaderboardsBonusEmpty
} from '../../../types/leaderboardsbonus.type';
import { LeaderboardsDatasStateType } from '../../../types/leaderboardsdatas.type';
import { LeaderboardsLogsStateType } from '../../../types/leaderboardslogs.type';
import angelic from './../../../assets/img/angelic.png';
import meiyo from './../../../assets/img/meiyo.png';
import night from './../../../assets/img/night.png';

const tabItems = [
  { id: 0, title: 'Top 50 Ranking' },
  { id: 1, title: 'My Ranking' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Conquest = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const seasonBonusStart = 11;
  const seasonArenaEnd = 17;
  const [idLeaderboard, setIdLeaderboard] = React.useState(0);
  const [currentLeaderboard, setCurrentLeaderboard] = React.useState(0);

  const [idUser, setIdUser] = React.useState(0);
  const [pseudoUser, setPseudoUser] = React.useState('');
  const [showUserRanking, setShowUserRanking] = React.useState(false);

  const handleSelectUser = (id_user: number, pseudo: string) => {
    if (userSession.userID == id_user) {
      setActiveTab(1);
      setIdUser(userSession.userID);
      setShowTopRanking(false);
      setShowMyRanking(true);
    } else {
      setIdUser(id_user);
      setPseudoUser(pseudo);
      setShowTopRanking(false);
      setShowMyRanking(true);
      setShowUserRanking(true);
    }

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleBackTopRanking = () => {
    setIdUser(0);
    setPseudoUser('');
    setShowTopRanking(true);
    setShowMyRanking(false);
    setShowUserRanking(false);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const [activeTab, setActiveTab] = React.useState(0);
  const [showTopRanking, setShowTopRanking] = React.useState(true);
  const [showMyRanking, setShowMyRanking] = React.useState(false);
  const handleShow = (id: number) => {
    setActiveTab(id);

    switch (id) {
      case 0:
        setShowTopRanking(true);
        setShowMyRanking(false);
        break;
      case 1:
        setIdUser(userSession.userID);
        setShowTopRanking(false);
        setShowMyRanking(true);
        break;
    }
  };

  const handleShowTopRanking = () => {
    setShowTopRanking(true);
    setShowMyRanking(false);
  };
  const handleShowMyRanking = () => {
    setIdUser(userSession.userID);
    setShowTopRanking(false);
    setShowMyRanking(true);
  };

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });

  const [leaderboardsIds, setLeaderboardsIds] =
    React.useState<LeaderboardsLogsStateType>({
      leaderboards_logs: [],
      message: '',
      status: ''
    });
  const [leaderboardsInfos, setLeaderboardsInfos] =
    React.useState<LeaderboardsLogsStateType>({
      leaderboards_logs: [],
      message: '',
      status: ''
    });
  const [leaderboardsDatas, setLeaderboardsDatas] =
    React.useState<LeaderboardsDatasStateType>({
      leaderboards_datas: [],
      message: '',
      status: ''
    });
  const [leaderboardsBonus, setLeaderboardsBonus] =
    React.useState<LeaderboardsBonusStateType>({
      leaderboards_bonus: leaderboardsBonusEmpty,
      message: '',
      status: ''
    });
  const [arenasRewardsLogs, setArenasRewardsLogs] =
    React.useState<ArenasRankedRewardsStateType>({
      arenas_rewards_logs: [],
      message: '',
      status: ''
    });

  const [userRanked, setUserRanked] = React.useState(false);

  const [currentPoints, setCurrentPoints] = React.useState(0);

  const [bonusInfos, setBonusInfos] = React.useState({
    totalBonusPoints: 0,
    ratioExpIncBonusPoints: 0,
    expenseBonusPoints: 0,
    battleBonusPoints: 0,
    arenaBonusPoints: 0,
    tokenName: '',
    incomeAmount: '',
    expenseAmount: '',
    ratioExpInc: 0,
    battleParticipation: 0,
    arenaParticipation: 0
  });

  const [rankedInfos, setRankedInfos] = React.useState({
    rankedArenaPoints: 0,
    nbRankedTotal: 0,
    nbRankedWin: 0,
    nbRankedWinPoints: 0,
    nbRankedLose: 0,
    nbRankedLosePoints: 0,
    ratioWin: '',
    ratioLose: ''
  });

  const [leaderboardsDatasTotal, setLeaderboardsDatasTotal] = React.useState({
    area_win_points: 0,
    area_loose_points: 0,
    faction_points: 0,
    total_points: 0,
    deploy_phase: 0,
    battle_phase: 0,
    total_participation: 0
  });

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'leaderboard';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setIdLeaderboard(data.setting.value);
        setCurrentLeaderboard(data.setting.value);
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsIdData = () => {
    LeaderboardsLogsDataService.findAllLeaderboardId().then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsIds({
          leaderboards_logs: data.leaderboards_logs,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsLogsData = () => {
    LeaderboardsLogsDataService.findAllByLeaderboard(idLeaderboard).then(
      ({ data }) => {
        if (data.status == 'success') {
          setLeaderboardsInfos({
            leaderboards_logs: data.leaderboards_logs,
            status: data.status
          });
        }
      }
    );
  };

  const fetchLeaderboardsDatasData = () => {
    const params: any = {};
    params['id_leaderboard'] = idLeaderboard;
    params['id_user'] = idUser;

    LeaderboardsDatasDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsDatas({
          leaderboards_datas: data.leaderboards_datas,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsBonusData = () => {
    const params: any = {};
    params['id_leaderboard'] = idLeaderboard;
    params['id_user'] = idUser;

    LeaderboardsBonusDataService.findOneByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsBonus({
          leaderboards_bonus: data.leaderboards_bonus,
          status: data.status
        });
      } else {
        setLeaderboardsBonus({
          leaderboards_bonus: leaderboardsBonusEmpty,
          message: data.message,
          status: 'success'
        });
      }
    });
  };

  const fetchArenasRewardsLogsData = () => {
    const params: any = {};
    params['id_leaderboard'] = idLeaderboard;
    params['id_user'] = idUser;

    ArenasRewardsLogsDataService.findAllRankedByUser(params).then(
      ({ data }) => {
        if (data.status == 'success') {
          setArenasRewardsLogs({
            arenas_rewards_logs: data.arenas_rewards_logs,
            status: data.status
          });
        }
      }
    );
  };

  const fetchLeaderboardsDatasTotal = () => {
    let isRanked = false;
    leaderboardsInfos.leaderboards_logs.map((leaderboards_log) => {
      if (leaderboards_log.id_user == idUser) {
        isRanked = true;
      }
    });

    const tokenName = 'JCORP';
    const bonus = leaderboardsBonus.leaderboards_bonus;

    const totalBonusPoints =
      bonus.ratio_points +
      bonus.expense_points +
      bonus.battle_points +
      bonus.arena_points;
    const ratioExpIncBonusPoints = bonus.ratio_points;
    const expenseBonusPoints = bonus.expense_points;
    const battleBonusPoints = bonus.battle_points;
    const arenaBonusPoints = bonus.arena_points;
    const incomeAmount = bonus.income_amount;
    const expenseAmount = bonus.expense_amount;
    const ratioExpInc = bonus.ratio_exp_inc;
    const battleParticipation = bonus.battle_participation;
    const arenaParticipation = bonus.arena_participation;

    let area_win_points = 0;
    let area_loose_points = 0;
    let faction_points = 0;
    let total_points = 0;
    let deploy_phase = 0;
    let battle_phase = 0;
    let total_participation = 0;
    leaderboardsDatas.leaderboards_datas.map((leaderboards_data) => {
      area_win_points += leaderboards_data.area_win_points;
      area_loose_points -= leaderboards_data.area_loose_points;
      area_win_points += leaderboards_data.area_bonus_points;
      faction_points += leaderboards_data.faction_points;
      total_points += leaderboards_data.total_points;
      if (leaderboards_data.deploy_phase) {
        deploy_phase++;
      }
      if (leaderboards_data.battle_phase) {
        battle_phase++;
      }
      total_participation += leaderboards_data.total_participation;
    });

    let rankedArenaPoints = 0;
    let nbRankedTotal = 0;
    let nbRankedWin = 0;
    let nbRankedWinPoints = 0;
    let nbRankedLose = 0;
    let nbRankedLosePoints = 0;
    arenasRewardsLogs.arenas_rewards_logs.map((reward) => {
      rankedArenaPoints += reward.points;

      nbRankedTotal++;
      if (reward.points > 0) {
        nbRankedWin++;
        nbRankedWinPoints += reward.points;
      } else {
        nbRankedLose++;
        nbRankedLosePoints += reward.points;
      }
    });
    const ratioWin = ((nbRankedWin / nbRankedTotal) * 100).toFixed(2);
    const ratioLose = ((nbRankedLose / nbRankedTotal) * 100).toFixed(2);

    setUserRanked(isRanked);

    const curPoints =
      initScore + total_points + rankedArenaPoints + totalBonusPoints;
    setCurrentPoints(curPoints);

    setBonusInfos({
      totalBonusPoints: totalBonusPoints,
      ratioExpIncBonusPoints: ratioExpIncBonusPoints,
      expenseBonusPoints: expenseBonusPoints,
      battleBonusPoints: battleBonusPoints,
      arenaBonusPoints: arenaBonusPoints,
      tokenName: tokenName,
      incomeAmount: incomeAmount.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      expenseAmount: expenseAmount.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      ratioExpInc: ratioExpInc,
      battleParticipation: battleParticipation,
      arenaParticipation: arenaParticipation
    });

    setRankedInfos({
      rankedArenaPoints: rankedArenaPoints,
      nbRankedTotal: nbRankedTotal,
      nbRankedWin: nbRankedWin,
      nbRankedWinPoints: nbRankedWinPoints,
      nbRankedLose: nbRankedLose,
      nbRankedLosePoints: nbRankedLosePoints,
      ratioWin: ratioWin,
      ratioLose: ratioLose
    });

    setLeaderboardsDatasTotal({
      area_win_points: area_win_points,
      area_loose_points: area_loose_points,
      faction_points: faction_points,
      total_points: total_points,
      deploy_phase: deploy_phase,
      battle_phase: battle_phase,
      total_participation: total_participation
    });
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    fetchLeaderboardsIdData();
    fetchFactionsData();
  }, []);

  React.useEffect(() => {
    if (idLeaderboard != 0) {
      fetchLeaderboardsLogsData();
    }
  }, [idLeaderboard]);

  React.useEffect(() => {
    if (idUser != 0) {
      fetchLeaderboardsDatasData();
      fetchLeaderboardsBonusData();
      fetchArenasRewardsLogsData();
    }
  }, [idLeaderboard, idUser]);

  React.useEffect(() => {
    if (
      leaderboardsInfos.status == 'success' &&
      leaderboardsDatas.status == 'success' &&
      leaderboardsBonus.status == 'success' &&
      arenasRewardsLogs.status == 'success'
    ) {
      fetchLeaderboardsDatasTotal();
    }
  }, [
    leaderboardsInfos,
    leaderboardsDatas,
    leaderboardsBonus,
    arenasRewardsLogs
  ]);

  const { factions } = factionsInfos;
  const { leaderboards_logs } = leaderboardsInfos;
  const { leaderboards_datas } = leaderboardsDatas;

  return (
    <>
      <div className='boxContainer topArenaContainer' ref={refToTop}>
        <div className='stripContainerLeft'>
          <div className='stripBoxLeft'>
            <div className='stripBoxLeftRank'>
              <div className='stripInfos'>
                <div className='stripInfosIn'>
                  <div className='stripInfosText'>Season {idLeaderboard}</div>
                </div>
              </div>
            </div>
            <div className='stripBoxLeftList'>
              <div className='text-center'>
                <Form.Group>
                  <Form.Row>
                    <Col xs='auto'>
                      <TextField
                        select
                        value={idLeaderboard}
                        variant='standard'
                        onChange={(e) =>
                          setIdLeaderboard(parseInt(e.target.value))
                        }
                      >
                        <MenuItem value={currentLeaderboard}>
                          Current Season
                        </MenuItem>
                        {leaderboardsIds.leaderboards_logs.map((row, i) => {
                          if (
                            row.id_leaderboard != currentLeaderboard &&
                            i < 5
                          ) {
                            return (
                              <MenuItem key={i} value={row.id_leaderboard}>
                                Season {row.id_leaderboard}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                    </Col>
                  </Form.Row>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className='boxContainer'>
          <div className='inventoryTabsWrapper'>
            <div className='inventoryTabsLine'></div>
            {showUserRanking ? (
              <div className='inventoryTabsItemsWrapper'>
                <div
                  className='inventoryTabsItems inventoryTabsItemsActive'
                  onClick={handleBackTopRanking}
                >
                  Back
                </div>
              </div>
            ) : (
              <div className='inventoryTabsItemsWrapper'>
                {tabItems.map((item) => (
                  <div
                    className={classNames(
                      item.id == activeTab
                        ? 'inventoryTabsItems inventoryTabsItemsActive'
                        : 'inventoryTabsItems'
                    )}
                    onClick={() => handleShow(item.id)}
                    key={item.id}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            <div className='inventoryTabsLine'></div>
          </div>
        </div>
      </div>

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosBox'>
              {userSession.userID == idUser ? (
                <div className='infosSubTitle'>My Ranking</div>
              ) : (
                <div className='infosSubTitle'>{pseudoUser} Ranking</div>
              )}
            </div>
            <div className='table-responsive text-center'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Rank</th>
                    <th className='border-0'>User</th>
                    <th className='border-0 iconCol'>Faction</th>
                    <th className='border-0'></th>
                    <th className='border-0'>Score</th>
                    <th className='border-0'>Participation</th>
                    <th className='border-0'>Power</th>
                  </tr>
                </thead>
                {leaderboards_logs.length == 0 || !userRanked ? (
                  <tbody>
                    <tr className='rowTableContent textResult'>
                      <td colSpan={7}>
                        <span>No leaderboard ranking</span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {leaderboards_logs.map((leaderboards_log, i) => {
                      let isUser = false;
                      let factionName = '';
                      let factionImg = '';

                      if (leaderboards_log.id_user == idUser) {
                        isUser = true;

                        factions.map((faction) => {
                          if (
                            faction.id_faction == leaderboards_log.id_faction
                          ) {
                            factionName = faction.name;
                          }
                        });

                        switch (leaderboards_log.id_faction) {
                          case 1:
                            factionImg = angelic;
                            break;
                          case 2:
                            factionImg = night;
                            break;
                          case 3:
                            factionImg = meiyo;
                            break;
                        }
                      }

                      if (isUser) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{leaderboards_log.rank}</span>
                            </td>
                            <td>
                              <span>{leaderboards_log.user.pseudo}</span>
                            </td>
                            <td>
                              <span>{factionName}</span>
                            </td>
                            <td>
                              <img
                                src={`${factionImg}`}
                                className='imgFactionProfile'
                              />
                            </td>
                            <td className='textBold'>
                              <span>{leaderboards_log.score}</span>
                            </td>
                            <td>
                              <span>{leaderboards_log.participation}</span>
                            </td>
                            <td>
                              <span>
                                {leaderboards_log.tot_power.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}

      {showMyRanking &&
        idLeaderboard < seasonBonusStart &&
        idLeaderboard < seasonArenaEnd && (
          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='dataContainer'>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Ranking Information</div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Initial Points</span>
                    <span className='dataAttribut'>{initScore}</span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Conquests Points</span>
                    <span className='dataAttribut'>
                      {leaderboardsDatasTotal.total_points}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Ranked Arena Points</span>
                    <span className='dataAttribut'>
                      {rankedInfos.rankedArenaPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Current Points</span>
                    <span className='dataAttribut'>{currentPoints}</span>
                  </div>
                </div>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Ranked Arena</div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Fights</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedTotal}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Win</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedWin}
                      {rankedInfos.nbRankedTotal > 0 ? (
                        <span>&nbsp;({rankedInfos.ratioWin} %)</span>
                      ) : null}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Points won</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedWinPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Lose</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedLose}
                      {rankedInfos.nbRankedTotal > 0 ? (
                        <span>&nbsp;({rankedInfos.ratioLose} %)</span>
                      ) : null}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Points lost</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedLosePoints}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {showMyRanking &&
        idLeaderboard >= seasonBonusStart &&
        idLeaderboard < seasonArenaEnd && (
          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='dataContainer'>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Ranking Information</div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Initial Points</span>
                    <span className='dataAttribut'>{initScore}</span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Conquests Points</span>
                    <span className='dataAttribut'>
                      {leaderboardsDatasTotal.total_points}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Ranked Arena Points</span>
                    <span className='dataAttribut'>
                      {rankedInfos.rankedArenaPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Season Bonuses Points</span>
                    <span className='dataAttribut'>
                      {bonusInfos.totalBonusPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Current Points</span>
                    <span className='dataAttribut'>{currentPoints}</span>
                  </div>
                </div>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>
                      Season Information
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='tooltipConnect' className='textTooltip'>
                            Calculated daily
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className='iconInfo'
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Income</span>
                    <span className='dataAttribut'>
                      {bonusInfos.incomeAmount}
                      &nbsp;
                      {bonusInfos.tokenName}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Expense</span>
                    <span className='dataAttribut'>
                      {bonusInfos.expenseAmount}
                      &nbsp;
                      {bonusInfos.tokenName}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Expense / Revenue Ratio</span>
                    <span className='dataAttribut'>
                      {bonusInfos.ratioExpInc} %
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Conquest Battle Phase</span>
                    <span className='dataAttribut'>
                      {bonusInfos.battleParticipation}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Ranked Arena Fights</span>
                    <span className='dataAttribut'>
                      {bonusInfos.arenaParticipation}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {showMyRanking &&
        idLeaderboard >= seasonBonusStart &&
        idLeaderboard < seasonArenaEnd && (
          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='dataContainer'>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Ranked Arena</div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Fights</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedTotal}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Win</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedWin}
                      {rankedInfos.nbRankedTotal > 0 ? (
                        <span>&nbsp;({rankedInfos.ratioWin} %)</span>
                      ) : null}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Points won</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedWinPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Lose</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedLose}
                      {rankedInfos.nbRankedTotal > 0 ? (
                        <span>&nbsp;({rankedInfos.ratioLose} %)</span>
                      ) : null}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Points lost</span>
                    <span className='dataAttribut'>
                      {rankedInfos.nbRankedLosePoints}
                    </span>
                  </div>
                </div>
                <div className='dataBox'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>
                      Season Bonuses Points
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='tooltipConnect' className='textTooltip'>
                            Calculated at the end of the season
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className='iconInfo'
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Conquest Battle Phase</span>
                    <span className='dataAttribut'>
                      {bonusInfos.battleBonusPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Ranked Arena</span>
                    <span className='dataAttribut'>
                      {bonusInfos.arenaBonusPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Expense</span>
                    <span className='dataAttribut'>
                      {bonusInfos.expenseBonusPoints}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Expense / Revenue Ratio</span>
                    <span className='dataAttribut'>
                      {bonusInfos.ratioExpIncBonusPoints}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {showMyRanking && idLeaderboard >= seasonArenaEnd && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Ranking Information</div>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Initial Points</span>
                <span className='dataAttribut'>{initScore}</span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Conquests Points</span>
                <span className='dataAttribut'>
                  {leaderboardsDatasTotal.total_points}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Season Bonuses Points</span>
                <span className='dataAttribut'>
                  {bonusInfos.totalBonusPoints}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Current Points</span>
                <span className='dataAttribut'>{currentPoints}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && idLeaderboard >= seasonArenaEnd && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>
                    Season Information
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          Calculated daily
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Income</span>
                  <span className='dataAttribut'>
                    {bonusInfos.incomeAmount}
                    &nbsp;
                    {bonusInfos.tokenName}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Expense</span>
                  <span className='dataAttribut'>
                    {bonusInfos.expenseAmount}
                    &nbsp;
                    {bonusInfos.tokenName}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Expense / Revenue Ratio</span>
                  <span className='dataAttribut'>
                    {bonusInfos.ratioExpInc} %
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Conquest Battle Phase</span>
                  <span className='dataAttribut'>
                    {bonusInfos.battleParticipation}
                  </span>
                </div>
              </div>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>
                    Season Bonuses Points
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          Calculated at the end of the season
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Conquest Battle Phase</span>
                  <span className='dataAttribut'>
                    {bonusInfos.battleBonusPoints}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Expense</span>
                  <span className='dataAttribut'>
                    {bonusInfos.expenseBonusPoints}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Expense / Revenue Ratio</span>
                  <span className='dataAttribut'>
                    {bonusInfos.ratioExpIncBonusPoints}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosBox'>
              <div className='infosSubTitle'>Conquests</div>
            </div>
            <div className='table-responsive text-center'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Conquest</th>
                    <th className='border-0'>Won Areas Points</th>
                    <th className='border-0'>Lost Areas Points</th>
                    <th className='border-0'>Faction Points</th>
                    <th className='border-0'>Total Points</th>
                    <th className='border-0'>Deployment Phase</th>
                    <th className='border-0'>Battle Phase</th>
                    <th className='border-0'>Participation</th>
                  </tr>
                </thead>
                {leaderboards_datas.length == 0 ? (
                  <tbody>
                    <tr className='rowTableContent textResult'>
                      <td colSpan={8}>
                        <span>No information</span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {leaderboards_datas.map((lead_data, i) => {
                      const area_loose_points = 0 - lead_data.area_loose_points;

                      let deploy_phase = 'No';
                      let battle_phase = 'No';

                      if (lead_data.deploy_phase) {
                        deploy_phase = 'Yes';
                      }
                      if (lead_data.battle_phase) {
                        battle_phase = 'Yes';
                      }

                      return (
                        <tr key={i} className='rowTableContent textResult'>
                          <td className='textBold'>
                            <span>#{lead_data.id_conquest}</span>
                          </td>
                          <td>
                            <span>{lead_data.area_win_points}</span>
                            {lead_data.area_bonus_points > 0 && (
                              <span>&nbsp;+ {lead_data.area_bonus_points}</span>
                            )}
                          </td>
                          <td>
                            <span>{area_loose_points}</span>
                          </td>
                          <td>
                            <span>{lead_data.faction_points}</span>
                          </td>
                          <td className='textBold'>
                            <span>{lead_data.total_points}</span>
                          </td>
                          <td>
                            <span>{deploy_phase}</span>
                          </td>
                          <td>
                            <span>{battle_phase}</span>
                          </td>
                          <td className='textBold'>
                            <span>{lead_data.total_participation}</span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr className='headTable'>
                      <th className='border-0 textBold'>
                        <span>TOTAL</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.area_win_points}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.area_loose_points}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.faction_points}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.total_points}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.deploy_phase}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>{leaderboardsDatasTotal.battle_phase}</span>
                      </th>
                      <th className='border-0 textBold'>
                        <span>
                          {leaderboardsDatasTotal.total_participation}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}

      {showTopRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosBox'>
              <div className='infosSubTitle'>
                Top {leaderboardMaxRanking} Ranking
              </div>
            </div>
            <div className='table-responsive text-center'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Rank</th>
                    <th className='border-0'>User</th>
                    <th className='border-0 iconCol'>Faction</th>
                    <th className='border-0'></th>
                    <th className='border-0'>Score</th>
                    <th className='border-0'>Participation</th>
                    <th className='border-0'>Power</th>
                  </tr>
                </thead>
                {leaderboards_logs.length == 0 ? (
                  <tbody>
                    <tr className='rowTableContent textResult'>
                      <td colSpan={7}>
                        <span>No leaderboard ranking</span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {leaderboards_logs.map((leaderboards_log, i) => {
                      let factionName = '';
                      let factionImg = '';

                      factions.map((faction) => {
                        if (faction.id_faction == leaderboards_log.id_faction) {
                          factionName = faction.name;
                        }
                      });

                      switch (leaderboards_log.id_faction) {
                        case 1:
                          factionImg = angelic;
                          break;
                        case 2:
                          factionImg = night;
                          break;
                        case 3:
                          factionImg = meiyo;
                          break;
                      }

                      let classname;
                      if (userSession.userID == leaderboards_log.id_user) {
                        classname = 'rowTableSelfResult textResult';
                      } else {
                        classname = 'rowTableResult textResult';
                      }

                      if (i < leaderboardMaxRanking) {
                        return (
                          <tr
                            key={i}
                            className={classname}
                            onClick={() =>
                              handleSelectUser(
                                leaderboards_log.id_user,
                                leaderboards_log.user.pseudo
                              )
                            }
                          >
                            <td>
                              <span>{leaderboards_log.rank}</span>
                            </td>
                            <td>
                              <span>{leaderboards_log.user.pseudo}</span>
                            </td>
                            <td>
                              <span>{factionName}</span>
                            </td>
                            <td>
                              <img
                                src={`${factionImg}`}
                                className='imgFactionProfile'
                              />
                            </td>
                            <td className='textBold'>
                              <span>{leaderboards_log.score}</span>
                            </td>
                            <td>
                              <span>{leaderboards_log.participation}</span>
                            </td>
                            <td>
                              <span>
                                {leaderboards_log.tot_power.toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Conquest;

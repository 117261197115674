import * as React from 'react';
import { faFrown, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { xoxnoLink, deadrareLink } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../../services/attributs.service';
import UserDataService from '../../../services/user.service';
import {
  AttributsStateType,
  AttributsDisplayStateType
} from '../../../types/attributs.type';
import { hierarchyColor } from '../../../types/token.type';

const NFTsWallet = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userSession, userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [isLoading, setLoading] = React.useState(false);
  const [userPower, setUserPower] = React.useState(0);

  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });
  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const refreshUserAttributs = () => {
    setLoading(true);

    const params: any = {};
    params['address'] = userSession.userAccount;

    UserDataService.refreshUserAttributs(params).then(({ data }) => {
      if (data.status == 'success') {
        fetchNFTsData();
      }
    });
  };

  const fetchNFTsData = () => {
    AttributsDataService.findAllByUser(userInfos.user.id_user).then(
      ({ data }) => {
        if (data.status == 'success') {
          //data.attributs.sort((a, b) => b.nft_power - a.nft_power);
          setListNFTs({
            attributs: data.attributs,
            status: data.status
          });

          const nbPages = Math.ceil(data.attributs.length / pageSize);
          setPage(1);
          setTotalPages(nbPages);

          let NFTscore = 0;
          data.attributs.map((attribut) => {
            NFTscore += attribut.nft_power;
          });
          const NFTscoreRounded = parseFloat(NFTscore.toFixed(2));

          setUserPower(NFTscoreRounded);
          setLoading(false);
        }
      }
    );
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTs.attributs.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTs.attributs[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };

  React.useEffect(() => {
    if (userInfos.user.id_user != 0) {
      fetchNFTsData();
    }
  }, [userInfos]);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0 && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTs, page]);

  return (
    <>
      <div className='smallBox'>
        <div className='boxContainer'>
          <div className='card-body p-2'>
            <div className='menuWrapper'>
              <div className='menuBackTitle'>
                <span>My NFTs</span>
              </div>

              <div
                className='menuBack'
                onClick={() => navigate(routeNames.account)}
              >
                <span className='menuBackIcon'>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>

      {userSession.isBlockchainAccount ? (
        <div className='smallBox'>
          <div className='infosBoxAccount'>
            <div className='buttonsNFTContainer'>
              {isLoading ? (
                <React.Fragment>
                  <Button disabled className='btnStandard'>
                    Loading...
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className='btnStandardReverse'
                    onClick={refreshUserAttributs}
                  >
                    Synchronize
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className='boxContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Total Power</div>
            <div className='infosTitle'>{userPower.toFixed(2)}</div>
          </div>
          {/*{userSession.isBlockchainAccount ? (
            <div className='infosBoxSub'>
              <div className='buttonsNFTContainer'>
                <Button
                  className='btnStandardReverse'
                  onClick={() => navigate(routeNames.mint)}
                >
                  Mint Heroes
                </Button>
                <br className='smallBox' />
                <a href={xoxnoLink} target='_blank' rel='noreferrer'>
                  <Button className='btnStandard'>
                    Heroes
                    <br />
                    on XOXNO
                  </Button>
                </a>
                <a href={deadrareLink} target='_blank' rel='noreferrer'>
                  <Button className='btnStandard'>
                    Heroes
                    <br />
                    on Dead Rare
                  </Button>
                </a>
              </div>
            </div>
          ) : null}*/}
          {userSession.isBlockchainAccount ? (
            <div className='bigBox'>
              <div className='infosBoxSub'>
                <div className='buttonsNFTContainer'>
                  {isLoading ? (
                    <React.Fragment>
                      <Button disabled className='btnStandard'>
                        Loading...
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        className='btnStandardReverse'
                        onClick={refreshUserAttributs}
                      >
                        Synchronize
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {listNFTs.attributs.length > 0 ? (
        <div className='boxContainer topContainer' ref={refToTop}>
          <div className='card-body p-2'>
            <div className='nftBlock'>
              {listNFTsDisplay.nfts.map((token, i) => {
                const divStyle = {
                  '--rarityColor': hierarchyColor[token.nft_hierarchy]
                } as React.CSSProperties;

                const NFTscoreRounded = token.nft_power.toFixed(2);
                const nameT = token.nft_name.split(' ');
                const nft_collection = nameT[0];
                let nft_number;
                if (nameT[1]) {
                  nft_number = nameT[1].replace('#', '');
                }
                const flg_blockchain = token.flg_blockchain;

                return (
                  <div key={i} style={divStyle} className='cardNFTBlock'>
                    <div className='cardNFTContainer'>
                      <div className='cardNFTBox'>
                        <div className='cardNFTBoxIn'>
                          <div className='cardNFTInfos'>
                            <div className='cardNFTHierarchy'>
                              {token.nft_hierarchy}
                            </div>
                            <div className='cardNFTPower'>
                              Power : {NFTscoreRounded}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cardNFTPictureBox'>
                        {flg_blockchain ? (
                          <img
                            src={`${token.nft_url}`}
                            className='cardNFTPicture'
                          />
                        ) : (
                          <img
                            src={`../${token.nft_url}`}
                            className='cardNFTPicture'
                          />
                        )}
                      </div>
                      <div className='cardNFTInfosBis'>
                        {!nft_number ? (
                          <div className='cardNFTTitle'>{nft_collection}</div>
                        ) : (
                          <React.Fragment>
                            <div className='cardNFTNumber'>{nft_number}</div>
                            <div className='cardNFTName'>{nft_collection}</div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <React.Fragment>
            {totalPages > 1 && (
              <div className='paginBoxBottom'>
                <Pagination
                  className='paginContainer'
                  count={totalPages}
                  defaultPage={1}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  shape='rounded'
                  onChange={handlePageChange}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      ) : (
        <div className='boxContainer topContainer'>
          <div className='card-body text-center p-2'>
            <div className='iconContainer'>
              <FontAwesomeIcon icon={faFrown} className='fa-4x' />
            </div>
            <p>
              Oops... it looks like you don&apos;t have any J-Corp Heroes at the
              moment !
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default NFTsWallet;

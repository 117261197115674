import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';
import Account from './pages/Account';
import Admin from './pages/Admin';
//import Arena from './pages/Arena';
import ArenaRanked from './pages/ArenaRanked';
import ArenaTournament from './pages/ArenaTournament';
import BetaFeatures from './pages/BetaFeatures';
//import ArenaLeaderBoard from './pages/Boards/ArenaLeaderboard';
import ConquestBoard from './pages/Boards/Conquest';
import ConquestBoardAdmin from './pages/Boards/ConquestAdmin';
import HistoryBoard from './pages/Boards/History';
import LeaderBoard from './pages/Leaderboard';
//import ClaimPanel from './pages/ClaimPanel';
import Conquest from './pages/Conquest';
import Factions from './pages/Factions';
import FactionInventory from './pages/FactionInventory';
import FactionMarket from './pages/FactionMarket';
//import FactionMarketplace from './pages/FactionMarketplace';
//import FactionMarketplaceHistory from './pages/FactionMarketplaceHistory';
import Home from './pages/Home';
import Inventory from './pages/Inventory';
import Maintenance from './pages/Maintenance';
import Market from './pages/Market';
//import Marketplace from './pages/Marketplace';
//import MarketplaceHistory from './pages/MarketplaceHistory';
import Menu from './pages/Menu';
import Mint from './pages/Mint';
//import NFTsWallet from './pages/NFTsWallet';
//import Profil from './pages/Profil';
//import EditProfil from './pages/Profil/Edit';
import Research from './pages/Research';
import Rewards from './pages/Rewards';
import Rules from './pages/Rules';
import Squads from './pages/Squads';
//import Wallet from './pages/Wallet';

export const routeNames = {
  home: '/',
  maintenance: '/maintenance',
  unlock: '/unlock',
  account: '/account',
  accountCat: '/account/:category?',
  profile: '/account/profile',
  editprofile: '/account/profile?edit=true',
  nftswallet: '/account/nfts',
  admin: '/jcorpgest',
  adminCat: '/jcorpgest/:category?',
  conquestboardadmin: '/jcorpgest/control',
  menu: '/menu',
  arena: '/arena',
  arenaranked: '/arena/ranked',
  arenaleaderboard: '/arena/leaderboard',
  arenatournament: '/arena/tournament',
  betafeatures: '/beta',
  conquest: '/conquest',
  market: '/market',
  marketCat: '/market/:category?',
  marketplace: '/marketplace',
  marketplacehistory: '/marketplace/history',
  mint: '/mint',
  conquestboard: '/board/conquest',
  historyboard: '/board/history',
  leaderboard: '/leaderboard',
  leaderboardCat: '/leaderboard/:category?',
  factions: '/factions',
  claimpanel: '/claim',
  wallet: '/wallet',
  inventory: '/inventory',
  factioninventory: '/factioninventory',
  factionmarketplace: '/faction/marketplace',
  factionmarketplacehistory: '/faction/marketplace/history',
  factionmarket: '/factionmarket',
  factionmarketCat: '/factionmarket/:category?',
  nftsearch: '/nftsearch',
  rewards: '/rewards',
  rules: '/rules',
  squads: '/squads'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.maintenance,
    title: 'Maintenance',
    component: Maintenance
  },
  {
    path: routeNames.accountCat,
    title: 'My Account',
    component: Account,
    authenticatedRoute: true
  },
  {
    path: routeNames.marketCat,
    title: 'Market',
    component: Market,
    authenticatedRoute: true
  },
  {
    path: routeNames.factionmarketCat,
    title: 'Faction Market',
    component: FactionMarket,
    authenticatedRoute: true
  },
  {
    path: routeNames.adminCat,
    title: 'Admin',
    component: Admin,
    authenticatedRoute: true
  },
  {
    path: routeNames.menu,
    title: 'Game Modes',
    component: Menu,
    authenticatedRoute: true
  },
  {
    path: routeNames.betafeatures,
    title: 'Beta Features',
    component: BetaFeatures,
    authenticatedRoute: true
  },
  {
    path: routeNames.conquestboardadmin,
    title: 'Conquest Board',
    component: ConquestBoardAdmin,
    authenticatedRoute: true
  },
  /*{
    path: routeNames.arena,
    title: 'Arena',
    component: Arena,
    authenticatedRoute: true
  },*/
  {
    path: routeNames.arenaranked,
    title: 'Ranked Arena',
    component: ArenaRanked,
    authenticatedRoute: true
  },
  /*{
    path: routeNames.arenaleaderboard,
    title: 'Ranked Arena Leaderboard',
    component: ArenaLeaderBoard,
    authenticatedRoute: true
  },*/
  {
    path: routeNames.arenatournament,
    title: 'Arena Tournament',
    component: ArenaTournament,
    authenticatedRoute: true
  },
  {
    path: routeNames.conquest,
    title: 'Conquest',
    component: Conquest,
    authenticatedRoute: true
  },
  /*{
    path: routeNames.marketplace,
    title: 'Marketplace',
    component: Marketplace,
    authenticatedRoute: true
  },*/
  /*{
    path: routeNames.marketplacehistory,
    title: 'Marketplace History',
    component: MarketplaceHistory,
    authenticatedRoute: true
  },*/
  {
    path: routeNames.mint,
    title: 'Mint',
    component: Mint,
    authenticatedRoute: true
  },
  {
    path: routeNames.conquestboard,
    title: 'Conquest Board',
    component: ConquestBoard,
    authenticatedRoute: true
  },
  {
    path: routeNames.factions,
    title: 'Factions',
    component: Factions,
    authenticatedRoute: true
  },
  {
    path: routeNames.historyboard,
    title: 'History of Conquests',
    component: HistoryBoard,
    authenticatedRoute: true
  },
  {
    path: routeNames.leaderboardCat,
    title: 'Leaderboard',
    component: LeaderBoard,
    authenticatedRoute: true
  },
  /*{
    path: routeNames.profile,
    title: 'Profile',
    component: Profil,
    authenticatedRoute: true
  },
  {
    path: routeNames.editprofile,
    title: 'Edit Profile',
    component: EditProfil,
    authenticatedRoute: true
  },*/
  /*{
    path: routeNames.claimpanel,
    title: 'Claim Panel',
    component: ClaimPanel,
    authenticatedRoute: true
  },*/
  /*{
    path: routeNames.wallet,
    title: 'Wallet',
    component: Wallet,
    authenticatedRoute: true
  },*/
  /*{
    path: routeNames.nftswallet,
    title: 'NFTs Wallet',
    component: NFTsWallet,
    authenticatedRoute: true
  },*/
  {
    path: routeNames.inventory,
    title: 'Inventory',
    component: Inventory,
    authenticatedRoute: true
  },
  {
    path: routeNames.factioninventory,
    title: 'Faction Inventory',
    component: FactionInventory,
    authenticatedRoute: true
  },
  /*{
    path: routeNames.factionmarketplace,
    title: 'Faction Marketplace',
    component: FactionMarketplace,
    authenticatedRoute: true
  },
  {
    path: routeNames.factionmarketplacehistory,
    title: 'Faction Marketplace History',
    component: FactionMarketplaceHistory,
    authenticatedRoute: true
  },*/
  {
    path: routeNames.nftsearch,
    title: 'NFT Search',
    component: Research
  },
  {
    path: routeNames.rewards,
    title: 'Rewards',
    component: Rewards,
    authenticatedRoute: true
  },
  {
    path: routeNames.rules,
    title: 'Rules',
    component: Rules
  },
  {
    path: routeNames.squads,
    title: 'Squads',
    component: Squads,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;

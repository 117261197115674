import * as React from 'react';
import { useAuthContext } from 'context/auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import { routeNames } from 'routes';
import FactionDataService from '../../services/faction.service';
import FactionMarketplace from './FactionMarketplace';
import FactionMarketplaceHistory from './FactionMarketplaceHistory';
import { FactionStateType, factionEmpty } from '../../types/faction.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const defaultContent = 'shop';

const navigationAccount = [
  {
    title: 'Faction Shop',
    content: 'shop',
    href: '/factionmarket/shop'
  },
  {
    title: 'Faction Operations',
    content: 'operations',
    href: '/factionmarket/operations'
  },
  {
    title: 'Market',
    content: 'market',
    href: '/market'
  }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const FactionMarket = () => {
  const { userInfos } = useAuthContext();

  const navigate = useNavigate();
  const params = useParams();

  const [accountContent, setAccountContent] = React.useState(defaultContent);

  const handleChangeContent = (parameters: any) => {
    if (parameters.category != null) {
      setAccountContent(parameters.category);
    } else {
      setAccountContent(defaultContent);
    }
  };

  const [factionImg, setFactionImg] = React.useState('');
  const [factionInfos, setFactionInfos] = React.useState<FactionStateType>({
    faction: factionEmpty,
    message: '',
    status: ''
  });

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const fetchFactionsData = () => {
    FactionDataService.findOneById(userInfos.user.id_faction).then(
      ({ data }) => {
        if (data.status == 'success') {
          setFactionInfos({
            faction: data.faction,
            status: data.status
          });
        }
      }
    );
  };

  React.useEffect(() => {
    handleChangeContent(params);
  }, [params]);

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchFactionsData();
      fetchFactionInfos();
    }
  }, []);

  const { faction } = factionInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                <h2>Conquest {faction.name}</h2>
                <span>Faction Market</span>
              </div>

              {/*<div className='bigBox'>
                <div className='stripContainerRight'>
                  <div className='stripBoxRight'>
                    <div className='stripBoxRightRank'>
                      <div className='stripInfos'>
                        <div className='stripInfosIn'>
                          <div className='stripInfosText'>{faction.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className='stripBoxRightBtn'>
                      <div className='imgBoxFaction'>
                        <img
                          src={`${factionImg}`}
                          className='imgFactionSmall'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='smallBox'>
                <div className='boxContainer topArenaContainer'>
                  <div className='stripContainerRight'>
                    <div className='stripBoxRight'>
                      <div className='stripBoxRightRank'>
                        <div className='stripInfos'>
                          <div className='stripInfosIn'>
                            <div className='stripInfosText'>{faction.name}</div>
                          </div>
                        </div>
                      </div>
                      <div className='stripBoxRightBtn'>
                        <div className='imgBoxFaction'>
                          <img
                            src={`${factionImg}`}
                            className='imgFactionSmall'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}

              <div className='boxWrapperContainer'>
                <div className='tabsWrapper'>
                  <div className='tabsWrapperIn'>
                    <ul className='tabsWrapperContainer'>
                      {navigationAccount.map((item) => (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='tabsWrapperInMobile'>
                  <div className='tabsWrapperContainerMobile'>
                    {navigationAccount.map((item) => {
                      return (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {accountContent != null ? (
              <React.Fragment>
                {accountContent == '' ? <FactionMarketplace /> : null}
                {accountContent == 'shop' ? <FactionMarketplace /> : null}
                {accountContent == 'operations' ? (
                  <FactionMarketplaceHistory />
                ) : null}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactionMarket;

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import Conquest from './Conquest';
import ArenaRanked from './ArenaRanked';

const defaultContent = 'conquest';

const navigationAccount = [
  { title: 'Conquest', content: 'conquest', href: '/leaderboard/conquest' },
  {
    title: 'Ranked Arena',
    content: 'arenaranked',
    href: '/leaderboard/arenaranked'
  }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Market = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();
  const params = useParams();

  const [accountContent, setAccountContent] = React.useState(defaultContent);

  const handleChangeContent = (parameters: any) => {
    if (parameters.category != null) {
      setAccountContent(parameters.category);
    } else {
      setAccountContent(defaultContent);
    }
  };

  React.useEffect(() => {
    if (params.category != null) {
      handleChangeContent(params);
    } else {
      switch (userSession.gameMode) {
        case 'conquest':
          setAccountContent('conquest');
          break;
        case 'arenaranked':
          setAccountContent('arenaranked');
          break;
        default:
          setAccountContent(defaultContent);
          break;
      }
    }
  }, [params]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                <span>Leaderboard</span>
              </div>

              <div className='boxWrapperContainer'>
                <div className='tabsWrapper'>
                  <div className='tabsWrapperIn'>
                    <ul className='tabsWrapperContainer'>
                      {navigationAccount.map((item) => (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='tabsWrapperInMobile'>
                  <div className='tabsWrapperContainerMobile'>
                    {navigationAccount.map((item) => {
                      return (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {accountContent != null ? (
              <React.Fragment>
                {accountContent == '' ? <Conquest /> : null}
                {accountContent == 'conquest' ? <Conquest /> : null}
                {accountContent == 'arenaranked' ? <ArenaRanked /> : null}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;

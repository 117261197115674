import React from 'react';
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers';
import ReactGA from 'react-ga4';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAccountData } from 'common/user-account';
import InternalAuthRoutesWrapper from 'components/InternalAuthRoutesWrapper';
import { useAuthContext } from 'context/auth-context';
import routes, { routeNames } from 'routes';
import SettingDataService from 'services/setting.service';
import arena from './../../assets/img/arena.png';
import city from './../../assets/img/city.png';
import marketplace from './../../assets/img/marketplace.png';
import titan from './../../assets/img/titan.png';
//import tower from './../../assets/img/tower.png';
import universe from './../../assets/img/universe.png';
import Footer from './Footer';
import FooterHome from './FooterHome';
//import Navbar from './Navbar';
//import NavbarArenaRanked from './NavbarArenaRanked';
//import NavbarConquest from './NavbarConquest';
import NavbarHome from './NavbarHome';
import NavbarMenu from './NavbarMenu';
import NavbarSubGame from './NavbarSubGame';
import TxTracking from './TxTracking';

const Layout = ({ children }: { children: React.ReactNode }) => {
  TxTracking();

  const refPageTop = React.useRef<HTMLInputElement>(null);

  const { userSession } = useAuthContext();

  const isLoggedIn = Boolean(userSession.userAccount);

  const navigate = useNavigate();
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  const path_array = location.pathname.split('/');
  const current_path = '/' + path_array[1];

  const getTarget = new URLSearchParams(location.search).get('target');
  if (getTarget == null || getTarget == '') {
    const targetUrl = location.pathname.replace('/', '');
    if (targetUrl != 'unlock' && targetUrl != '') {
      if (location.search == null || location.search == '') {
        location.search = '?target=' + targetUrl;
      } else {
        location.search += '&target=' + targetUrl;
      }
    }
  }

  const [flgMaintenance, setFlgMaintenance] = React.useState(false);
  const [currentPhase, setCurrentPhase] = React.useState(0);

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'maintenance';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.setting.value == 0) {
          setFlgMaintenance(false);
          if (location.pathname == '/maintenance') {
            navigate(routeNames.conquest);
          }
        } else {
          setFlgMaintenance(true);
          if (location.pathname != '/maintenance') {
            navigate(routeNames.maintenance);
          }
        }
      }
    });

    const path = location.pathname.substring(1); // remove the first /
    switch (path) {
      case 'conquest':
      case 'board/conquest':
        params['name'] = 'phase';

        // Fetch of the current phase in the parameters
        SettingDataService.findByName(params).then(({ data }) => {
          if (data.status == 'success') {
            setCurrentPhase(data.setting.value);
          }
        });
        break;
    }
  };

  const [pageInfos, setPageInfos] = React.useState({
    style: {
      '--bg-image': ''
    } as React.CSSProperties
  });

  const fetchLocationPath = () => {
    const path = location.pathname.substring(1); // remove the first /
    let img;

    switch (path) {
      case 'conquest':
      case 'board/conquest':
        if (currentPhase == 2) img = `url(${city})`;
        if (currentPhase == 3) img = `url(${universe})`;
        break;
      case 'leaderboard':
        if (userSession.gameMode == 'conquest') img = `url(${universe})`;
        if (userSession.gameMode == 'arenaranked') img = `url(${arena})`;
        break;
      case 'leaderboard/conquest':
      //case 'board/leaderboard':
      case 'board/history':
        img = `url(${universe})`;
        break;
      //case 'arena':
      case 'arena/ranked':
      case 'leaderboard/arenaranked':
      //case 'arena/leaderboard':
      case 'arena/tournament':
        img = `url(${arena})`;
        break;
      /*case 'marketplace':
      case 'marketplace/history':
      case 'faction/marketplace':
      case 'faction/marketplace/history':
        img = `url(${marketplace})`;
        break;*/
      case 'market':
      case 'market/shop':
      case 'market/operations':
      case 'factionmarket':
      case 'factionmarket/shop':
      case 'factionmarket/operations':
        img = `url(${marketplace})`;
        break;
      case 'inventory':
      case 'faction/inventory':
        img = `url(${marketplace})`;
        break;
      case 'menu':
        img = `url(${city})`;
        break;
      default:
        img = `url(${titan})`;
        break;
    }

    const style = {
      '--bg-image': img
    } as React.CSSProperties;

    setPageInfos({
      style: style
    });
  };

  React.useEffect(() => {
    refPageTop.current &&
      refPageTop.current.scrollIntoView({
        block: 'start',
        inline: 'nearest'
      });

    fetchSettingData();
  }, [location]);

  React.useEffect(() => {
    fetchLocationPath();
  }, [location, currentPhase]);

  React.useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('G-8X0NYC3XSQ');
      setInitialized(true);
    }
  }, [location]);

  React.useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search
      });
    }
  }, [initialized, location]);

  const [isHome, setIsHome] = React.useState(false);
  const [isMenu, setIsMenu] = React.useState(false);
  const [isConquestMode, setIsConquestMode] = React.useState(false);
  const [isArenaRankedMode, setIsArenaRankedMode] = React.useState(false);

  const [isSubGameMenu, setIsSubGameMenu] = React.useState(false);
  const [gameMode, setGameMode] = React.useState<string>('');
  const [gameModePath, setGameModePath] = React.useState<string>('');

  React.useEffect(() => {
    setIsHome(false);
    setIsMenu(false);
    setIsConquestMode(false);
    setIsArenaRankedMode(false);
    setIsSubGameMenu(false);

    let isGameMode = false;

    switch (current_path) {
      case routeNames.home:
      case routeNames.unlock:
        setIsHome(true);
        break;
      case routeNames.menu:
      case routeNames.mint:
      case routeNames.factions:
      case routeNames.admin:
      case routeNames.account:
        setIsMenu(true);
        break;
      case routeNames.nftsearch:
        if (isLoggedIn) {
          setIsMenu(true);
        } else {
          setIsHome(true);
        }
        break;
      default:
        if (isLoggedIn) {
          isGameMode = true;
        } else {
          setIsHome(true);
        }
        break;
    }

    if (isGameMode) {
      let isDefault = false;

      switch (location.pathname) {
        case routeNames.conquest:
          setIsConquestMode(true);
          setAccountData('gameMode', 'conquest');
          setIsSubGameMenu(true);
          setGameMode('Conquest');
          setGameModePath(routeNames.conquest);
          break;
        case routeNames.arenaranked:
          setIsArenaRankedMode(true);
          setAccountData('gameMode', 'arenaranked');
          setIsSubGameMenu(true);
          setGameMode('Ranked Arena');
          setGameModePath(routeNames.arenaranked);
          break;
        case routeNames.arenatournament:
          setIsMenu(true); // TODO
          setAccountData('gameMode', 'arenatournament');
          //setIsSubGameMenu(true); // TODO
          setGameMode('Tournament Arena');
          setGameModePath(routeNames.arenatournament);
          break;
        default:
          isDefault = true;
          break;
      }

      if (isDefault) {
        switch (userSession.gameMode) {
          case 'conquest':
            setIsConquestMode(true);
            setIsSubGameMenu(true);
            setGameMode('Conquest');
            setGameModePath(routeNames.conquest);
            break;
          case 'arenaranked':
            setIsArenaRankedMode(true);
            setIsSubGameMenu(true);
            setGameMode('Ranked Arena');
            setGameModePath(routeNames.arenaranked);
            break;
          case 'arenatournament':
            setIsMenu(true); // TODO
            //setIsSubGameMenu(true); // TODO
            setGameMode('Tournament Arena');
            setGameModePath(routeNames.arenatournament);
            break;
          default:
            setIsMenu(true);
            break;
        }
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      {flgMaintenance ? (
        <div className='d-flex flex-column flex-fill'>
          <div className='maintenanceContainer' ref={refPageTop}>
            <div className='shadeContainer'>
              <div className='shade1'></div>
              <div className='shade2'></div>
              <div className='shade3'></div>
              <div className='shade4'></div>
              <div className='shade5'></div>
              <div className='pageImgTexture'></div>
            </div>
            <main className='d-flex flex-column flex-grow-1'>
              {userSession.userAccountProvider == 'multiversx' ? (
                <AuthenticatedRoutesWrapper
                  routes={routes}
                  unlockRoute={`${routeNames.unlock}${location.search}`}
                >
                  {children}
                </AuthenticatedRoutesWrapper>
              ) : (
                <InternalAuthRoutesWrapper>
                  {children}
                </InternalAuthRoutesWrapper>
              )}
            </main>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column flex-fill'>
          <div
            style={pageInfos.style}
            className='pageContainer'
            ref={refPageTop}
          >
            <div className='shadeContainer'>
              <div className='shade1'></div>
              <div className='shade2'></div>
              <div className='shade3'></div>
              <div className='shade4'></div>
              <div className='shade5'></div>
              <div className='pageImgTexture'></div>
            </div>
            <div className='navGroupContainer'>
              {isHome ? <NavbarHome /> : <NavbarMenu />}
              {/*
              {isHome ? <NavbarHome /> : null}
              {isMenu ? <NavbarMenu /> : null}
              {isConquestMode ? <NavbarConquest /> : null}
              {isArenaRankedMode ? <NavbarArenaRanked /> : null}
              */}
            </div>
            {isSubGameMenu ? (
              <div className='navSubGameContainer'>
                <NavbarSubGame
                  gameModeTitle={gameMode}
                  gameModePath={gameModePath}
                />
              </div>
            ) : null}
            <main className='d-flex flex-column flex-grow-1'>
              {userSession.userAccountProvider == 'multiversx' ? (
                <AuthenticatedRoutesWrapper
                  routes={routes}
                  unlockRoute={`${routeNames.unlock}${location.search}`}
                >
                  {children}
                </AuthenticatedRoutesWrapper>
              ) : (
                <InternalAuthRoutesWrapper>
                  {children}
                </InternalAuthRoutesWrapper>
              )}
            </main>
          </div>
          {isHome ? <FooterHome /> : <Footer />}
        </div>
      )}
    </React.Fragment>
  );
};

export default Layout;

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import General from './General';
import GeneralModerator from './GeneralModerator';
import Tournament from './Tournament';

const defaultContent = 'general';

const navigationAdmin = [
  { title: 'General', content: 'general', href: '/jcorpgest/general' },
  { title: 'Tournament', content: 'tournament', href: '/jcorpgest/tournament' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Admin = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();
  const params = useParams();

  const [adminContent, setAdminContent] = React.useState(defaultContent);

  const handleChangeContent = (parameters: any) => {
    if (parameters.category != null) {
      setAdminContent(parameters.category);
    } else {
      setAdminContent(defaultContent);
    }
  };

  React.useEffect(() => {
    handleChangeContent(params);
  }, [params]);

  React.useEffect(() => {
    if (!userSession.isAllowed) {
      navigate(routeNames.conquest);
    }
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                {userSession.isAdmin ? (
                  <h2>Administrator</h2>
                ) : (
                  <h2>Moderator</h2>
                )}
                <span>Administration Panel</span>
              </div>

              <div className='boxWrapperContainer'>
                <div className='tabsWrapper'>
                  <div className='tabsWrapperIn'>
                    <ul className='tabsWrapperContainer'>
                      {navigationAdmin.map((item) => (
                        <li
                          className={classNames(
                            item.content == adminContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='tabsWrapperInMobile'>
                  <div className='tabsWrapperContainerMobile'>
                    {navigationAdmin.map((item) => {
                      return (
                        <li
                          className={classNames(
                            item.content == adminContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {adminContent != null ? (
            <React.Fragment>
              {adminContent == '' || adminContent == 'general' ? (
                <React.Fragment>
                  {userSession.isAdmin ? <General /> : <GeneralModerator />}
                </React.Fragment>
              ) : null}
              {adminContent == 'tournament' ? <Tournament /> : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Admin;

import * as React from 'react';
import { useAuthContext } from 'context/auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import Marketplace from './Marketplace';
import MarketplaceHistory from './MarketplaceHistory';

const defaultContent = 'shop';

const navigationConquest = [
  { title: 'Shop', content: 'shop', href: '/market/shop' },
  { title: 'Operations', content: 'operations', href: '/market/operations' },
  {
    title: 'Faction Market',
    content: 'factionmarket',
    href: '/factionmarket'
  }
];

const navigationArena = [
  { title: 'Shop', content: 'shop', href: '/market/shop' },
  { title: 'Operations', content: 'operations', href: '/market/operations' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Market = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();
  const params = useParams();

  const [accountContent, setAccountContent] = React.useState(defaultContent);

  const [showConquest, setShowConquest] = React.useState(false);
  const [showArena, setShowArena] = React.useState(false);
  const handleShowConquest = () => {
    setShowConquest(true);
    setShowArena(false);
  };
  const handleShowArena = () => {
    setShowConquest(false);
    setShowArena(true);
  };

  const handleChangeContent = (parameters: any) => {
    if (parameters.category != null) {
      setAccountContent(parameters.category);
    } else {
      setAccountContent(defaultContent);
    }
  };

  React.useEffect(() => {
    switch (userSession.gameMode) {
      case 'conquest':
        handleShowConquest();
        break;
      case 'arenaranked':
        handleShowArena();
        break;
      default:
        handleShowConquest();
        break;
    }
  }, []);

  React.useEffect(() => {
    handleChangeContent(params);
  }, [params]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                {showConquest ? <h2>Conquest</h2> : <h2>Ranked Arena</h2>}
                <span>Market</span>
              </div>

              {showConquest ? (
                <div className='boxWrapperContainer'>
                  <div className='tabsWrapper'>
                    <div className='tabsWrapperIn'>
                      <ul className='tabsWrapperContainer'>
                        {navigationConquest.map((item) => (
                          <li
                            className={classNames(
                              item.content == accountContent
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.content}
                          >
                            {item.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className='tabsWrapperInMobile'>
                    <div className='tabsWrapperContainerMobile'>
                      {navigationConquest.map((item) => {
                        return (
                          <li
                            className={classNames(
                              item.content == accountContent
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.content}
                          >
                            {item.title}
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxWrapperContainer'>
                  <div className='tabsWrapper'>
                    <div className='tabsWrapperIn'>
                      <ul className='tabsWrapperContainer'>
                        {navigationArena.map((item) => (
                          <li
                            className={classNames(
                              item.content == accountContent
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.content}
                          >
                            {item.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className='tabsWrapperInMobile'>
                    <div className='tabsWrapperContainerMobile'>
                      {navigationArena.map((item) => {
                        return (
                          <li
                            className={classNames(
                              item.content == accountContent
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.content}
                          >
                            {item.title}
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            {accountContent != null ? (
              <React.Fragment>
                {accountContent == '' ? <Marketplace /> : null}
                {accountContent == 'shop' ? <Marketplace /> : null}
                {accountContent == 'operations' ? <MarketplaceHistory /> : null}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;

import * as React from 'react';
import { Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { network, tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import RewardsLogsDataService from '../../services/rewardslogs.service';
import { RewardsLogsStateType } from '../../types/rewardslogs.type';

const Rewards = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { explorerAddress } = network;
  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const pageSize = 50;

  const [rewardsInfos, setRewardsInfos] = React.useState<RewardsLogsStateType>({
    totalItems: 0,
    totalPages: 0,
    rewards_logs: [],
    message: '',
    status: ''
  });

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const fetchRewardsData = () => {
    const params: any = {};
    params['page'] = page - 1;
    params['size'] = pageSize;

    RewardsLogsDataService.findAllByUser(userSession.userID, params).then(
      ({ data }) => {
        if (data.status == 'success') {
          setRewardsInfos({
            totalItems: data.totalItems,
            totalPages: data.totalPages,
            rewards_logs: data.rewards_logs,
            status: data.status
          });
        }
      }
    );
  };

  React.useEffect(() => {
    fetchRewardsData();
  }, [page]);

  const { totalPages, rewards_logs } = rewardsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                <h2>Conquest</h2>
                <span>Rewards</span>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer' ref={refToTop}>
            <div className='card-body text-center p-2'>
              <div className='table-responsive'>
                {userSession.isBlockchainAccount ? (
                  <table className='transactions table pb-3'>
                    <thead>
                      <tr className='headTable'>
                        <th className='border-0'>Conquest</th>
                        <th className='border-0'>My played NFTs</th>
                        <th className='border-0'>Faction played NFTs</th>
                        <th className='border-0'>Faction winning NFTs</th>
                        <th className='border-0'>Tokens per winning NFT</th>
                        <th className='border-0'>Bonus</th>
                        <th className='border-0'>Amount</th>
                        <th className='border-0'>Contribution</th>
                        <th className='border-0'>Transaction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rewards_logs.map((rewards_log, i) => {
                        let token_name = '';

                        if (rewards_log.token_identifier == 'EGLD') {
                          token_name = 'EGLD';
                        } else {
                          tokensListNames.map((token) => {
                            if (
                              rewards_log.token_identifier ==
                              token.token_identifier
                            ) {
                              token_name = token.name;
                            }
                          });
                        }

                        const rewardAmount = rewards_log.amount.toLocaleString(
                          'en-GB',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        );
                        const contributionAmount =
                          rewards_log.amount_contribution.toLocaleString(
                            'en-GB',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          );

                        let flg_claim = false;
                        let link = '';
                        if (
                          rewards_log.reward_status == false &&
                          rewards_log.tx == ''
                        ) {
                          flg_claim = true;
                        } else {
                          if (rewards_log.tx == '') {
                            if (
                              rewards_log.claims_log?.tx != null &&
                              rewards_log.claims_log?.tx != ''
                            ) {
                              link =
                                explorerAddress +
                                '/transactions/' +
                                rewards_log.claims_log?.tx;
                            }
                          } else {
                            link =
                              explorerAddress +
                              '/transactions/' +
                              rewards_log.tx;
                          }
                        }

                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>#{rewards_log.id_conquest}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_played}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_faction_played}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_faction_win}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_token_per_nft}</span>
                            </td>
                            <td>
                              {rewards_log.bonus_value > 0 ? (
                                <span>+{rewards_log.bonus_value}%</span>
                              ) : (
                                <span>/</span>
                              )}
                            </td>
                            <td>
                              <span>
                                {rewardAmount} {token_name}
                              </span>
                            </td>
                            <td>
                              {rewards_log.amount_contribution > 0 ? (
                                <span>
                                  {contributionAmount} {token_name}
                                </span>
                              ) : (
                                <span>/</span>
                              )}
                            </td>
                            <td>
                              {flg_claim ? (
                                <a
                                  href={routeNames.claimpanel}
                                  rel='noreferrer'
                                >
                                  <div className='buttonContentTab buttonRed'>
                                    To claim
                                  </div>
                                </a>
                              ) : (
                                <React.Fragment>
                                  {link != '' ? (
                                    <a
                                      href={link}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      <div className='buttonContentTab buttonBlue'>
                                        See transaction
                                      </div>
                                    </a>
                                  ) : (
                                    <span>/</span>
                                  )}
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      {rewards_logs.length == 0 && (
                        <tr className='rowTableContent textResult'>
                          <td colSpan={9}>
                            <span>No rewards history</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <table className='transactions table pb-3'>
                    <thead>
                      <tr className='headTable'>
                        <th className='border-0'>Conquest</th>
                        <th className='border-0'>My played NFTs</th>
                        <th className='border-0'>Faction played NFTs</th>
                        <th className='border-0'>Faction winning NFTs</th>
                        <th className='border-0'>Tokens per winning NFT</th>
                        <th className='border-0'>Bonus</th>
                        <th className='border-0'>Amount</th>
                        <th className='border-0'>Contribution</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rewards_logs.map((rewards_log, i) => {
                        let token_name = '';

                        if (rewards_log.token_identifier == 'EGLD') {
                          token_name = 'EGLD';
                        } else {
                          tokensListNames.map((token) => {
                            if (
                              rewards_log.token_identifier ==
                              token.token_identifier
                            ) {
                              token_name = token.name;
                            }
                          });
                        }

                        const rewardAmount = rewards_log.amount.toLocaleString(
                          'en-GB',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        );
                        const contributionAmount =
                          rewards_log.amount_contribution.toLocaleString(
                            'en-GB',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          );

                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>#{rewards_log.id_conquest}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_played}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_faction_played}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_nfts_faction_win}</span>
                            </td>
                            <td>
                              <span>{rewards_log.nb_token_per_nft}</span>
                            </td>
                            <td>
                              {rewards_log.bonus_value > 0 ? (
                                <span>+{rewards_log.bonus_value}%</span>
                              ) : (
                                <span>/</span>
                              )}
                            </td>
                            <td>
                              <span>
                                {rewardAmount} {token_name}
                              </span>
                            </td>
                            <td>
                              {rewards_log.amount_contribution > 0 ? (
                                <span>
                                  {contributionAmount} {token_name}
                                </span>
                              ) : (
                                <span>/</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      {rewards_logs.length == 0 && (
                        <tr className='rowTableContent textResult'>
                          <td colSpan={8}>
                            <span>No rewards history</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <React.Fragment>
              {totalPages > 1 && (
                <div className='paginBoxBottom'>
                  <Pagination
                    className='paginContainer'
                    count={totalPages}
                    defaultPage={1}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    shape='rounded'
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;

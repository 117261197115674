import * as React from 'react';
import { faWindowClose, faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../services/attributs.service';
import FactionDataService from '../../services/faction.service';
import UserDataService from '../../services/user.service';
import {
  AttributsStateType,
  AttributsDisplayStateType
} from '../../types/attributs.type';
import { FactionsStateType } from '../../types/faction.type';
import { hierarchyColor } from '../../types/token.type';
import { UsersStateType, IUserData, userEmpty } from '../../types/user.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Factions = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });

  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const [selectedUser, setSelectedUser] = React.useState({
    user: userEmpty,
    nb_nfts: 0,
    tot_power: 0
  });

  const [showUserModal, setShowUserModal] = React.useState(false);
  const handleCloseUserModal = () => {
    setShowUserModal(false);
  };
  const handleShowUserModal = (user: IUserData) => {
    setListNFTs({
      attributs: [],
      status: ''
    });
    setListNFTsDisplay({
      nfts: []
    });

    setSelectedUser({
      user: user,
      nb_nfts: user.nb_nfts,
      tot_power: user.tot_power
    });

    setShowUserModal(true);
  };

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleRefreshUser = () => {
    AttributsDataService.findAllByUser(selectedUser.user.id_user).then(
      ({ data }) => {
        if (data.status == 'success') {
          setListNFTs({
            attributs: data.attributs,
            status: data.status
          });

          const nbPages = Math.ceil(data.attributs.length / pageSize);
          setPage(1);
          setTotalPages(nbPages);

          let NFTscore = 0;
          data.attributs.map((attribut) => {
            NFTscore += attribut.nft_power;
          });
          const nb_nfts = data.attributs.length;
          const NFTscoreRounded = parseFloat(NFTscore.toFixed(2));

          setSelectedUser({
            user: selectedUser.user,
            nb_nfts: nb_nfts,
            tot_power: NFTscoreRounded
          });
        }
      }
    );
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTs.attributs.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTs.attributs[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });
  const [usersInfos, setUsersInfos] = React.useState<UsersStateType>({
    users: [],
    message: '',
    status: ''
  });

  const [idFaction, setIdFaction] = React.useState<number>();
  const [factionName, setFactionName] = React.useState('');
  const [factionImg, setFactionImg] = React.useState('');
  const [factionLeader, setFactionLeader] = React.useState('');
  const [factionTotalPower, setFactionTotalPower] = React.useState('');

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
        setIdFaction(1);
      }
    });
  };

  const fetchUsersFactionData = () => {
    UserDataService.findAllByFaction(idFaction).then(({ data }) => {
      if (data.status == 'success') {
        setUsersInfos({
          users: data.users,
          status: data.status
        });
      }
    });
  };

  const fetchFactionInfos = () => {
    switch (idFaction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }

    let NFTscore = 0;
    usersInfos.users.map((user) => {
      NFTscore += user.tot_power;
    });
    setFactionTotalPower(NFTscore.toFixed(2));

    let flg_trouve = false;
    factionsInfos.factions.map((faction) => {
      if (faction.id_faction == idFaction) {
        setFactionName(faction.name);
        usersInfos.users.map((user) => {
          if (faction.id_user == user.id_user) {
            flg_trouve = true;
            setFactionLeader(user.pseudo);
          }
        });
      }
    });
    if (!flg_trouve) setFactionLeader('');
  };

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchFactionsData();
    }
  }, []);

  React.useEffect(() => {
    if (idFaction != null) {
      fetchUsersFactionData();
    }
  }, [idFaction]);

  React.useEffect(() => {
    if (idFaction != null) {
      fetchFactionInfos();
    }
  }, [idFaction, usersInfos]);

  React.useEffect(() => {
    if (showUserModal == true) {
      handleRefreshUser();
    }
  }, [showUserModal]);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0 && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTs, page]);

  const { factions } = factionsInfos;
  const { users } = usersInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                <span>Factions Board</span>
              </div>

              <div className='boxWrapperContainer'>
                <div className='tabsWrapper'>
                  <div className='tabsWrapperIn'>
                    <ul className='tabsWrapperContainer'>
                      {factions.map((faction) => {
                        return (
                          <li
                            className={classNames(
                              faction.id_faction == idFaction
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => setIdFaction(faction.id_faction)}
                            key={faction.id_faction}
                          >
                            {faction.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className='tabsWrapperInMobile'>
                  <ul className='tabsWrapperContainerMobile'>
                    {factions.map((faction) => {
                      return (
                        <li
                          className={classNames(
                            faction.id_faction == idFaction
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => setIdFaction(faction.id_faction)}
                          key={faction.id_faction}
                        >
                          {faction.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='dataContainer'>
                <div className='containerFaction marginBot20'>
                  <img src={`${factionImg}`} className='imgFaction' />
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Leader</span>
                  <span className='dataAttribut'>{factionLeader}</span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Members</span>
                  <span className='dataAttribut'>{users.length}</span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Power</span>
                  <span className='dataAttribut'>{factionTotalPower}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Members</div>
              </div>
              <div className='table-responsive text-center'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0 col50w'>User</th>
                      <th className='border-0 col20w'>Nb NFTs</th>
                      <th className='border-0 col30w'>Power</th>
                    </tr>
                  </thead>
                  {users.length == 0 ? (
                    <tbody>
                      <tr className='rowTableContent textResult'>
                        <td colSpan={3}>
                          <span>No members in this faction</span>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {users.map((user, i) => {
                        return (
                          /*<tr
                            key={i}
                            className='rowTableResult textResult'
                            onClick={() => handleShowUserModal(user)}
                          >*/
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{user.pseudo}</span>
                            </td>
                            <td>
                              <span>{user.nb_nfts}</span>
                            </td>
                            <td>
                              <span>{user.tot_power.toFixed(2)}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showUserModal}
        onHide={handleCloseUserModal}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContent'>
          <div className='modalBox'>
            <div className='boxTitle titleDef'>
              <div className='card-body text-center p-2'>
                <div>
                  <h3 className='py-2'>
                    <span className='mr-1 fantasy'>
                      {selectedUser.user.pseudo}
                      <span
                        className='iconsModalRight'
                        onClick={handleCloseUserModal}
                      >
                        <FontAwesomeIcon
                          icon={faWindowClose}
                          className='iconsConnectRight'
                        />
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className='boxContent'>
              <div className='card-body text-center p-2'>
                <div className='titleContent'>
                  <h3 className='py-2'>
                    <span className='mr-1 fantasy'>Profile</span>
                  </h3>
                </div>
              </div>
              <div className='card-body p-2'>
                <p className='profileLine'>
                  <span className='fantasy'>Faction : </span>
                  {factionName}
                  <img src={`${factionImg}`} className='imgFactionProfile' />
                </p>

                <p className='profileLine'>
                  <span className='fantasy'>Delegation : </span>
                  {selectedUser.user.flg_delegation ? (
                    <span>YES</span>
                  ) : (
                    <span>NO</span>
                  )}
                </p>

                <p className='profileLine'>
                  <span className='fantasy'>Faction contribution : </span>
                  {selectedUser.user.faction_contribution} %
                </p>
              </div>
            </div>
            <div className='boxContent' ref={refToTop}>
              <div className='card-body text-center p-2'>
                <div className='titleContent'>
                  <h3 className='py-2'>
                    <span className='mr-1 fantasy'>NFTs Wallet</span>
                  </h3>
                </div>
              </div>
              {listNFTs.attributs.length > 0 ? (
                <React.Fragment>
                  <div className='card-body text-center p-2'>
                    <div className='blockReward'>
                      <span className='fantasy'>Nb NFTs</span>
                      <br />
                      <div className='factionInfos'>{selectedUser.nb_nfts}</div>
                    </div>
                    <div className='blockReward'>
                      <span className='fantasy'>Total power</span>
                      <br />
                      <div className='factionInfos'>
                        {selectedUser.tot_power.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <React.Fragment>
                    {totalPages > 1 && (
                      <div className='card-body text-center p-2'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                  </React.Fragment>
                  <div className='card-body p-2'>
                    <div className='nftBlock'>
                      {listNFTsDisplay.nfts.map((token, i) => {
                        const divStyle = {
                          '--rarityColor': hierarchyColor[token.nft_hierarchy]
                        } as React.CSSProperties;

                        const NFTscoreRounded = token.nft_power.toFixed(2);
                        const nameT = token.nft_name.split(' ');
                        const nft_collection = nameT[0];
                        let nft_number;
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                        const flg_blockchain = token.flg_blockchain;

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardNFTPictureBox'>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <React.Fragment>
                    {totalPages > 1 && (
                      <div className='card-body text-center p-2'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                  </React.Fragment>
                </React.Fragment>
              ) : (
                <div className='card-body text-center p-2'>
                  <div className='iconContainer'>
                    <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                  </div>
                  <p>
                    Oops... it looks like&nbsp;
                    {selectedUser.user.pseudo}
                    &nbsp;don&apos; t have any J-Corp Heroes at the moment !
                  </p>
                </div>
              )}
            </div>
            <div className='boxContent'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <div
                    className='buttonContent buttonGrey fantasy'
                    onClick={handleCloseUserModal}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Factions;

import * as React from 'react';
import { Pagination } from '@mui/material';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import FactionsShopsDataService from '../../../services/factionsshops.service';
import ItemsDataService from '../../../services/items.service';
import { FactionShopsStateType } from '../../../types/factionsshops.type';
import { ItemsStateType } from '../../../types/items.type';

const FactionMarketplaceHistory = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userInfos } = useAuthContext();

  const [page, setPage] = React.useState(1);
  const pageSize = 50;

  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });
  const [shopsInfos, setShopsInfos] = React.useState<FactionShopsStateType>({
    totalItems: 0,
    totalPages: 0,
    shops: [],
    message: '',
    status: ''
  });

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const fetchShopsData = () => {
    const params: any = {};
    params['page'] = page - 1;
    params['size'] = pageSize;

    FactionsShopsDataService.findAllByFaction(
      userInfos.user.id_faction,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setShopsInfos({
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          shops: data.shops,
          status: data.status
        });
      }
    });
  };

  React.useEffect(() => {
    fetchItemsData();
  }, []);

  React.useEffect(() => {
    fetchShopsData();
  }, [page]);

  const { items } = itemsInfos;
  const { totalPages, shops } = shopsInfos;

  return (
    <>
      <div className='boxContainer topContainer' ref={refToTop}>
        <div className='card-body text-center p-2'>
          <div className='table-responsive'>
            <table className='transactions table pb-3'>
              <thead>
                <tr className='headTable'>
                  <th className='border-0'>Date</th>
                  <th className='border-0'>User</th>
                  <th className='border-0'>Item</th>
                  <th className='border-0'>Quantity</th>
                  <th className='border-0'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {shops.map((shop, i) => {
                  let token_name = '';
                  let item_name = '';

                  if (shop.token_identifier == 'EGLD') {
                    token_name = 'EGLD';
                  } else {
                    tokensListNames.map((token) => {
                      if (shop.token_identifier == token.token_identifier) {
                        token_name = token.name;
                      }
                    });
                  }
                  items.map((item) => {
                    if (item.id_item == shop.id_item) {
                      item_name = item.item_name;
                    }
                  });

                  let timeDate = '';
                  if (shop.createdAt != null) {
                    const time = new Date(shop.createdAt);
                    timeDate =
                      time.toLocaleDateString() +
                      ' ' +
                      time.toLocaleTimeString();
                  }

                  const price = shop.amount.toLocaleString('en-GB', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3
                  });

                  return (
                    <tr key={i} className='rowTableContent textResult'>
                      <td>
                        <span>{timeDate}</span>
                      </td>
                      <td>
                        <span>{shop.user?.pseudo}</span>
                      </td>
                      <td>
                        <span>{item_name}</span>
                      </td>
                      <td>
                        <span>{shop.quantity}</span>
                      </td>
                      <td>
                        <span>
                          {price} {token_name}
                        </span>
                      </td>
                    </tr>
                  );
                })}
                {shops.length == 0 && (
                  <tr className='rowTableContent textResult'>
                    <td colSpan={5}>
                      <span>No faction market purchase</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <React.Fragment>
          {totalPages > 1 && (
            <div className='paginBoxBottom'>
              <Pagination
                className='paginContainer'
                count={totalPages}
                defaultPage={1}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                shape='rounded'
                onChange={handlePageChange}
              />
            </div>
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default FactionMarketplaceHistory;

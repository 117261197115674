import React from 'react';
import { Navbar as BsNavbar, Nav, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from 'assets/img/JCORP_logo.png';
import logo_purple from 'assets/img/JCORP_logo_purple.png';
import { ReactComponent as VectorCross } from 'assets/img/vector_cross.svg';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';

const NavbarMenu = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();
  const location = useLocation();

  const [navCollapsed, setNavCollapsed] = React.useState(false);
  const [navGroupClass, setNavGroupClass] = React.useState('navGroup');
  const handleCollapse = () => {
    if (navCollapsed) {
      setNavGroupClass('navGroup');
      setNavCollapsed(false);
    } else {
      setNavGroupClass('navGroupCollapsed');
      setNavCollapsed(true);
    }
  };

  React.useEffect(() => {
    setNavGroupClass('navGroup');
    setNavCollapsed(false);
  }, [location]);

  return (
    <React.Fragment>
      <div onClick={() => navigate(routeNames.menu)}>
        <img src={logo} className='logoSmall' />
      </div>
      <BsNavbar expand='lg' id={navGroupClass}>
        <Container>
          <BsNavbar.Brand
            className='logoContainer'
            onClick={() => navigate(routeNames.menu)}
          >
            <img src={logo_purple} className='logoNav' />
          </BsNavbar.Brand>
          {!navCollapsed ? (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              __
              <br />
              __
              <br />
              __
            </BsNavbar.Toggle>
          ) : (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              <div className='navToggle'>
                <div className='navToggleIn'>
                  <VectorCross className='navToggleVector' />
                </div>
              </div>
            </BsNavbar.Toggle>
          )}
          <BsNavbar.Collapse
            className='justify-content-end'
            id='navbarResponsive'
          >
            <Nav className='ms-auto navbarScrollable' navbarScroll>
              <BsNavbar.Brand
                className='logoSmallContainer'
                onClick={() => navigate(routeNames.menu)}
              >
                <img src={logo_purple} className='logoNav' />
              </BsNavbar.Brand>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.menu)}
                >
                  Game Modes
                </div>
              </Nav.Item>
              {userSession.isBlockchainAccount ? (
                <Nav.Item>
                  <div
                    className='navLink'
                    onClick={() => navigate(routeNames.mint)}
                  >
                    Mint
                  </div>
                </Nav.Item>
              ) : null}
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.nftsearch)}
                >
                  NFTs Search
                </div>
              </Nav.Item>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.factions)}
                >
                  Factions
                </div>
              </Nav.Item>
              {userSession.isAllowed ? (
                <Nav.Item>
                  <div
                    className='navLink'
                    onClick={() => navigate(routeNames.admin)}
                  >
                    Administration
                  </div>
                </Nav.Item>
              ) : null}
              <div className='navBtnContainerSmall'>
                <Nav.Item>
                  <div
                    onClick={() => navigate(routeNames.account)}
                    className='btnConnect'
                  >
                    <div className='btnConnectIn'>
                      <div className='btnConnectText'>ACCOUNT</div>
                    </div>
                  </div>
                </Nav.Item>
              </div>
            </Nav>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
      <div className='navBtnContainer'>
        <div
          onClick={() => navigate(routeNames.account)}
          className='btnConnect'
        >
          <div className='btnConnectIn'>
            <div className='btnConnectText'>ACCOUNT</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavbarMenu;

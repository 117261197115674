import * as React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { initScore, leaderboardMaxRanking } from 'config';
import { idItemPowerPotion, idItemLifePotion, idItemSpeedPotion } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import ArenaLdbDatasDataService from '../../../services/arenaldbdatas.service';
import ArenaLdbLogsDataService from '../../../services/arenaldblogs.service';
import FactionDataService from '../../../services/faction.service';
import SettingDataService from '../../../services/setting.service';
import { ArenaLdbDatasStateType } from '../../../types/arenaldbdatas.type';
import { ArenaLdbLogsStateType } from '../../../types/arenaldblogs.type';
import { FactionsStateType } from '../../../types/faction.type';
import angelic from './../../../assets/img/angelic.png';
import meiyo from './../../../assets/img/meiyo.png';
import night from './../../../assets/img/night.png';

const tabItems = [
  { id: 0, title: 'Top 50 Ranking' },
  { id: 1, title: 'My Ranking' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const ArenaRanked = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const [idLeaderboard, setIdLeaderboard] = React.useState(0);
  const [currentLeaderboard, setCurrentLeaderboard] = React.useState(0);

  const [idUser, setIdUser] = React.useState(0);
  const [pseudoUser, setPseudoUser] = React.useState('');
  const [showUserRanking, setShowUserRanking] = React.useState(false);

  const handleSelectUser = (id_user: number, pseudo: string) => {
    if (userSession.userID == id_user) {
      setActiveTab(1);
      setIdUser(userSession.userID);
      setShowTopRanking(false);
      setShowMyRanking(true);
    } else {
      setIdUser(id_user);
      setPseudoUser(pseudo);
      setShowTopRanking(false);
      setShowMyRanking(true);
      setShowUserRanking(true);
    }

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleBackTopRanking = () => {
    setIdUser(0);
    setPseudoUser('');
    setShowTopRanking(true);
    setShowMyRanking(false);
    setShowUserRanking(false);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const [activeTab, setActiveTab] = React.useState(0);
  const [showTopRanking, setShowTopRanking] = React.useState(true);
  const [showMyRanking, setShowMyRanking] = React.useState(false);
  const handleShow = (id: number) => {
    setActiveTab(id);

    switch (id) {
      case 0:
        setShowTopRanking(true);
        setShowMyRanking(false);
        break;
      case 1:
        setIdUser(userSession.userID);
        setShowTopRanking(false);
        setShowMyRanking(true);
        break;
    }
  };

  const handleShowTopRanking = () => {
    setShowTopRanking(true);
    setShowMyRanking(false);
  };
  const handleShowMyRanking = () => {
    setIdUser(userSession.userID);
    setShowTopRanking(false);
    setShowMyRanking(true);
  };

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });

  const [leaderboardsIds, setLeaderboardsIds] =
    React.useState<ArenaLdbLogsStateType>({
      arena_ldb_logs: [],
      message: '',
      status: ''
    });
  const [leaderboardsInfos, setLeaderboardsInfos] =
    React.useState<ArenaLdbLogsStateType>({
      arena_ldb_logs: [],
      message: '',
      status: ''
    });
  const [leaderboardsDatas, setLeaderboardsDatas] =
    React.useState<ArenaLdbDatasStateType>({
      arena_ldb_datas: [],
      message: '',
      status: ''
    });

  const [userRanked, setUserRanked] = React.useState(false);

  const [currentPoints, setCurrentPoints] = React.useState(0);

  const [rankedInfos, setRankedInfos] = React.useState({
    rankedArenaPoints: 0,
    nbRankedTotal: 0,
    nbRankedWin: 0,
    nbRankedWinPoints: 0,
    nbRankedLose: 0,
    nbRankedLosePoints: 0,
    ratioWin: '',
    ratioLose: '',
    minPointsWin: 0,
    maxPointsWin: 0,
    minPointsLose: 0,
    maxPointsLose: 0
  });

  const [warriorsInfos, setWarriorsInfos] = React.useState({
    nbWarrior: 0,
    nbSamurai: 0,
    nbDaimyos: 0,
    nbShogun: 0,
    ratioWarrior: '',
    ratioSamurai: '',
    ratioDaimyos: '',
    ratioShogun: ''
  });

  const [fightInfos, setFightInfos] = React.useState({
    nbSkillSword: 0,
    nbSkillDagger: 0,
    nbSkillStaff: 0,
    nbDamagePotion: 0,
    nbLifePotion: 0,
    nbSpeedPotion: 0,
    ratioSword: '',
    ratioDagger: '',
    ratioStaff: '',
    ratioDamagePotion: '',
    ratioLifePotion: '',
    ratioSpeedPotion: ''
  });

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'arena_leaderboard';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setIdLeaderboard(data.setting.value);
        setCurrentLeaderboard(data.setting.value);
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsIdData = () => {
    ArenaLdbLogsDataService.findAllLeaderboardId().then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsIds({
          arena_ldb_logs: data.arena_ldb_logs,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsLogsData = () => {
    ArenaLdbLogsDataService.findAllByLeaderboard(idLeaderboard).then(
      ({ data }) => {
        if (data.status == 'success') {
          setLeaderboardsInfos({
            arena_ldb_logs: data.arena_ldb_logs,
            status: data.status
          });
        }
      }
    );
  };

  const fetchLeaderboardsDatasData = () => {
    const params: any = {};
    params['id_user'] = idUser;
    params['id_leaderboard'] = idLeaderboard;

    ArenaLdbDatasDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsDatas({
          arena_ldb_datas: data.arena_ldb_datas,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsDatasTotal = () => {
    let isRanked = false;
    leaderboardsInfos.arena_ldb_logs.map((arena_ldb_log) => {
      if (arena_ldb_log.id_user == idUser) {
        isRanked = true;
      }
    });

    let rankedArenaPoints = 0;
    let nbRankedTotal = 0;
    let nbRankedWin = 0;
    let nbRankedWinPoints = 0;
    let nbRankedLose = 0;
    let nbRankedLosePoints = 0;
    let minPointsWin = 0;
    let maxPointsWin = 0;
    let minPointsLose = 0;
    let maxPointsLose = 0;

    let nbWarrior = 0;
    let nbSamurai = 0;
    let nbDaimyos = 0;
    let nbShogun = 0;

    let nbSkillSword = 0;
    let nbSkillDagger = 0;
    let nbSkillStaff = 0;
    let nbDamagePotion = 0;
    let nbLifePotion = 0;
    let nbSpeedPotion = 0;

    leaderboardsDatas.arena_ldb_datas.map((arena_ldb_data) => {
      nbRankedTotal++;
      rankedArenaPoints += arena_ldb_data.points;

      const arena_registration = arena_ldb_data.arena_registration;

      switch (arena_registration.attribut.nft_hierarchy) {
        case 'Warrior':
          nbWarrior++;
          break;
        case 'Samurai':
          nbSamurai++;
          break;
        case 'Daimyos':
          nbDaimyos++;
          break;
        case 'Shogun':
          nbShogun++;
          break;
        default:
          break;
      }

      arena_registration.arena_skills.map((arena_skill) => {
        switch (arena_skill.id_skill) {
          case 1:
            nbSkillSword++;
            break;
          case 2:
            nbSkillDagger++;
            break;
          case 3:
            nbSkillStaff++;
            break;
          default:
            break;
        }
      });

      arena_registration.arena_items.map((arena_item) => {
        switch (arena_item.id_item) {
          case idItemPowerPotion: // Power
            nbDamagePotion++;
            break;
          case idItemLifePotion: // HP
            nbLifePotion++;
            break;
          case idItemSpeedPotion: // Speed
            nbSpeedPotion++;
            break;
          default:
            break;
        }
      });

      if (arena_registration.flg_winner) {
        nbRankedWin++;
        nbRankedWinPoints += arena_ldb_data.points;
        if (arena_ldb_data.points < minPointsWin || minPointsWin == 0) {
          minPointsWin = arena_ldb_data.points;
        }
        if (arena_ldb_data.points > maxPointsWin || maxPointsWin == 0) {
          maxPointsWin = arena_ldb_data.points;
        }
      } else {
        nbRankedLose++;
        nbRankedLosePoints += arena_ldb_data.points;
        if (arena_ldb_data.points > minPointsLose || minPointsLose == 0) {
          minPointsLose = arena_ldb_data.points;
        }
        if (arena_ldb_data.points < maxPointsLose || maxPointsLose == 0) {
          maxPointsLose = arena_ldb_data.points;
        }
      }
    });

    const ratioWin = ((nbRankedWin / nbRankedTotal) * 100).toFixed(2);
    const ratioLose = ((nbRankedLose / nbRankedTotal) * 100).toFixed(2);

    const ratioWarrior = ((nbWarrior / nbRankedTotal) * 100).toFixed(2);
    const ratioSamurai = ((nbSamurai / nbRankedTotal) * 100).toFixed(2);
    const ratioDaimyos = ((nbDaimyos / nbRankedTotal) * 100).toFixed(2);
    const ratioShogun = ((nbShogun / nbRankedTotal) * 100).toFixed(2);

    const ratioSword = ((nbSkillSword / nbRankedTotal) * 100).toFixed(2);
    const ratioDagger = ((nbSkillDagger / nbRankedTotal) * 100).toFixed(2);
    const ratioStaff = ((nbSkillStaff / nbRankedTotal) * 100).toFixed(2);
    const ratioDamagePotion = ((nbDamagePotion / nbRankedTotal) * 100).toFixed(
      2
    );
    const ratioLifePotion = ((nbLifePotion / nbRankedTotal) * 100).toFixed(2);
    const ratioSpeedPotion = ((nbSpeedPotion / nbRankedTotal) * 100).toFixed(2);

    setUserRanked(isRanked);

    const curPoints = initScore + rankedArenaPoints;
    setCurrentPoints(curPoints);

    setRankedInfos({
      rankedArenaPoints: rankedArenaPoints,
      nbRankedTotal: nbRankedTotal,
      nbRankedWin: nbRankedWin,
      nbRankedWinPoints: nbRankedWinPoints,
      nbRankedLose: nbRankedLose,
      nbRankedLosePoints: nbRankedLosePoints,
      ratioWin: ratioWin,
      ratioLose: ratioLose,
      minPointsWin: minPointsWin,
      maxPointsWin: maxPointsWin,
      minPointsLose: minPointsLose,
      maxPointsLose: maxPointsLose
    });

    setWarriorsInfos({
      nbWarrior: nbWarrior,
      nbSamurai: nbSamurai,
      nbDaimyos: nbDaimyos,
      nbShogun: nbShogun,
      ratioWarrior: ratioWarrior,
      ratioSamurai: ratioSamurai,
      ratioDaimyos: ratioDaimyos,
      ratioShogun: ratioShogun
    });

    setFightInfos({
      nbSkillSword: nbSkillSword,
      nbSkillDagger: nbSkillDagger,
      nbSkillStaff: nbSkillStaff,
      nbDamagePotion: nbDamagePotion,
      nbLifePotion: nbLifePotion,
      nbSpeedPotion: nbSpeedPotion,
      ratioSword: ratioSword,
      ratioDagger: ratioDagger,
      ratioStaff: ratioStaff,
      ratioDamagePotion: ratioDamagePotion,
      ratioLifePotion: ratioLifePotion,
      ratioSpeedPotion: ratioSpeedPotion
    });
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    fetchLeaderboardsIdData();
    fetchFactionsData();
  }, []);

  React.useEffect(() => {
    if (idLeaderboard != 0) {
      fetchLeaderboardsLogsData();
    }
  }, [idLeaderboard]);

  React.useEffect(() => {
    if (idUser != 0) {
      fetchLeaderboardsDatasData();
    }
  }, [idLeaderboard, idUser]);

  React.useEffect(() => {
    if (
      leaderboardsInfos.status == 'success' &&
      leaderboardsDatas.status == 'success'
    ) {
      fetchLeaderboardsDatasTotal();
    }
  }, [leaderboardsInfos, leaderboardsDatas]);

  const { factions } = factionsInfos;
  const { arena_ldb_logs } = leaderboardsInfos;

  return (
    <>
      <div className='boxContainer topArenaContainer' ref={refToTop}>
        <div className='stripContainerLeft'>
          <div className='stripBoxLeft'>
            <div className='stripBoxLeftRank'>
              <div className='stripInfos'>
                <div className='stripInfosIn'>
                  <div className='stripInfosText'>Season {idLeaderboard}</div>
                </div>
              </div>
            </div>
            <div className='stripBoxLeftList'>
              <div className='text-center'>
                <Form.Group>
                  <Form.Row>
                    <Col xs='auto'>
                      <TextField
                        select
                        value={idLeaderboard}
                        variant='standard'
                        onChange={(e) =>
                          setIdLeaderboard(parseInt(e.target.value))
                        }
                      >
                        <MenuItem value={currentLeaderboard}>
                          Current Season
                        </MenuItem>
                        {leaderboardsIds.arena_ldb_logs.map((row, i) => {
                          if (
                            row.id_leaderboard != currentLeaderboard &&
                            i < 5
                          ) {
                            return (
                              <MenuItem key={i} value={row.id_leaderboard}>
                                Season {row.id_leaderboard}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                    </Col>
                  </Form.Row>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className='boxContainer'>
          <div className='inventoryTabsWrapper'>
            <div className='inventoryTabsLine'></div>
            {showUserRanking ? (
              <div className='inventoryTabsItemsWrapper'>
                <div
                  className='inventoryTabsItems inventoryTabsItemsActive'
                  onClick={handleBackTopRanking}
                >
                  Back
                </div>
              </div>
            ) : (
              <div className='inventoryTabsItemsWrapper'>
                {tabItems.map((item) => (
                  <div
                    className={classNames(
                      item.id == activeTab
                        ? 'inventoryTabsItems inventoryTabsItemsActive'
                        : 'inventoryTabsItems'
                    )}
                    onClick={() => handleShow(item.id)}
                    key={item.id}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            <div className='inventoryTabsLine'></div>
          </div>
        </div>
      </div>

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosBox'>
              {userSession.userID == idUser ? (
                <div className='infosSubTitle'>My Ranking</div>
              ) : (
                <div className='infosSubTitle'>{pseudoUser} Ranking</div>
              )}
            </div>
            <div className='table-responsive text-center'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Rank</th>
                    <th className='border-0'>User</th>
                    <th className='border-0 iconCol'>Faction</th>
                    <th className='border-0'></th>
                    <th className='border-0'>Score</th>
                    <th className='border-0'>Fights</th>
                    <th className='border-0'>Fights won</th>
                  </tr>
                </thead>
                {arena_ldb_logs.length == 0 || !userRanked ? (
                  <tbody>
                    <tr className='rowTableContent textResult'>
                      <td colSpan={7}>
                        <span>No leaderboard ranking</span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {arena_ldb_logs.map((arena_ldb_log, i) => {
                      let isUser = false;
                      let factionName = '';
                      let factionImg = '';

                      if (arena_ldb_log.id_user == idUser) {
                        isUser = true;

                        factions.map((faction) => {
                          if (
                            faction.id_faction == arena_ldb_log.user.id_faction
                          ) {
                            factionName = faction.name;
                          }
                        });

                        switch (arena_ldb_log.user.id_faction) {
                          case 1:
                            factionImg = angelic;
                            break;
                          case 2:
                            factionImg = night;
                            break;
                          case 3:
                            factionImg = meiyo;
                            break;
                        }
                      }

                      if (isUser) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{arena_ldb_log.rank}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.user.pseudo}</span>
                            </td>
                            <td>
                              <span>{factionName}</span>
                            </td>
                            <td>
                              <img
                                src={`${factionImg}`}
                                className='imgFactionProfile'
                              />
                            </td>
                            <td className='textBold'>
                              <span>{arena_ldb_log.score}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.nb_fight}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.nb_win}</span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Ranking Information</div>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Initial Points</span>
                <span className='dataAttribut'>{initScore}</span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Ranked Arena Points</span>
                <span className='dataAttribut'>
                  {rankedInfos.rankedArenaPoints}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Current Points</span>
                <span className='dataAttribut'>{currentPoints}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Ranked Arena</div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Fights</span>
                  <span className='dataAttribut'>
                    {rankedInfos.nbRankedTotal}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Win</span>
                  <span className='dataAttribut'>
                    {rankedInfos.nbRankedWin}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({rankedInfos.ratioWin} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Points won</span>
                  <span className='dataAttribut'>
                    {rankedInfos.nbRankedWinPoints}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Lose</span>
                  <span className='dataAttribut'>
                    {rankedInfos.nbRankedLose}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({rankedInfos.ratioLose} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total Points lost</span>
                  <span className='dataAttribut'>
                    {rankedInfos.nbRankedLosePoints}
                  </span>
                </div>
              </div>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Points Information</div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Min Points won</span>
                  <span className='dataAttribut'>
                    {rankedInfos.minPointsWin}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Max Points won</span>
                  <span className='dataAttribut'>
                    {rankedInfos.maxPointsWin}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Min Points lost</span>
                  <span className='dataAttribut'>
                    {rankedInfos.minPointsLose}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Max Points lost</span>
                  <span className='dataAttribut'>
                    {rankedInfos.maxPointsLose}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Warriors Usage Information</div>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Warrior</span>
                <span className='dataAttribut'>
                  {warriorsInfos.nbWarrior}
                  {rankedInfos.nbRankedTotal > 0 ? (
                    <span>&nbsp;({warriorsInfos.ratioWarrior} %)</span>
                  ) : null}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Samurai</span>
                <span className='dataAttribut'>
                  {warriorsInfos.nbSamurai}
                  {rankedInfos.nbRankedTotal > 0 ? (
                    <span>&nbsp;({warriorsInfos.ratioSamurai} %)</span>
                  ) : null}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Daimyos</span>
                <span className='dataAttribut'>
                  {warriorsInfos.nbDaimyos}
                  {rankedInfos.nbRankedTotal > 0 ? (
                    <span>&nbsp;({warriorsInfos.ratioDaimyos} %)</span>
                  ) : null}
                </span>
              </div>
              <div className='dataSepartorLine'>
                <span className='dataTitle'>Shogun</span>
                <span className='dataAttribut'>
                  {warriorsInfos.nbShogun}
                  {rankedInfos.nbRankedTotal > 0 ? (
                    <span>&nbsp;({warriorsInfos.ratioShogun} %)</span>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMyRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='dataContainer'>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Skills Usage Information</div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Sword</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbSkillSword}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioSword} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Dagger</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbSkillDagger}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioDagger} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Staff</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbSkillStaff}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioStaff} %)</span>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className='dataBox'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Items Usage Information</div>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Damage Potion</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbDamagePotion}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioDamagePotion} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Life Potion</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbLifePotion}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioLifePotion} %)</span>
                    ) : null}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Speed Potion</span>
                  <span className='dataAttribut'>
                    {fightInfos.nbSpeedPotion}
                    {rankedInfos.nbRankedTotal > 0 ? (
                      <span>&nbsp;({fightInfos.ratioSpeedPotion} %)</span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showTopRanking && (
        <div className='boxContainer topContainer'>
          <div className='card-body p-2'>
            <div className='infosBox'>
              <div className='infosSubTitle'>
                Top {leaderboardMaxRanking} Ranking
              </div>
            </div>
            <div className='table-responsive text-center'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Rank</th>
                    <th className='border-0'>User</th>
                    <th className='border-0 iconCol'>Faction</th>
                    <th className='border-0'></th>
                    <th className='border-0'>Score</th>
                    <th className='border-0'>Fights</th>
                    <th className='border-0'>Fights won</th>
                  </tr>
                </thead>
                {arena_ldb_logs.length == 0 ? (
                  <tbody>
                    <tr className='rowTableContent textResult'>
                      <td colSpan={7}>
                        <span>No leaderboard ranking</span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {arena_ldb_logs.map((arena_ldb_log, i) => {
                      let factionName = '';
                      let factionImg = '';

                      factions.map((faction) => {
                        if (
                          faction.id_faction == arena_ldb_log.user.id_faction
                        ) {
                          factionName = faction.name;
                        }
                      });

                      switch (arena_ldb_log.user.id_faction) {
                        case 1:
                          factionImg = angelic;
                          break;
                        case 2:
                          factionImg = night;
                          break;
                        case 3:
                          factionImg = meiyo;
                          break;
                      }

                      let classname;
                      if (userSession.userID == arena_ldb_log.id_user) {
                        classname = 'rowTableSelfResult textResult';
                      } else {
                        classname = 'rowTableResult textResult';
                      }

                      if (i < leaderboardMaxRanking) {
                        return (
                          <tr
                            key={i}
                            className={classname}
                            onClick={() =>
                              handleSelectUser(
                                arena_ldb_log.id_user,
                                arena_ldb_log.user.pseudo
                              )
                            }
                          >
                            <td>
                              <span>{arena_ldb_log.rank}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.user.pseudo}</span>
                            </td>
                            <td>
                              <span>{factionName}</span>
                            </td>
                            <td>
                              <img
                                src={`${factionImg}`}
                                className='imgFactionProfile'
                              />
                            </td>
                            <td className='textBold'>
                              <span>{arena_ldb_log.score}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.nb_fight}</span>
                            </td>
                            <td>
                              <span>{arena_ldb_log.nb_win}</span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArenaRanked;

//import './index.css';

import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom';
import { googleClientID } from 'config';
import App from './App';

import './assets/sass/theme.scss';
import './assets/sass/loading.scss';
import './assets/sass/styles.css';
import './assets/sass/design.css';

import './assets/sass/design_small.scss';
import './assets/sass/design_large.scss';
import './assets/sass/design_common.scss';
import './assets/sass/design_modal.scss';

ReactDOM.render(
  <GoogleOAuthProvider clientId={googleClientID}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

import * as React from 'react';
import {
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faFrown,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown,
  faPencil,
  faTrash,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Button, Slider } from '@mui/material';
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  Button as ReactButton
} from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import ImageMapper from 'react-img-mapper';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { ResizeObserver } from 'resize-observer';
import { routeNames } from 'routes';
import AreasFonctions from '../../../common/areas';
import AreaDataService from '../../../services/area.service';
import BattleDataService from '../../../services/battle.service';
import ConquestDataService from '../../../services/conquest.service';
import ConquestLogsDataService from '../../../services/conquestslogs.service';
import FactionDataService from '../../../services/faction.service';
import FactionsInventoriesDataService from '../../../services/factionsinventories.service';
import InventoriesDataService from '../../../services/inventories.service';
import ItemsDataService from '../../../services/items.service';
import SettingDataService from '../../../services/setting.service';
import {
  ColorsStateType,
  colorsEmpty,
  AreasStateType
} from '../../../types/area.type';
import { BattleStateType, battleEmpty } from '../../../types/battle.type';
import { GameBonusesStateType } from '../../../types/bonuses.type';
import {
  IConquestData,
  ConquestsStateType
} from '../../../types/conquest.type';
import {
  ConquestsLogsInfos,
  conquestEmpty
} from '../../../types/conquestslogs.type';
import { FactionsStateType } from '../../../types/faction.type';
import {
  FactionInventoryStateType,
  FactionInventoryInfos,
  factionInventoryEmpty
} from '../../../types/factionsinventories.type';
import {
  InventoryStateType,
  InventoryInfos,
  IInventoryInfos,
  inventoryEmpty
} from '../../../types/inventories.type';
import { ItemsStateType } from '../../../types/items.type';
import { UserStateType } from '../../../types/user.type';
import angelic from './../../../assets/img/angelic.png';
import emperorIcon from './../../../assets/img/icons/emperor.png';
import infoIcon from './../../../assets/img/icons/info.png';
import marketplace from './../../../assets/img/icons/marketplace.png';
import merchantIcon from './../../../assets/img/icons/merchant.png';
import meiyo from './../../../assets/img/meiyo.png';
import night from './../../../assets/img/night.png';
import reiwa from './../../../assets/img/reiwa.png';

const Battle = ({
  userInfos,
  gameBonusesInfos,
  conquestInfosInit,
  userLeader
}: {
  userInfos: UserStateType;
  gameBonusesInfos: GameBonusesStateType;
  conquestInfosInit: ConquestsStateType;
  userLeader: boolean;
}) => {
  const navigate = useNavigate();

  function MyTimerPhase({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshPhase()
    });

    return (
      <span>
        <span className='timerNumber'>{hours < 10 ? '0' + hours : hours}</span>
        <span className='timerSep'>:</span>
        <span className='timerNumber'>
          {minutes < 10 ? '0' + minutes : minutes}
        </span>
        <span className='timerSep'>:</span>
        <span className='timerNumber'>
          {seconds < 10 ? '0' + seconds : seconds}
        </span>
      </span>
    );
  }

  function MyTimerBattle({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshBattle()
    });

    return (
      <span>
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  const maxItems = 50;
  const deployPercent = 15;

  const [showSettings, setShowSettings] = React.useState(false);
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const handleShowSettings = () => {
    setShowSettings(true);
  };

  const [showPlayer, setShowPlayer] = React.useState(true);
  const [showFaction, setShowFaction] = React.useState(false);
  const handleShowPlayer = () => {
    setShowPlayer(true);
    setShowFaction(false);
  };
  const handleShowFaction = () => {
    setShowPlayer(false);
    setShowFaction(true);
  };

  const [showPower, setShowPower] = React.useState(true);
  const [showBonus, setShowBonus] = React.useState(false);
  const handleShowPower = () => {
    setShowPower(true);
    setShowBonus(false);
  };
  const handleShowBonus = () => {
    setShowPower(false);
    setShowBonus(true);
  };

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [phaseInfos, setPhaseInfos] = React.useState({
    nextPhase: new Date()
  });

  const [factionBonus, setFactionBonus] = React.useState(false);
  const [gameBonus, setGameBonus] = React.useState(false);
  const [merchantBonus, setMerchantBonus] = React.useState({
    merchant: false,
    merchantIdAreas: [0],
    merchantAreas: [''],
    merchantBonusValue: 0
  });
  const [emperorVisitBonus, setEmperorVisitBonusBonus] = React.useState({
    emperorVisit: false,
    emperorVisitIdAreas: [0],
    emperorVisitAreas: [''],
    emperorVisitBonusValue: 0
  });

  let count = 0;
  let countItems = 0;
  let countFactionItems = 0;
  let countFactionBonusItems = 0;
  const [filteredArea, setFilteredArea] = React.useState({
    id_area: 0,
    name: '',
    ratio: '',
    numDeployNFTs: 0,
    numDeployItems: 0,
    totalPower: '',
    numDeployNFTsFaction: 0,
    numDeployItemsFaction: 0,
    totalPowerFaction: ''
  });

  const [selectedArea, setSelectedArea] = React.useState({
    id_area: 0,
    name: '',
    id_faction: 0,
    NFTsPowerPercent: ''
  });
  const [selectedItem, setSelectedItem] = React.useState({
    divStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties,
    rarity: '',
    attack: false,
    defense: false,
    idConquest: 0,
    id_item: 0,
    item_type: 0,
    item_identifier: '',
    item_img: '',
    item_name: '',
    item_name_1: '',
    item_name_2: '',
    item_label: '',
    item_value: '',
    item_qty: 0
  });

  const mapMaxWidth = 2048;
  const mapMaxHeight = mapMaxWidth * 0.75;
  const [mapDimensions, setMapDimensions] = React.useState({
    width: 0,
    height: 0,
    style: {
      '--mapWidth': '0px',
      '--mapHeight': '0px',
      '--mapRatioWidth': 0,
      '--mapRatioHeight': 0
    } as React.CSSProperties
  });

  const mapFieldSelector = document.querySelector('.mapField');
  const mapContainerSize = React.useRef<HTMLDivElement>(null);

  const [AreasInfos, setAreasInfos] = React.useState<AreasStateType>({
    areas: [],
    message: '',
    status: ''
  });
  const [battleInfos, setBattleInfos] = React.useState<BattleStateType>({
    battle: battleEmpty,
    message: '',
    status: ''
  });
  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });
  const [conquestInfos, setConquestInfos] =
    React.useState<ConquestsStateType>(conquestInfosInit);
  const [conquestFactionInfos, setConquestFactionInfos] =
    React.useState<ConquestsStateType>({
      conquests: [],
      message: '',
      status: ''
    });
  const [inventoryInfos, setInventoryInfos] =
    React.useState<InventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [inventoryGrpInfos, setInventoryGrpInfos] =
    React.useState<InventoryInfos>({
      inventory: []
    });
  const [factionInventoryInfos, setFactionInventoryInfos] =
    React.useState<FactionInventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [factionInventoryGrpInfos, setFactionInventoryGrpInfos] =
    React.useState<FactionInventoryInfos>({
      factionInventory: []
    });
  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });

  const [AREAS_MAP, setAreasMap] = React.useState<any>({
    name: '',
    areas: []
  });
  const [conquestLogsInfos, setConquestLogsInfos] =
    React.useState<ConquestsLogsInfos>({
      conquests_logs: []
    });

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result != null) {
      return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      };
    }
  }

  const [factionDeployedPower, setFactionDeployedPower] = React.useState(0);

  const handleFilterArea = (id_area: any) => {
    let areaFilteredName = '';
    AreasInfos.areas.map((area) => {
      if (area.id_area == id_area) {
        areaFilteredName = area.name;
      }
    });

    const areaPower = new Array(factionsInfos.factions.length);
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      areaPower[i] = 0;
    }
    conquestFactionInfos.conquests.map((conquest) => {
      if (conquest.nft_type == 1) {
        if (conquest.attribut != null) {
          areaPower[conquest.id_faction - 1] += conquest.attribut.nft_power;
        }
      }
    });

    let faction_power_max = 0;
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      if (faction_power_max == 0 || areaPower[i] > faction_power_max) {
        faction_power_max = areaPower[i];
      }
    }

    let ratio = 0;
    if (areaPower[userInfos.user.id_faction - 1] > 0) {
      ratio = faction_power_max / areaPower[userInfos.user.id_faction - 1];
    } else {
      ratio = 1;
    }

    for (let i = 0; i < factionsInfos.factions.length; i++) {
      const faction = factionsInfos.factions[i];
      if (faction.id_faction == userInfos.user.id_faction) {
        setFactionDeployedPower(areaPower[i]);
      }
    }

    let nbDeployNFTs = 0;
    let nbDeployItems = 0;
    let totalPower = 0;
    let nbDeployNFTsFaction = 0;
    let nbDeployItemsFaction = 0;
    let totalPowerFaction = 0;
    let isBonus = false;
    let totalBonus = 0;

    conquestFactionInfos.conquests.map((conquest) => {
      if (conquest.id_faction == userInfos.user.id_faction) {
        let availableFaction = true;
        if (id_area != 0 && conquest.id_area != id_area) {
          availableFaction = false;
        }
        if (availableFaction) {
          if (conquest.nft_type == 1) {
            if (conquest.attribut != null) {
              nbDeployNFTsFaction++;
              totalPowerFaction += conquest.attribut.nft_power * ratio;
            }
          }

          if (conquest.nft_type == 2) {
            if (conquest.item?.item_type == 2) {
              nbDeployItemsFaction++;
              totalPowerFaction += conquest.item.item_power;
            }

            if (
              conquest.item?.item_type == 4 &&
              conquest.item.bonus_value > 0
            ) {
              totalBonus += conquest.item.bonus_value;
              isBonus = true;
            }
          }
        }
      }

      if (conquest.id_user == userInfos.user.id_user) {
        let available = true;
        if (id_area != 0 && conquest.id_area != id_area) {
          available = false;
        }
        if (available) {
          if (conquest.nft_type == 1) {
            if (conquest.attribut != null) {
              nbDeployNFTs++;
              totalPower += conquest.attribut.nft_power * ratio;
            }
          }

          if (conquest.nft_type == 2) {
            let availableItem = true;
            const identifier = conquest.nft_identifier.split('-');

            if (identifier[1].substring(0, 1) == 'f') {
              availableItem = false;
            }

            if (availableItem) {
              if (conquest.item?.item_type == 2) {
                nbDeployItems++;
                totalPower += conquest.item.item_power;
              }
            }
          }
        }
      }
    });

    if (isBonus) {
      totalPowerFaction += (totalPowerFaction * totalBonus) / 100;
      totalPower += (totalPower * totalBonus) / 100;
    }

    setFilteredArea({
      id_area: id_area,
      name: areaFilteredName,
      ratio: ratio.toFixed(6),
      numDeployNFTs: nbDeployNFTs,
      numDeployItems: nbDeployItems,
      totalPower: totalPower.toFixed(2),
      numDeployNFTsFaction: nbDeployNFTsFaction,
      numDeployItemsFaction: nbDeployItemsFaction,
      totalPowerFaction: totalPowerFaction.toFixed(2)
    });
  };

  const [showMapBonus, setShowMapBonus] = React.useState(false);
  const handleCloseMapBonus = () => {
    setShowMapBonus(false);
  };
  const handleShowMapBonus = () => {
    setShowMapBonus(true);
  };

  const [showDeploy, setShowDeploy] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const handleCloseDeploy = () => {
    if (showDeploy) {
      if (showPlayer) {
        fetchConquestData();
        fetchConquestFactionData();
      } else {
        fetchConquestFactionData();
      }
    }

    setSelectedArea({
      id_area: 0,
      name: '',
      id_faction: 0,
      NFTsPowerPercent: ''
    });
    setShowDeploy(false);
    setShowResults(false);
  };
  const handleShowDeploy = (areaId: any) => {
    switch (areaId) {
      case 1:
      case 2:
      case 3:
        setShowDeploy(false);
        setShowResults(false);
        navigate(routeNames.conquestboard);
        break;
      default:
        AreaDataService.getAll().then(({ data }) => {
          if (data.status == 'success') {
            data.areas.map(async (area) => {
              if (area.id_area == areaId) {
                let NFTsPowerPercentRounded = '';

                if (!area.flg_battle) {
                  let NFTsPowerTotal = 0;
                  let NFTsPower = 0;
                  let NFTsPowerPercent = 0;

                  await ConquestDataService.findAllByConquest(
                    currentConquest
                  ).then((conquestData) => {
                    if (conquestData.data.status == 'success') {
                      conquestData.data.conquests.map((conquest) => {
                        if (conquest.id_faction == userInfos.user.id_faction) {
                          if (conquest.nft_type == 1) {
                            if (conquest.attribut != null) {
                              NFTsPowerTotal += conquest.attribut.nft_power;

                              if (conquest.id_area == areaId) {
                                NFTsPower += conquest.attribut.nft_power;
                              }
                            }
                          }
                        }
                      });
                    }
                  });

                  if (NFTsPower > 0 && NFTsPowerTotal > 0) {
                    NFTsPowerPercent = (NFTsPower / NFTsPowerTotal) * 100;
                  }
                  NFTsPowerPercentRounded = NFTsPowerPercent.toFixed(2);
                }

                setSelectedArea({
                  id_area: area.id_area,
                  name: area.name,
                  id_faction: area.id_faction,
                  NFTsPowerPercent: NFTsPowerPercentRounded
                });

                if (!area.flg_battle) {
                  setShowDeploy(true);
                  setShowResults(false);
                } else {
                  setShowDeploy(false);
                  setShowResults(true);
                }
              }
            });
          }
        });
        break;
    }
  };

  const handleChangeQuantity = (e: any) => {
    setItemQty(e.target.value);
  };

  const handleIncrementQuantity = () => {
    setItemQty(itemQty + 1);
  };

  const handleDecrementQuantity = () => {
    setItemQty(itemQty - 1);
  };

  const [massiveItems, setMassiveItems] = React.useState(false);
  const [maxQty, setMaxQty] = React.useState(0);
  const [itemQty, setItemQty] = React.useState(0);

  const [showArea, setShowArea] = React.useState(false);
  const handleCloseArea = () => {
    setShowArea(false);
  };

  const handleShowAreaD = (item: IInventoryInfos) => {
    let maximumQty = 0;
    if (item.itemsAvailableCount > maxItems) {
      maximumQty = maxItems;
    } else {
      maximumQty = item.itemsAvailableCount;
    }

    const divStyle = {
      '--rarityColor': item.itemInfos.item_rarityColor
    } as React.CSSProperties;
    const rarity = item.itemInfos.item_rarity;

    let attack = false;
    let defense = false;
    switch (item.itemInfos.flg_use) {
      case 1:
        attack = true;
        break;
      case 2:
        defense = true;
        break;
      default:
        attack = true;
        defense = true;
        break;
    }

    const id_item = item.id_item;
    const item_type = item.itemInfos.item_type;
    const item_identifier = item.itemsAvailable[0].identifier;
    const item_img = item.itemInfos.item_img;
    const item_name = item.itemInfos.item_name;

    const nameT = item.itemInfos.item_name.split(' ');

    let item_name_1 = '';
    let item_name_2 = '';
    switch (nameT.length) {
      case 1:
        item_name_1 = nameT[0];
        break;
      case 2:
        item_name_1 = nameT[0];
        item_name_2 = nameT[1];
        break;
      default:
        item_name_1 = nameT[0] + ' ' + nameT[1];
        item_name_2 = nameT[2];
        break;
    }

    let item_label = '';
    let item_value = '';
    if (item.itemInfos.item_type == 2) {
      item_label = 'Power';
      item_value = item.itemInfos.item_power.toFixed(2);
    }
    if (item.itemInfos.item_type == 4) {
      maximumQty = 1;
      if (item.itemInfos.bonus_value > 0) {
        item_label = 'Faction Power';
        item_value = '+' + item.itemInfos.bonus_value + '%';
      } else {
        item_label = 'Enemy Power';
        item_value = item.itemInfos.bonus_value + '%';
      }
    }

    const item_qty = item.itemsAvailableCount;

    setSelectedItem({
      divStyle: divStyle,
      rarity: rarity,
      attack: attack,
      defense: defense,
      idConquest: 0,
      id_item: id_item,
      item_type: item_type,
      item_identifier: item_identifier,
      item_img: item_img,
      item_name: item_name,
      item_name_1: item_name_1,
      item_name_2: item_name_2,
      item_label: item_label,
      item_value: item_value,
      item_qty: item_qty
    });

    setMaxQty(maximumQty);
    setItemQty(1);
    setMassiveItems(true);
    setShowArea(true);
  };

  const handleShowAreaU = (conquest: IConquestData) => {
    const idConquest = conquest.id;
    const idItem = conquest.id_item;
    const item_identifier = conquest.nft_identifier;

    let divStyle = {
      '--rarityColor': '#000'
    } as React.CSSProperties;
    let rarity = '';
    let attack = false;
    let defense = false;
    let id_item = 0;
    let item_type = 0;
    let item_img = '';
    let item_name = '';
    let item_name_1 = '';
    let item_name_2 = '';
    let item_label = '';
    let item_value = '';
    const item_qty = 1;

    itemsInfos.items.map((item) => {
      if (item.id_item == idItem) {
        divStyle = {
          '--rarityColor': item.item_rarityColor
        } as React.CSSProperties;
        rarity = item.item_rarity;
        switch (item.flg_use) {
          case 1:
            attack = true;
            break;
          case 2:
            defense = true;
            break;
          default:
            attack = true;
            defense = true;
            break;
        }
        id_item = item.id_item;
        item_type = item.item_type;
        item_img = item.item_img;
        item_name = item.item_name;

        const nameT = item.item_name.split(' ');

        switch (nameT.length) {
          case 1:
            item_name_1 = nameT[0];
            break;
          case 2:
            item_name_1 = nameT[0];
            item_name_2 = nameT[1];
            break;
          default:
            item_name_1 = nameT[0] + ' ' + nameT[1];
            item_name_2 = nameT[2];
            break;
        }

        if (item.item_type == 2) {
          item_label = 'Power';
          item_value = item.item_power.toFixed(2);
        }
        if (item.item_type == 4) {
          if (item.bonus_value > 0) {
            item_label = 'Faction Power';
            item_value = '+' + item.bonus_value + '%';
          } else {
            item_label = 'Enemy Power';
            item_value = item.bonus_value + '%';
          }
        }
      }
    });

    setSelectedItem({
      divStyle: divStyle,
      rarity: rarity,
      attack: attack,
      defense: defense,
      idConquest: idConquest,
      id_item: id_item,
      item_type: item_type,
      item_identifier: item_identifier,
      item_img: item_img,
      item_name: item_name,
      item_name_1: item_name_1,
      item_name_2: item_name_2,
      item_label: item_label,
      item_value: item_value,
      item_qty: item_qty
    });

    setMaxQty(1);
    setItemQty(1);
    setMassiveItems(false);
    setShowArea(true);
  };

  const fetchAreasData = () => {
    AreaDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setAreasInfos({
          areas: data.areas,
          status: data.status
        });

        const colorNeutre = '#95A5A6';
        const colorHoverNeutre = '#BFC9CA';
        const colorArea: ColorsStateType = {
          colors: colorsEmpty
        };
        data.areas.map((area) => {
          // Close of Modal showDeploy
          if (area.id_area == selectedArea.id_area && area.flg_battle) {
            setShowDeploy(false);
            setShowResults(true);
          }
          // Close of Modal showArea
          conquestInfos.conquests.map((conquest) => {
            if (conquest.nft_identifier == selectedItem.item_identifier) {
              if (area.id_area == conquest.id_area && area.flg_battle) {
                handleCloseArea();
              }
            }
          });

          if (area.id_faction != 0) {
            colorArea.colors.color[area.id_area] = area.faction.color;
            colorArea.colors.colorHover[area.id_area] = area.faction.colorHover;
          } else {
            colorArea.colors.color[area.id_area] = colorNeutre;
            colorArea.colors.colorHover[area.id_area] = colorHoverNeutre;
          }
        });
        setAreasMap(AreasFonctions.getAreasMap(colorArea));
      }
    });
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const fetchInventoryData = () => {
    InventoriesDataService.findAllByUser(userInfos.user.id_user).then(
      ({ data }) => {
        if (data.status == 'success') {
          setInventoryInfos({
            inventory: data.inventory,
            status: data.status
          });

          let prevIdItem = 0;
          let itemCount = 0;
          let dataInventory = inventoryEmpty;
          const inventory: InventoryInfos = {
            inventory: []
          };
          data.inventory.map((item) => {
            if (item.id_item != prevIdItem) {
              if (prevIdItem != 0 && itemCount > 0) {
                inventory.inventory.push(dataInventory);
              }

              prevIdItem = item.id_item;
              itemCount = 0;

              dataInventory = {
                id_item: item.id_item,
                img: item.item.item_img,
                itemInfos: item.item,
                itemsAvailableCount: 0,
                itemsAvailable: []
              };
            }
            if (!item.flg_used && item.item.item_type == 2) {
              itemCount++;
              dataInventory.itemsAvailableCount++;
              dataInventory.itemsAvailable.push({
                identifier: item.identifier
              });
            }
          });
          if (prevIdItem != 0 && itemCount > 0) {
            inventory.inventory.push(dataInventory);
          }
          setInventoryGrpInfos(inventory);
        }
      }
    );
  };

  const fetchFactionInventoryData = () => {
    FactionsInventoriesDataService.findAllByFaction(
      userInfos.user.id_faction
    ).then(({ data }) => {
      if (data.status == 'success') {
        setFactionInventoryInfos({
          inventory: data.inventory,
          status: data.status
        });

        let inventory_item_type = 0;
        if (showPower) inventory_item_type = 2;
        if (showBonus) inventory_item_type = 4;

        let prevIdItem = 0;
        let itemCount = 0;
        let dataInventory = factionInventoryEmpty;
        const inventory: FactionInventoryInfos = {
          factionInventory: []
        };
        data.inventory.map((item) => {
          if (item.id_item != prevIdItem) {
            if (prevIdItem != 0 && itemCount > 0) {
              inventory.factionInventory.push(dataInventory);
            }

            prevIdItem = item.id_item;
            itemCount = 0;

            dataInventory = {
              id_item: item.id_item,
              img: item.item.item_img,
              itemInfos: item.item,
              itemsAvailableCount: 0,
              itemsAvailable: []
            };
          }
          if (!item.flg_used && item.item.item_type == inventory_item_type) {
            itemCount++;
            dataInventory.itemsAvailableCount++;
            dataInventory.itemsAvailable.push({
              identifier: item.identifier
            });
          }
        });
        if (prevIdItem != 0 && itemCount > 0) {
          inventory.factionInventory.push(dataInventory);
        }
        setFactionInventoryGrpInfos(inventory);
      }
    });
  };

  const fetchConquestLogsData = () => {
    ConquestLogsDataService.findAllByConquest(currentConquest).then(
      ({ data }) => {
        if (data.status == 'success') {
          let prevArea = 0;
          let imgFaction = '';
          let dataConquest = conquestEmpty;
          const logs: ConquestsLogsInfos = {
            conquests_logs: []
          };
          data.conquests_logs.map((logsInfos) => {
            if (logsInfos.id_area != prevArea) {
              if (prevArea != 0) {
                logs.conquests_logs.push(dataConquest);
              }

              prevArea = logsInfos.id_area;

              dataConquest = {
                id_area: logsInfos.id_area,
                name_area: logsInfos.area.name,
                id_faction: 0,
                name_faction: 'Bandits',
                color: '#95A5A6',
                colorHover: '#BFC9CA',
                infos: [],
                showResult: false
              };
            }
            if (logsInfos.id_faction_winner == logsInfos.id_faction) {
              dataConquest.id_faction = logsInfos.id_faction;
              dataConquest.name_faction = logsInfos.faction.name;
              dataConquest.color = logsInfos.faction.color;
              dataConquest.colorHover = logsInfos.faction.colorHover;
            }
            switch (logsInfos.id_faction) {
              case 1:
                imgFaction = angelic;
                break;
              case 2:
                imgFaction = night;
                break;
              case 3:
                imgFaction = meiyo;
                break;
            }
            dataConquest.infos.push({
              id_faction: logsInfos.id_faction,
              name: logsInfos.faction.name,
              img: imgFaction,
              bonus_value: logsInfos.bonus_value,
              malus_value: logsInfos.malus_value,
              tot_power: logsInfos.tot_power
            });
          });
          if (dataConquest.id_area != 0) {
            logs.conquests_logs.push(dataConquest);
          }
          setConquestLogsInfos(logs);
        }
      }
    );
  };

  const handleShowResult = (id_area: any) => {
    const logs: ConquestsLogsInfos = {
      conquests_logs: conquestLogsInfos.conquests_logs
    };
    logs.conquests_logs.map((infos) => {
      if (infos.id_area == id_area) {
        if (infos.showResult == true) {
          infos.showResult = false;
        } else {
          infos.showResult = true;
        }
      }
    });
    setConquestLogsInfos(logs);
  };

  const fetchConquestData = () => {
    const params: any = {};
    params['id_conquest'] = currentConquest;
    params['id_user'] = userInfos.user.id_user;

    ConquestDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setConquestInfos({
          conquests: data.conquests,
          status: data.status
        });
      }
    });
  };

  /*
  const fetchConquestFactionData = () => {
    const params: any = {};
    params['id_conquest'] = currentConquest;
    params['id_faction'] = userInfos.user.id_faction;
  
    ConquestDataService.findAllByFaction(params).then(({ data }) => {
      if (data.status == 'success') {
        setConquestFactionInfos({
          conquests: data.conquests,
          status: data.status
        });
  
        let bonus = false;
        data.conquests.map((conquest) => {
          if (
            conquest.nft_type == 2 &&
            conquest.id_faction == userInfos.user.id_faction &&
            conquest.item?.item_type == 4
          ) {
            bonus = true;
          }
        });
        setFactionBonus(bonus);
      }
    });
  };
  */

  const fetchConquestFactionData = () => {
    ConquestDataService.findAllByConquest(currentConquest).then(({ data }) => {
      if (data.status == 'success') {
        setConquestFactionInfos({
          conquests: data.conquests,
          status: data.status
        });

        let bonus = false;
        data.conquests.map((conquest) => {
          if (
            conquest.nft_type == 2 &&
            conquest.id_faction == userInfos.user.id_faction &&
            conquest.item?.item_type == 4
          ) {
            bonus = true;
          }
        });
        setFactionBonus(bonus);
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchDimensions = () => {
    if (mapContainerSize.current) {
      setMapDimensions({
        width: mapContainerSize.current.offsetWidth,
        height: mapContainerSize.current.offsetHeight,
        style: {
          '--mapWidth': mapContainerSize.current.offsetWidth + 'px',
          '--mapHeight': mapContainerSize.current.offsetHeight + 'px',
          '--mapRatioWidth': 0,
          '--mapRatioHeight': 0
        } as React.CSSProperties
      });
    }
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setPhaseInfos({
          nextPhase: data.setting.nextPhase
        });
      }
    });

    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const fetchBattleData = () => {
    // Fetch of the current phase in the parameters
    BattleDataService.findNextBattleByConquest(currentConquest).then(
      ({ data }) => {
        if (data.status == 'success') {
          setBattleInfos({
            battle: data.battle,
            status: data.status
          });
        } else {
          setBattleInfos({
            battle: battleEmpty,
            status: data.status
          });
        }
      }
    );
  };

  const deployNFT = (id_area: any) => {
    if (showPlayer) {
      if (massiveItems) deployMassItem(id_area);
      else deployItem(id_area);
    } else {
      if (massiveItems) deployFactionMassItem(id_area);
      else deployFactionItem(id_area);
    }
  };

  const deployItem = (id_area: any) => {
    /*
    let flg_update = false;
    conquestInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == selectedItem.item_identifier) {
        flg_update = true;
      }
    });
    if (flg_update) {
      conquestInfos.conquests.map((conquest) => {
        if (conquest.nft_identifier == selectedItem.item_identifier) {
          const dataConquest: any = {
            id_area: id_area
          };
  
          ConquestDataService.update(dataConquest, conquest.id).then(() => {
            fetchConquestData();
          });
        }
      });
    } else {
      const dataConquest: IConquestData = {
        id_conquest: currentConquest,
        id_user: userInfos.user.id_user,
        id_faction: userInfos.user.id_faction,
        id_area: id_area,
        nft_type: 2,
        id_nft: 0,
        id_item: selectedItem.id_item,
        nft_identifier: selectedItem.item_identifier
      };
  
      const dataItem: any = {
        flg_used: true
      };
  
      ConquestDataService.create(dataConquest).then(({ data }) => {
        if (data.status == 'success') {
          InventoriesDataService.update(
            dataItem,
            selectedItem.item_identifier
          ).then(() => {
            fetchInventoryData();
            fetchConquestData();
          });
        }
      });
    }
    */

    const dataConquest: any = {
      id_area: id_area
    };

    ConquestDataService.update(dataConquest, selectedItem.idConquest).then(
      () => {
        fetchConquestData();
        fetchConquestFactionData();
      }
    );

    handleCloseArea();
  };

  const deployMassItem = async (id_area: any) => {
    let nbDeployed = 0;

    for (let i = 0; i < inventoryInfos.inventory.length; i++) {
      if (nbDeployed < itemQty) {
        const item = inventoryInfos.inventory[i];
        if (!item.flg_used && item.id_item == selectedItem.id_item) {
          const dataConquest: IConquestData = {
            id_conquest: currentConquest,
            id_user: userInfos.user.id_user,
            id_faction: userInfos.user.id_faction,
            id_area: id_area,
            nft_type: 2,
            id_nft: 0,
            id_item: item.item.id_item,
            nft_identifier: item.identifier
          };

          const dataItem: any = {
            flg_used: true
          };

          await ConquestDataService.create(dataConquest).then(
            async ({ data }) => {
              if (data.status == 'success') {
                await InventoriesDataService.update(dataItem, item.identifier);
              }
            }
          );

          nbDeployed++;
        }
      } else {
        i = inventoryInfos.inventory.length;
      }
    }

    fetchInventoryData();
    fetchConquestData();
    fetchConquestFactionData();
    handleCloseArea();
  };

  const deployFactionItem = (id_area: any) => {
    /*
    let flg_update = false;
    conquestFactionInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == selectedItem.item_identifier) {
        flg_update = true;
      }
    });
    if (flg_update) {
      conquestFactionInfos.conquests.map((conquest) => {
        if (conquest.nft_identifier == selectedItem.item_identifier) {
          const dataConquest: any = {
            id_area: id_area
          };
  
          ConquestDataService.update(dataConquest, conquest.id).then(() => {
            fetchConquestFactionData();
          });
        }
      });
    } else {
      const dataConquest: IConquestData = {
        id_conquest: currentConquest,
        id_user: userInfos.user.id_user,
        id_faction: userInfos.user.id_faction,
        id_area: id_area,
        nft_type: 2,
        id_nft: 0,
        id_item: selectedItem.id_item,
        nft_identifier: selectedItem.item_identifier
      };
  
      const dataItem: any = {
        flg_used: true
      };
  
      ConquestDataService.create(dataConquest).then(({ data }) => {
        if (data.status == 'success') {
          FactionsInventoriesDataService.update(
            dataItem,
            selectedItem.item_identifier
          ).then(() => {
            fetchFactionInventoryData();
            fetchConquestFactionData();
          });
        }
      });
    }
    */

    const dataConquest: any = {
      id_area: id_area
    };

    ConquestDataService.update(dataConquest, selectedItem.idConquest).then(
      () => {
        fetchConquestFactionData();
      }
    );

    handleCloseArea();
  };

  const deployFactionMassItem = async (id_area: any) => {
    let nbDeployed = 0;

    for (let i = 0; i < factionInventoryInfos.inventory.length; i++) {
      if (nbDeployed < itemQty) {
        const item = factionInventoryInfos.inventory[i];
        if (!item.flg_used && item.id_item == selectedItem.id_item) {
          const dataConquest: IConquestData = {
            id_conquest: currentConquest,
            id_user: userInfos.user.id_user,
            id_faction: userInfos.user.id_faction,
            id_area: id_area,
            nft_type: 2,
            id_nft: 0,
            id_item: item.item.id_item,
            nft_identifier: item.identifier
          };

          const dataItem: any = {
            flg_used: true
          };

          await ConquestDataService.create(dataConquest).then(
            async ({ data }) => {
              if (data.status == 'success') {
                await FactionsInventoriesDataService.update(
                  dataItem,
                  item.identifier
                );
              }
            }
          );

          nbDeployed++;
        }
      } else {
        i = factionInventoryInfos.inventory.length;
      }
    }

    fetchFactionInventoryData();
    fetchConquestFactionData();
    handleCloseArea();
  };

  const selectNFT = (id_item: number, identifier: string) => {
    if (showPlayer) {
      selectItem(id_item, identifier);
    } else {
      selectFactionItem(id_item, identifier);
    }
  };

  const selectItem = (id_item: number, identifier: string) => {
    /*
    let flg_update = false;
    conquestInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == identifier) {
        flg_update = true;
      }
    });
    if (flg_update) {
      conquestInfos.conquests.map((conquest) => {
        if (conquest.nft_identifier == identifier) {
          const dataConquest: any = {
            id_area: selectedArea.id_area
          };
  
          ConquestDataService.update(dataConquest, conquest.id).then(() => {
            fetchConquestData();
          });
        }
      });
    } else {
      inventoryInfos.inventory.map((item) => {
        if (item.identifier == identifier) {
          const dataConquest: IConquestData = {
            id_conquest: currentConquest,
            id_user: userInfos.user.id_user,
            id_faction: userInfos.user.id_faction,
            id_area: selectedArea.id_area,
            nft_type: 2,
            id_nft: 0,
            id_item: item.item.id_item,
            nft_identifier: item.identifier
          };
  
          const dataItem: any = {
            flg_used: true
          };
  
          ConquestDataService.create(dataConquest).then(({ data }) => {
            if (data.status == 'success') {
              InventoriesDataService.update(dataItem, item.identifier).then(
                () => {
                  fetchInventoryData();
                  fetchConquestData();
                }
              );
            }
          });
        }
      });
    }
    */

    const dataConquest: IConquestData = {
      id_conquest: currentConquest,
      id_user: userInfos.user.id_user,
      id_faction: userInfos.user.id_faction,
      id_area: selectedArea.id_area,
      nft_type: 2,
      id_nft: 0,
      id_item: id_item,
      nft_identifier: identifier
    };

    const dataItem: any = {
      flg_used: true
    };

    ConquestDataService.create(dataConquest).then(({ data }) => {
      if (data.status == 'success') {
        InventoriesDataService.update(dataItem, identifier).then(() => {
          fetchInventoryData();
        });
      }
    });
  };

  const selectFactionItem = (id_item: number, identifier: string) => {
    /*
    let flg_update = false;
    conquestFactionInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == identifier) {
        flg_update = true;
      }
    });
    if (flg_update) {
      conquestFactionInfos.conquests.map((conquest) => {
        if (conquest.nft_identifier == identifier) {
          const dataConquest: any = {
            id_area: selectedArea.id_area
          };
  
          ConquestDataService.update(dataConquest, conquest.id).then(() => {
            fetchConquestFactionData();
          });
        }
      });
    } else {
      factionInventoryInfos.inventory.map((item) => {
        if (item.identifier == identifier) {
          const dataConquest: IConquestData = {
            id_conquest: currentConquest,
            id_user: userInfos.user.id_user,
            id_faction: userInfos.user.id_faction,
            id_area: selectedArea.id_area,
            nft_type: 2,
            id_nft: 0,
            id_item: item.item.id_item,
            nft_identifier: item.identifier
          };
  
          const dataItem: any = {
            flg_used: true
          };
  
          ConquestDataService.create(dataConquest).then(({ data }) => {
            if (data.status == 'success') {
              FactionsInventoriesDataService.update(
                dataItem,
                item.identifier
              ).then(() => {
                fetchFactionInventoryData();
                fetchConquestFactionData();
              });
            }
          });
        }
      });
    }
    */

    const dataConquest: IConquestData = {
      id_conquest: currentConquest,
      id_user: userInfos.user.id_user,
      id_faction: userInfos.user.id_faction,
      id_area: selectedArea.id_area,
      nft_type: 2,
      id_nft: 0,
      id_item: id_item,
      nft_identifier: identifier
    };

    const dataItem: any = {
      flg_used: true
    };

    ConquestDataService.create(dataConquest).then(({ data }) => {
      if (data.status == 'success') {
        FactionsInventoriesDataService.update(dataItem, identifier).then(() => {
          fetchFactionInventoryData();
        });
      }
    });
  };

  const unselectNFT = (idConquest: any, nft_identifier: string) => {
    if (showPlayer) {
      unselectItem(idConquest, nft_identifier);
    } else {
      unselectFactionItem(idConquest, nft_identifier);
    }
  };

  const unselectItem = (idConquest: any, nft_identifier: string) => {
    /*
    conquestInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == nft_identifier) {
        ConquestDataService.delete(conquest.id).then(({ data }) => {
          if (data.status == 'success') {
            const dataItem: any = {
              flg_used: false
            };
  
            InventoriesDataService.update(dataItem, nft_identifier).then(() => {
              fetchInventoryData();
              fetchConquestData();
            });
          }
        });
      }
    });
    */

    ConquestDataService.delete(idConquest).then(({ data }) => {
      if (data.status == 'success') {
        const dataItem: any = {
          flg_used: false
        };

        InventoriesDataService.update(dataItem, nft_identifier).then(() => {
          fetchInventoryData();
          fetchConquestData();
          fetchConquestFactionData();
        });
      }
    });
  };

  const unselectFactionItem = (idConquest: any, nft_identifier: string) => {
    /*
    conquestFactionInfos.conquests.map((conquest) => {
      if (conquest.nft_identifier == nft_identifier) {
        ConquestDataService.delete(conquest.id).then(({ data }) => {
          if (data.status == 'success') {
            const dataItem: any = {
              flg_used: false
            };
  
            FactionsInventoriesDataService.update(
              dataItem,
              nft_identifier
            ).then(() => {
              fetchFactionInventoryData();
              fetchConquestFactionData();
            });
          }
        });
      }
    });
    */

    ConquestDataService.delete(idConquest).then(({ data }) => {
      if (data.status == 'success') {
        const dataItem: any = {
          flg_used: false
        };

        FactionsInventoriesDataService.update(dataItem, nft_identifier).then(
          () => {
            fetchFactionInventoryData();
            fetchConquestFactionData();
          }
        );
      }
    });
  };

  const handleRefreshPhase = () => {
    window.location.reload();
  };

  const handleRefreshBattle = () => {
    fetchBattleData();
    fetchAreasData();
    fetchConquestLogsData();
  };

  const fetchBonusesInfos = () => {
    let merchant = false;
    const merchantIdAreas = new Array(0);
    const merchantAreas = new Array(0);
    let merchantBonusValue = 0;

    let emperorVisit = false;
    const emperorVisitIdAreas = new Array(0);
    const emperorVisitAreas = new Array(0);
    let emperorVisitBonusValue = 0;

    gameBonusesInfos.bonuses.map((bonus) => {
      let areaId = 0;
      let areaName = '';
      AreasInfos.areas.map((area) => {
        if (area.id_area == bonus.id_area) {
          areaId = area.id_area;
          areaName = area.name;
        }
      });

      switch (bonus.id_item) {
        case 5:
          merchant = true;
          merchantIdAreas.push(areaId);
          merchantAreas.push(areaName);
          merchantBonusValue = bonus.item.bonus_value;
          break;
        case 10:
          emperorVisit = true;
          emperorVisitIdAreas.push(areaId);
          emperorVisitAreas.push(areaName);
          emperorVisitBonusValue = bonus.item.bonus_value;
          break;
        default:
          break;
      }
    });

    if (merchant) {
      setMerchantBonus({
        merchant: merchant,
        merchantIdAreas: merchantIdAreas,
        merchantAreas: merchantAreas,
        merchantBonusValue: merchantBonusValue
      });
    }

    if (emperorVisit) {
      setEmperorVisitBonusBonus({
        emperorVisit: emperorVisit,
        emperorVisitIdAreas: emperorVisitIdAreas,
        emperorVisitAreas: emperorVisitAreas,
        emperorVisitBonusValue: emperorVisitBonusValue
      });
    }

    if (merchant || emperorVisit) {
      setGameBonus(true);
    }
  };

  React.useEffect(fetchSettingData, []);

  const ro = new ResizeObserver((entries: any) => {
    for (const entry of entries) {
      switch (entry.target.className) {
        case 'mapField':
          const mapWidth = entry.contentRect.width;
          const mapHeight = entry.contentRect.width * 0.75;
          const mapRatioWidth = mapWidth / mapMaxWidth;
          const mapRatioHeight = mapHeight / mapMaxHeight;

          setMapDimensions({
            width: mapWidth,
            height: mapHeight,
            style: {
              '--mapWidth': mapWidth + 'px',
              '--mapHeight': mapHeight + 'px',
              '--mapRatioWidth': mapRatioWidth,
              '--mapRatioHeight': mapRatioHeight
            } as React.CSSProperties
          });
          break;
      }
    }
  });

  React.useEffect(() => {
    if (mapFieldSelector) {
      ro.observe(mapFieldSelector);
    }
  }, [document.onresize]);

  React.useEffect(() => {
    if (currentConquest != 0) {
      if (mapFieldSelector) {
        ro.observe(mapFieldSelector);
      }

      fetchAreasData();
      //fetchDimensions();
      fetchFactionsData();
      fetchBattleData();
      fetchConquestLogsData();
      fetchItemsData();
      fetchInventoryData();
      fetchConquestFactionData();
    }
  }, [currentConquest]);
  React.useEffect(() => {
    if (AreasInfos.status == 'success') {
      fetchBonusesInfos();
    }
  }, [AreasInfos]);
  React.useEffect(() => {
    if (
      AreasInfos.status == 'success' &&
      factionsInfos.status == 'success' &&
      conquestFactionInfos.status == 'success'
    ) {
      handleFilterArea(filteredArea.id_area);
    }
  }, [AreasInfos, factionsInfos, conquestFactionInfos]);
  React.useEffect(() => {
    if (userLeader) {
      fetchFactionInventoryData();
    }
  }, [showPower, showBonus]);

  const { user } = userInfos;
  const { factions } = factionsInfos;
  const { inventory } = inventoryGrpInfos;
  const { factionInventory } = factionInventoryGrpInfos;
  const { areas } = AreasInfos;
  const { battle } = battleInfos;
  const { conquests } = conquestInfos;
  const { conquests_logs } = conquestLogsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestContainer'>
                <div className='infosConquestBox'>
                  <div className='conquestTitleBox'>
                    <div className='conquestTitle'>Conquest</div>
                  </div>
                  <div className='conquestNumber'>{currentConquest}</div>
                  <div className='phaseTitle'>Battle Phase</div>
                </div>
                <div className='infosTimerContainer'>
                  <div className='infosTimerBox'>
                    <MyTimerPhase
                      expiryTimestamp={new Date(phaseInfos.nextPhase)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {battle.id_area != 0 ? (
            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerLeft'>
                <div className='stripBoxLeft'>
                  <div className='stripBoxLeftRank'>
                    {areas.map((area, i) => {
                      let area_name;
                      if (area.id_area == battle.id_area) {
                        area_name = area.name;

                        return (
                          <div key={i} className='stripInfos'>
                            <div className='stripInfosIn'>
                              <div className='stripInfosText'>{area_name}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className='stripBoxLeftTimerBox'>
                    <div className='stripBoxLeftTimer'>
                      <div className='infosTimerText'>Next Battle</div>
                      <div className='infosTimerTimer'>
                        <MyTimerBattle
                          expiryTimestamp={new Date(battle.battleAt)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/*
          {battle.id_area != 0 && (
            <div className='boxContainer topContainer'>
              <div className='card-body text-center p-2'>
                {areas.map((area, i) => {
                  let area_name;
                  if (area.id_area == battle.id_area) {
                    area_name = area.name;

                    return (
                      <div key={i} className='textImportant'>
                        Pay attention, check your equipment and be ready !
                        <br />
                        The next battle will take place on the&nbsp;
                        <span className='textImportantRed'>{area_name}</span>
                        &nbsp;in
                        <div className='margin10 textTimerRed'>
                          <MyTimerBattle
                            expiryTimestamp={new Date(battle.battleAt)}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
          */}

          {/*
          <div className='battleInfosContainer'>
            <div className='battleInfosContainerSubBefore'></div>
            <div className='battleInfosContainerSub'>
              <div className='card-body p-2'>
                {areas.map((area, i) => {
                  let area_name;
                  if (area.id_area == battle.id_area) {
                    area_name = area.name;

                    return (
                      <div key={i} className='textImportant'>
                        Pay attention, check your equipment and be ready !
                        <br />
                        The next battle will take place on the&nbsp;
                        <span className='textImportantRed'>{area_name}</span>
                        &nbsp;in
                        <div className='margin10 textTimerRed'>
                          <MyTimerBattle
                            expiryTimestamp={new Date(battle.battleAt)}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className='battleInfosContainerSubAfter'></div>
          </div>
          */}

          {/*
          {gameBonus ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Game Bonuses</div>
                </div>
                {merchantBonus.merchant && (
                  <div className='text-center textImportant'>
                    Merchants have appeared in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[0]}
                    </span>
                    &nbsp;and in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[1]}
                    </span>
                    &nbsp;!
                    <br />
                    <span className='textImportantRed'>
                      +{merchantBonus.merchantBonusValue}% JCORP
                    </span>
                    &nbsp;rewards for your entire faction for each area with
                    merchants you conquer
                  </div>
                )}
                {emperorVisitBonus.emperorVisit && (
                  <div className='text-center textImportant margin10'>
                    The Emperor visits the&nbsp;
                    <span className='textImportantRed'>
                      {emperorVisitBonus.emperorVisitAreas[0]}
                    </span>
                    , Leaderboard points won are doubled !
                  </div>
                )}
              </div>
            </div>
          ) : null}
          */}

          {factionBonus ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Faction Bonuses</div>
                </div>
                <div className='text-center'>
                  {conquestFactionInfos.conquests.map((conquest, i) => {
                    if (
                      conquest.nft_type == 2 &&
                      conquest.id_faction == user.id_faction &&
                      conquest.item?.item_type == 4
                    ) {
                      let bonusLabel = '';
                      let bonusType = '';
                      let bonusValue = '';
                      let iconName = faArrowCircleRight;
                      let colorName = '';

                      const itemName = conquest.item.item_name;
                      if (conquest.item.bonus_value > 0) {
                        bonusLabel = 'Faction Power';
                        bonusType = 'increased';
                        bonusValue = '+' + conquest.item.bonus_value + '%';
                        colorName = 'bonusUp';
                        iconName = faArrowCircleUp;
                      } else {
                        bonusLabel = 'Enemy Power';
                        bonusType = 'decreased';
                        bonusValue = conquest.item.bonus_value + '%';
                        colorName = 'bonusDown';
                        iconName = faArrowCircleDown;
                      }

                      return (
                        <div key={i} className='textImportant'>
                          <span className='textImportantRed'>{itemName}</span>
                          &nbsp;deployed in the&nbsp;
                          <span className='textImportantRed'>
                            {conquest.area?.name}
                          </span>
                          &nbsp;! The {bonusLabel} in this area will be&nbsp;
                          {bonusType} by&nbsp;
                          <span className={colorName}>
                            <FontAwesomeIcon
                              icon={iconName}
                              className='iconBonusSize'
                            />
                            &nbsp;{bonusValue}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <div className='boxContainer topContainer'>
            {/*<div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>The City of Reiwa</div>
              </div>
              <div className='factionsContainer'>
                {factions.map((faction, i) => {
                  const color = hexToRgb(faction.color);
                  const factionColor =
                    color?.red + ',' + color?.green + ',' + color?.blue;
                  const divStyle = {
                    '--factionColor': factionColor
                  } as React.CSSProperties;

                  return (
                    <div className='factionsInfos' style={divStyle} key={i}>
                      {faction.name}
                    </div>
                  );
                })}
              </div>
              </div>*/}
            <div
              className='mapField'
              ref={mapContainerSize}
              style={mapDimensions.style}
            >
              <ImageMapper
                src={reiwa}
                map={AREAS_MAP}
                width={mapDimensions.width}
                imgWidth={2048}
                onClick={(area) => handleShowDeploy(area.id)}
              />
              <div className='mapInfosContainerLeft'>
                <div
                  className='mapBoxIconContainer'
                  onClick={handleShowSettings}
                >
                  <div className='mapBoxIcon'>
                    <FontAwesomeIcon icon={faCog} className='mapBoxIconFa' />
                  </div>
                </div>
              </div>
              <div className='mapInfosContainerRight'>
                <div
                  className='mapBoxIconContainer'
                  onClick={handleShowMapBonus}
                >
                  <img src={`${infoIcon}`} className='mapBoxIcon' />
                </div>
                {/*<div
                  className='mapBoxIconContainer'
                  onClick={() => navigate(routeNames.marketplace)}
                >
                  <img src={`${marketplace}`} className='mapBoxIcon' />
                </div>*/}
              </div>
              <div className='factionsContainer1'>
                {factions.map((faction, i) => {
                  const color = hexToRgb(faction.color);
                  const factionColor =
                    color?.red + ',' + color?.green + ',' + color?.blue;
                  const divStyle = {
                    '--factionColor': factionColor
                  } as React.CSSProperties;

                  return (
                    <div className='factionsInfos1' style={divStyle} key={i}>
                      {faction.name}
                    </div>
                  );
                })}
              </div>
              {merchantBonus.merchant ? (
                <React.Fragment>
                  {areas.map((area, i) => {
                    let isBonusMerchant = false;
                    let bonusClassName = '';

                    for (
                      let j = 0;
                      j < merchantBonus.merchantIdAreas.length;
                      j++
                    ) {
                      const bonusIdArea = merchantBonus.merchantIdAreas[j];
                      if (bonusIdArea == area.id_area) {
                        isBonusMerchant = true;
                        const name = area.name.split(' ');
                        bonusClassName = 'map' + name[0];
                      }
                    }

                    if (isBonusMerchant) {
                      return (
                        <div
                          key={i}
                          className={bonusClassName}
                          onClick={() => handleShowDeploy(area.id_area)}
                        >
                          <img
                            src={`${merchantIcon}`}
                            className='mapInfosIcon'
                          />
                        </div>
                      );
                    }
                  })}
                </React.Fragment>
              ) : null}
              {emperorVisitBonus.emperorVisit ? (
                <React.Fragment>
                  {areas.map((area, i) => {
                    let isBonusEmperor = false;
                    let bonusClassName = '';

                    for (
                      let j = 0;
                      j < emperorVisitBonus.emperorVisitIdAreas.length;
                      j++
                    ) {
                      const bonusIdArea =
                        emperorVisitBonus.emperorVisitIdAreas[j];
                      if (bonusIdArea == area.id_area) {
                        isBonusEmperor = true;
                        const name = area.name.split(' ');
                        bonusClassName = 'emp' + name[0];
                      }
                    }

                    if (isBonusEmperor) {
                      return (
                        <div
                          key={i}
                          className={bonusClassName}
                          onClick={() => handleShowDeploy(area.id_area)}
                        >
                          <img
                            src={`${emperorIcon}`}
                            className='empInfosIcon'
                          />
                        </div>
                      );
                    }
                  })}
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Results</div>
              </div>
              <div className='table-responsive text-center'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0 iconCol'></th>
                      <th className='border-0'>Area</th>
                      <th className='border-0'>Faction</th>
                    </tr>
                  </thead>
                  {conquests_logs.length == 0 ? (
                    <tbody>
                      <tr className='rowTableContent textResult'>
                        <td colSpan={3}>
                          <span>No battles were fought</span>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {conquests_logs.map((logs, i) => {
                        return (
                          <React.Fragment key={i}>
                            <tr
                              className='rowTableResult textResult'
                              onClick={() => handleShowResult(logs.id_area)}
                            >
                              <td className='iconCol'>
                                {logs.showResult == true ? (
                                  <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronUp} />
                                )}
                              </td>
                              <td>
                                <span>{logs.name_area}</span>
                              </td>
                              <td>
                                <span>{logs.name_faction}</span>
                              </td>
                            </tr>
                            {logs.showResult == true && (
                              <tr className='rowTableContent textResult'>
                                <td colSpan={3}>
                                  <div className='card-body p-2'>
                                    {logs.id_faction == 0 ? (
                                      <p className='textImportant'>
                                        The {logs.name_faction} have conquered
                                        the {logs.name_area} !
                                      </p>
                                    ) : (
                                      <p className='textImportant'>
                                        The {logs.name_faction} faction has
                                        conquered the {logs.name_area} !
                                      </p>
                                    )}
                                    <div className='standardBlock'>
                                      {logs.infos.map((info, j) => {
                                        let divStyle = {
                                          '--rarityColor': '#000'
                                        } as React.CSSProperties;

                                        factions.map((faction) => {
                                          if (
                                            faction.id_faction ==
                                            info.id_faction
                                          ) {
                                            divStyle = {
                                              '--rarityColor': faction.color
                                            } as React.CSSProperties;
                                          }
                                        });

                                        const tot_power =
                                          info.tot_power.toFixed(2);

                                        const tot_bonus =
                                          info.bonus_value + info.malus_value;

                                        let tot_bonusLabel = tot_bonus + '%';

                                        let iconName;
                                        let colorName;

                                        if (tot_bonus == 0) {
                                          colorName = 'noBonus';
                                          iconName = faArrowCircleRight;
                                        } else {
                                          if (tot_bonus > 0) {
                                            colorName = 'bonusUp';
                                            tot_bonusLabel =
                                              '+' + tot_bonus + '%';
                                            iconName = faArrowCircleUp;
                                          } else {
                                            colorName = 'bonusDown';
                                            iconName = faArrowCircleDown;
                                          }
                                        }

                                        const nameT = info.name.split(' ');

                                        let faction_name_1;
                                        let faction_name_2;
                                        switch (nameT.length) {
                                          case 1:
                                            faction_name_1 = nameT[0];
                                            break;
                                          default:
                                            faction_name_1 = nameT[0];
                                            faction_name_2 = nameT[1];
                                            break;
                                        }

                                        return (
                                          <div
                                            key={j}
                                            style={divStyle}
                                            className='cardItemBlock'
                                          >
                                            <div className='cardItemContainer'>
                                              <div className='cardItemBox'>
                                                <div className='cardItemBoxIn'>
                                                  <div className='cardItemInfos'>
                                                    <div className='cardItemHierarchy'>
                                                      <span
                                                        className={colorName}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={iconName}
                                                          className='iconToken'
                                                        />
                                                        &nbsp;
                                                        <span className='bonusLabel'>
                                                          {tot_bonusLabel}
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div className='cardItemPower'>
                                                      Power : {tot_power}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='cardItemPictureBox'>
                                                <img
                                                  src={`${info.img}`}
                                                  className='cardItemPicture'
                                                />
                                              </div>
                                              <div className='cardItemInfosBis'>
                                                {!faction_name_2 ? (
                                                  <div className='cardItemName'>
                                                    {faction_name_1}
                                                  </div>
                                                ) : (
                                                  <React.Fragment>
                                                    <div className='cardItemNumber'>
                                                      {faction_name_1}
                                                    </div>
                                                    <div className='cardItemName'>
                                                      {faction_name_2}
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>

          {userLeader && (
            <div className='boxContainer topContainer'>
              <div className='card-body text-center p-2'>
                {showPlayer ? (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowPlayer}
                    >
                      Player Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowFaction}
                    >
                      Faction Mode
                    </ReactButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowPlayer}
                    >
                      Player Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowFaction}
                    >
                      Faction Mode
                    </ReactButton>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}

          {showPlayer && inventory.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Inventory</div>
                  </div>
                  <div className='textImportant'>
                    Click on your items to deploy them for the Battle.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  <div className='itemBlock'>
                    {inventory.map((item, i) => {
                      const divStyle = {
                        '--rarityColor': item.itemInfos.item_rarityColor
                      } as React.CSSProperties;

                      let attack = false;
                      let defense = false;
                      switch (item.itemInfos.flg_use) {
                        case 1:
                          attack = true;
                          break;
                        case 2:
                          defense = true;
                          break;
                        default:
                          attack = true;
                          defense = true;
                          break;
                      }

                      const NFTscoreRounded =
                        item.itemInfos.item_power.toFixed(2);

                      const nameT = item.itemInfos.item_name.split(' ');

                      let item_name_1;
                      let item_name_2;
                      switch (nameT.length) {
                        case 1:
                          item_name_1 = nameT[0];
                          break;
                        case 2:
                          item_name_1 = nameT[0];
                          item_name_2 = nameT[1];
                          break;
                        default:
                          item_name_1 = nameT[0] + ' ' + nameT[1];
                          item_name_2 = nameT[2];
                          break;
                      }

                      let available = true;
                      if (item.itemsAvailableCount == 0) {
                        available = false;
                      }

                      if (available) {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      {item.itemInfos.item_rarity}
                                    </div>
                                    <div className='cardItemPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardItemPictureBox'
                                onClick={() => handleShowAreaD(item)}
                              >
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Deploy
                                  </div>
                                </div>
                                <div className='overlayNumber'>
                                  <span className='overlayNumberText'>x</span>
                                  <span className='overlayNumberTextNumber'>
                                    {item.itemsAvailableCount}
                                  </span>
                                </div>
                                <img
                                  src={`${item.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!item_name_2 ? (
                                  <div className='cardItemTitle'>
                                    {item_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {item_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {item_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className='cardItemUsage'>
                                {attack && (
                                  <IconContext.Provider
                                    value={{
                                      className: 'cardUsageAttack'
                                    }}
                                  >
                                    <GiCrossedSwords />
                                  </IconContext.Provider>
                                )}
                                {defense && (
                                  <IconContext.Provider
                                    value={{ className: 'cardUsageDef' }}
                                  >
                                    <GiBorderedShield />
                                  </IconContext.Provider>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showPlayer && inventory.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My Inventory</div>
                </div>
                <div className='text-center'>
                  <div className='iconContainer'>
                    <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                  </div>
                  <p className='textImportant'>
                    Oops... it looks like you don&apos;t have any Item at the
                    moment !
                  </p>
                  <p className='textImportant'>
                    But ! you can find one by going here :
                  </p>
                </div>
                <div className='buttonContainer'>
                  <ReactButton
                    className='btnStandardReverse'
                    onClick={() => navigate(routeNames.market)}
                  >
                    Market
                  </ReactButton>
                </div>
              </div>
            </div>
          ) : null}

          {showFaction && userLeader && factionInventory.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Faction Inventory</div>
                  </div>
                  <div className='text-center'>
                    {showPower ? (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className='card-body p-2'>
                  <div className='textImportant'>
                    Click on your faction items to deploy them for the Battle.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  <div className='itemBlock'>
                    {factionInventory.map((item, i) => {
                      const divStyle = {
                        '--rarityColor': item.itemInfos.item_rarityColor
                      } as React.CSSProperties;

                      let attack = false;
                      let defense = false;
                      switch (item.itemInfos.flg_use) {
                        case 1:
                          attack = true;
                          break;
                        case 2:
                          defense = true;
                          break;
                        default:
                          attack = true;
                          defense = true;
                          break;
                      }

                      const NFTscoreRounded =
                        item.itemInfos.item_power.toFixed(2);

                      let bonusLabel = '';
                      let bonusValue = '';
                      if (item.itemInfos.bonus_value > 0) {
                        bonusLabel = 'Faction Power';
                        bonusValue = '+' + item.itemInfos.bonus_value + '%';
                      } else {
                        bonusLabel = 'Enemy Power';
                        bonusValue = item.itemInfos.bonus_value + '%';
                      }

                      const nameT = item.itemInfos.item_name.split(' ');

                      let item_name_1;
                      let item_name_2;
                      switch (nameT.length) {
                        case 1:
                          item_name_1 = nameT[0];
                          break;
                        case 2:
                          item_name_1 = nameT[0];
                          item_name_2 = nameT[1];
                          break;
                        default:
                          item_name_1 = nameT[0] + ' ' + nameT[1];
                          item_name_2 = nameT[2];
                          break;
                      }

                      let available = true;
                      if (item.itemsAvailableCount == 0) {
                        available = false;
                      }

                      let deployable = true;
                      conquestFactionInfos.conquests.map((conquest) => {
                        if (
                          conquest.nft_type == 2 &&
                          conquest.id_faction == user.id_faction &&
                          conquest.id_item == item.id_item &&
                          conquest.item?.item_type == 4
                        ) {
                          deployable = false;
                        }
                      });

                      if (available) {
                        if (deployable) {
                          return (
                            <div
                              key={i}
                              style={divStyle}
                              className='cardItemBlock'
                            >
                              <div className='cardItemContainer'>
                                <div className='cardItemBox'>
                                  <div className='cardItemBoxIn'>
                                    <div className='cardItemInfos'>
                                      <div className='cardItemHierarchy'>
                                        {item.itemInfos.item_rarity}
                                      </div>
                                      {item.itemInfos.item_type == 2 && (
                                        <div className='cardItemPower'>
                                          Power : {NFTscoreRounded}
                                        </div>
                                      )}
                                      {item.itemInfos.item_type == 4 && (
                                        <div className='cardItemPower'>
                                          {bonusLabel} : {bonusValue}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='cardItemPictureBox'
                                  onClick={() => handleShowAreaD(item)}
                                >
                                  <div className='overlayHoverA'>
                                    <div className='overlayHoverTextA'>
                                      Deploy
                                    </div>
                                  </div>
                                  <div className='overlayNumber'>
                                    <span className='overlayNumberText'>x</span>
                                    <span className='overlayNumberTextNumber'>
                                      {item.itemsAvailableCount}
                                    </span>
                                  </div>
                                  <img
                                    src={`${item.img}`}
                                    className='cardItemPicture'
                                  />
                                </div>
                                <div className='cardItemInfosBis'>
                                  {!item_name_2 ? (
                                    <div className='cardItemTitle'>
                                      {item_name_1}
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <div className='cardItemNumber'>
                                        {item_name_1}
                                      </div>
                                      <div className='cardItemName'>
                                        {item_name_2}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                {item.itemInfos.item_type == 2 && (
                                  <div className='cardItemUsage'>
                                    {attack && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageAttack'
                                        }}
                                      >
                                        <GiCrossedSwords />
                                      </IconContext.Provider>
                                    )}
                                    {defense && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageDef'
                                        }}
                                      >
                                        <GiBorderedShield />
                                      </IconContext.Provider>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={i}
                              style={divStyle}
                              className='cardItemBlock'
                            >
                              <div className='cardItemContainer'>
                                <div className='cardItemBox'>
                                  <div className='cardItemBoxIn'>
                                    <div className='cardItemInfos'>
                                      <div className='cardItemHierarchy'>
                                        {item.itemInfos.item_rarity}
                                      </div>
                                      {item.itemInfos.item_type == 2 && (
                                        <div className='cardItemPower'>
                                          Power : {NFTscoreRounded}
                                        </div>
                                      )}
                                      {item.itemInfos.item_type == 4 && (
                                        <div className='cardItemPower'>
                                          {bonusLabel} : {bonusValue}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='cardItemPictureBox'>
                                  <div className='overlay'>
                                    <div className='overlayText'>Deployed</div>
                                  </div>
                                  <div className='overlayNumber'>
                                    <span className='overlayNumberText'>x</span>
                                    <span className='overlayNumberTextNumber'>
                                      {item.itemsAvailableCount}
                                    </span>
                                  </div>
                                  <img
                                    src={`${item.img}`}
                                    className='cardItemPicture'
                                  />
                                </div>
                                <div className='cardItemInfosBis'>
                                  {!item_name_2 ? (
                                    <div className='cardItemTitle'>
                                      {item_name_1}
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <div className='cardItemNumber'>
                                        {item_name_1}
                                      </div>
                                      <div className='cardItemName'>
                                        {item_name_2}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                {item.itemInfos.item_type == 2 && (
                                  <div className='cardItemUsage'>
                                    {attack && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageAttack'
                                        }}
                                      >
                                        <GiCrossedSwords />
                                      </IconContext.Provider>
                                    )}
                                    {defense && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageDef'
                                        }}
                                      >
                                        <GiBorderedShield />
                                      </IconContext.Provider>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showFaction && userLeader && factionInventory.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My Faction Inventory</div>
                </div>
                <div className='text-center'>
                  {showPower ? (
                    <React.Fragment>
                      <ReactButton
                        className='navStandardSmallReverse'
                        onClick={handleShowPower}
                      >
                        Power
                      </ReactButton>
                      <ReactButton
                        className='navStandardSmall'
                        onClick={handleShowBonus}
                      >
                        Bonus
                      </ReactButton>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ReactButton
                        className='navStandardSmall'
                        onClick={handleShowPower}
                      >
                        Power
                      </ReactButton>
                      <ReactButton
                        className='navStandardSmallReverse'
                        onClick={handleShowBonus}
                      >
                        Bonus
                      </ReactButton>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className='card-body p-2'>
                <div className='text-center'>
                  <div className='iconContainer'>
                    <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                  </div>
                  <p className='textImportant'>
                    Oops... it looks like your faction don&apos;t have any Item
                    at the moment !
                  </p>
                  <p className='textImportant'>
                    But ! you can find one by going here :
                  </p>
                </div>
                <div className='buttonContainer'>
                  <ReactButton
                    className='btnStandardReverse'
                    onClick={() => navigate(routeNames.factionmarket)}
                  >
                    Faction Market
                  </ReactButton>
                </div>
              </div>
            </div>
          ) : null}

          {showPlayer ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Deployment</div>
                  </div>
                  <div className='whiteBox text-center'>
                    {filteredArea.id_area == 0 ? (
                      <ReactButton
                        className='navStandardSmallReverse'
                        onClick={() => handleFilterArea(0)}
                      >
                        All
                      </ReactButton>
                    ) : (
                      <ReactButton
                        className='navStandardSmall'
                        onClick={() => handleFilterArea(0)}
                      >
                        All
                      </ReactButton>
                    )}
                    {areas.map((area, i) => {
                      let visible = false;
                      switch (area.id_area) {
                        case 1:
                        case 2:
                        case 3:
                          visible = false;
                          break;
                        default:
                          visible = true;
                          break;
                      }

                      if (visible) {
                        if (filteredArea.id_area == area.id_area) {
                          return (
                            <ReactButton
                              className='navStandardSmallReverse'
                              key={i}
                              onClick={() => handleFilterArea(area.id_area)}
                            >
                              {area.name}
                            </ReactButton>
                          );
                        } else {
                          return (
                            <ReactButton
                              className='navStandardSmall'
                              key={i}
                              onClick={() => handleFilterArea(area.id_area)}
                            >
                              {area.name}
                            </ReactButton>
                          );
                        }
                      }
                    })}
                  </div>
                  <div className='dataContainer margin10'>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>NFTs deployed</span>
                      <span className='dataAttribut'>
                        {filteredArea.numDeployNFTs}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Items deployed</span>
                      <span className='dataAttribut'>
                        {filteredArea.numDeployItems}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Balancing ratio</span>
                      <span className='dataAttribut'>{filteredArea.ratio}</span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Total power</span>
                      <span className='dataAttribut'>
                        {filteredArea.totalPower}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='boxContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>NFTS</div>
                  </div>
                  <div className='table-responsive text-center'>
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>Name</th>
                          <th className='border-0'>Power</th>
                          <th className='border-0'>Area</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conquests.map((conquest, i) => {
                          let areaName = '';
                          let available = false;

                          // NFTs
                          if (conquest.nft_type == 1) {
                            available = true;

                            if (
                              filteredArea.id_area != 0 &&
                              conquest.id_area != filteredArea.id_area
                            ) {
                              available = false;
                            }
                          }

                          if (available) {
                            count++;

                            areas.map((area) => {
                              if (area.id_area == conquest.id_area) {
                                areaName = area.name;
                              }
                            });
                          }

                          if (available) {
                            return (
                              <tr
                                key={i}
                                className='rowTableContent textResult'
                              >
                                <td>
                                  <span>{conquest.attribut?.nft_name}</span>
                                </td>
                                <td>
                                  <span>
                                    {conquest.attribut?.nft_power.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>{areaName}</span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                        {count == 0 && (
                          <tr className='rowTableContent textResult'>
                            {filteredArea.id_area == 0 ? (
                              <td colSpan={3}>
                                <span>No NFT deployed</span>
                              </td>
                            ) : (
                              <td colSpan={3}>
                                <span>
                                  No NFT deployed to the&nbsp;
                                  {filteredArea.name}
                                </span>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='boxContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Items</div>
                  </div>
                  <div className='table-responsive text-center'>
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>Name</th>
                          <th className='border-0'>Power</th>
                          <th className='border-0'>Area</th>
                          <th className='border-0'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conquests.map((conquest, i) => {
                          let areaName = '';
                          let available = false;
                          let battleDone = false;

                          // Items
                          if (conquest.nft_type == 2) {
                            available = true;

                            if (
                              filteredArea.id_area != 0 &&
                              conquest.id_area != filteredArea.id_area
                            ) {
                              available = false;
                            }

                            const identifier =
                              conquest.nft_identifier.split('-');

                            if (identifier[1].substring(0, 1) == 'f') {
                              available = false;
                            }
                          }

                          if (available) {
                            countItems++;

                            areas.map((area) => {
                              if (area.id_area == conquest.id_area) {
                                areaName = area.name;
                                if (area.flg_battle) {
                                  battleDone = true;
                                }
                              }
                            });
                          }

                          if (available) {
                            return (
                              <tr
                                key={i}
                                className='rowTableContent textResult'
                              >
                                <td>
                                  <span>{conquest.item?.item_name}</span>
                                </td>
                                <td>
                                  <span>
                                    {conquest.item?.item_power.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>{areaName}</span>
                                </td>
                                <td>
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() => handleShowAreaU(conquest)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() =>
                                        unselectNFT(
                                          conquest.id,
                                          conquest.nft_identifier
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        {countItems == 0 && (
                          <tr className='rowTableContent textResult'>
                            {filteredArea.id_area == 0 ? (
                              <td colSpan={4}>
                                <span>No item deployed</span>
                              </td>
                            ) : (
                              <td colSpan={4}>
                                <span>
                                  No item deployed to the&nbsp;
                                  {filteredArea.name}
                                </span>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showFaction && userLeader ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Faction Deployment</div>
                  </div>
                  <div className='whiteBox text-center'>
                    {filteredArea.id_area == 0 ? (
                      <ReactButton
                        className='navStandardSmallReverse'
                        onClick={() => handleFilterArea(0)}
                      >
                        All
                      </ReactButton>
                    ) : (
                      <ReactButton
                        className='navStandardSmall'
                        onClick={() => handleFilterArea(0)}
                      >
                        All
                      </ReactButton>
                    )}
                    {areas.map((area, i) => {
                      let visible = false;
                      switch (area.id_area) {
                        case 1:
                        case 2:
                        case 3:
                          visible = false;
                          break;
                        default:
                          visible = true;
                          break;
                      }

                      if (visible) {
                        if (filteredArea.id_area == area.id_area) {
                          return (
                            <ReactButton
                              className='navStandardSmallReverse'
                              key={i}
                              onClick={() => handleFilterArea(area.id_area)}
                            >
                              {area.name}
                            </ReactButton>
                          );
                        } else {
                          return (
                            <ReactButton
                              className='navStandardSmall'
                              key={i}
                              onClick={() => handleFilterArea(area.id_area)}
                            >
                              {area.name}
                            </ReactButton>
                          );
                        }
                      }
                    })}
                  </div>
                  <div className='dataContainer margin10'>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>NFTs deployed</span>
                      <span className='dataAttribut'>
                        {filteredArea.numDeployNFTsFaction}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Items deployed</span>
                      <span className='dataAttribut'>
                        {filteredArea.numDeployItemsFaction}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Balancing ratio</span>
                      <span className='dataAttribut'>{filteredArea.ratio}</span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Total power</span>
                      <span className='dataAttribut'>
                        {filteredArea.totalPowerFaction}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='boxContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Faction Bonus Items</div>
                  </div>
                  <div className='table-responsive text-center'>
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>User</th>
                          <th className='border-0'>Name</th>
                          <th className='border-0'>Area</th>
                          <th className='border-0'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conquestFactionInfos.conquests.map((conquest, i) => {
                          let areaName = '';
                          let available = false;
                          let battleDone = false;

                          // Bonus Items
                          if (
                            conquest.nft_type == 2 &&
                            conquest.id_faction == user.id_faction &&
                            conquest.item?.item_type == 4
                          ) {
                            available = true;

                            if (
                              filteredArea.id_area != 0 &&
                              conquest.id_area != filteredArea.id_area
                            ) {
                              available = false;
                            }

                            const identifier =
                              conquest.nft_identifier.split('-');

                            if (identifier[1].substring(0, 1) != 'f') {
                              available = false;
                            }
                          }

                          if (available) {
                            countFactionBonusItems++;

                            areas.map((area) => {
                              if (area.id_area == conquest.id_area) {
                                areaName = area.name;
                                if (area.flg_battle) {
                                  battleDone = true;
                                }
                              }
                            });
                          }

                          if (available) {
                            return (
                              <tr
                                key={i}
                                className='rowTableContent textResult'
                              >
                                <td>
                                  <span>{conquest.user?.pseudo}</span>
                                </td>
                                <td>
                                  <span>{conquest.item?.item_name}</span>
                                </td>
                                <td>
                                  <span>{areaName}</span>
                                </td>
                                <td>
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() => handleShowAreaU(conquest)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() =>
                                        unselectNFT(
                                          conquest.id,
                                          conquest.nft_identifier
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        {countFactionBonusItems == 0 && (
                          <tr className='rowTableContent textResult'>
                            {filteredArea.id_area == 0 ? (
                              <td colSpan={4}>
                                <span>No bonus item deployed</span>
                              </td>
                            ) : (
                              <td colSpan={4}>
                                <span>
                                  No bonus item deployed to the&nbsp;
                                  {filteredArea.name}
                                </span>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='boxContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Faction Power Items</div>
                  </div>
                  <div className='table-responsive text-center'>
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>User</th>
                          <th className='border-0'>Name</th>
                          <th className='border-0'>Power</th>
                          <th className='border-0'>Area</th>
                          <th className='border-0'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conquestFactionInfos.conquests.map((conquest, i) => {
                          let areaName = '';
                          let available = false;
                          let battleDone = false;

                          // Power Items
                          if (
                            conquest.nft_type == 2 &&
                            conquest.id_faction == user.id_faction &&
                            conquest.item?.item_type == 2
                          ) {
                            available = true;

                            if (
                              filteredArea.id_area != 0 &&
                              conquest.id_area != filteredArea.id_area
                            ) {
                              available = false;
                            }

                            const identifier =
                              conquest.nft_identifier.split('-');

                            if (identifier[1].substring(0, 1) != 'f') {
                              available = false;
                            }
                          }

                          if (available) {
                            countFactionItems++;

                            areas.map((area) => {
                              if (area.id_area == conquest.id_area) {
                                areaName = area.name;
                                if (area.flg_battle) {
                                  battleDone = true;
                                }
                              }
                            });
                          }

                          if (available) {
                            return (
                              <tr
                                key={i}
                                className='rowTableContent textResult'
                              >
                                <td>
                                  <span>{conquest.user?.pseudo}</span>
                                </td>
                                <td>
                                  <span>{conquest.item?.item_name}</span>
                                </td>
                                <td>
                                  <span>
                                    {conquest.item?.item_power.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>{areaName}</span>
                                </td>
                                <td>
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() => handleShowAreaU(conquest)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                  {!battleDone && (
                                    <div
                                      className='rowActionIcon'
                                      onClick={() =>
                                        unselectNFT(
                                          conquest.id,
                                          conquest.nft_identifier
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className='fa'
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        {countFactionItems == 0 && (
                          <tr className='rowTableContent textResult'>
                            {filteredArea.id_area == 0 ? (
                              <td colSpan={5}>
                                <span>No power item deployed</span>
                              </td>
                            ) : (
                              <td colSpan={5}>
                                <span>
                                  No power item deployed to the&nbsp;
                                  {filteredArea.name}
                                </span>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      <Modal
        show={showResults}
        onHide={handleCloseDeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body text-center p-2'>
            <div className='infosBox'>
              <div className='deployBoxTitle'>{selectedArea.name}</div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Result of the battle</div>
              </div>
              <div className='text-center'>
                {conquests_logs.map((logs, i) => {
                  if (logs.id_area == selectedArea.id_area) {
                    if (logs.id_faction == 0) {
                      return (
                        <div key={i} className='textImportant'>
                          The&nbsp;
                          <span className='textImportantRed'>
                            {logs.name_faction}
                          </span>
                          &nbsp;have conquered the {selectedArea.name} !
                        </div>
                      );
                    } else {
                      return (
                        <div key={i} className='textImportant'>
                          The&nbsp;
                          <span className='textImportantRed'>
                            {logs.name_faction}
                          </span>
                          &nbsp;faction has conquered the {selectedArea.name} !
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
            <div className='text-center margin10'>
              {conquests_logs.map((logs, i) => {
                if (logs.id_area == selectedArea.id_area) {
                  return (
                    <div key={i} className='itemBlock'>
                      {logs.infos.map((info, j) => {
                        let divStyle = {
                          '--rarityColor': '#000'
                        } as React.CSSProperties;

                        factions.map((faction) => {
                          if (faction.id_faction == info.id_faction) {
                            divStyle = {
                              '--rarityColor': faction.color
                            } as React.CSSProperties;
                          }
                        });

                        const tot_power = info.tot_power.toFixed(2);

                        const tot_bonus = info.bonus_value + info.malus_value;

                        let tot_bonusLabel = tot_bonus + '%';

                        let iconName;
                        let colorName;

                        if (tot_bonus == 0) {
                          colorName = 'noBonus';
                          iconName = faArrowCircleRight;
                        } else {
                          if (tot_bonus > 0) {
                            colorName = 'bonusUp';
                            tot_bonusLabel = '+' + tot_bonus + '%';
                            iconName = faArrowCircleUp;
                          } else {
                            colorName = 'bonusDown';
                            iconName = faArrowCircleDown;
                          }
                        }

                        const nameT = info.name.split(' ');

                        let faction_name_1;
                        let faction_name_2;
                        switch (nameT.length) {
                          case 1:
                            faction_name_1 = nameT[0];
                            break;
                          default:
                            faction_name_1 = nameT[0];
                            faction_name_2 = nameT[1];
                            break;
                        }

                        return (
                          <div
                            key={j}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      <span className={colorName}>
                                        <FontAwesomeIcon
                                          icon={iconName}
                                          className='iconToken'
                                        />
                                        &nbsp;
                                        <span className='bonusLabel'>
                                          {tot_bonusLabel}
                                        </span>
                                      </span>
                                    </div>
                                    <div className='cardItemPower'>
                                      Power : {tot_power}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardItemPictureBox'>
                                <img
                                  src={`${info.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!faction_name_2 ? (
                                  <div className='cardItemName'>
                                    {faction_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {faction_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {faction_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleCloseDeploy}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeploy}
        onHide={handleCloseDeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          {user.id_faction == selectedArea.id_faction ? (
            <div className='defenseBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>
                    Defend the {selectedArea.name}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='attackBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>
                    Attack the {selectedArea.name}
                  </div>
                </div>
              </div>
            </div>
          )}

          {userLeader ? (
            <div className='boxContainer'>
              <div className='card-body text-center p-2'>
                {showPlayer ? (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowPlayer}
                    >
                      Player Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowFaction}
                    >
                      Faction Mode
                    </ReactButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ReactButton
                      className='navStandardSmall'
                      onClick={handleShowPlayer}
                    >
                      Player Mode
                    </ReactButton>
                    <ReactButton
                      className='navStandardSmallReverse'
                      onClick={handleShowFaction}
                    >
                      Faction Mode
                    </ReactButton>
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : null}

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>
                  Faction Deployment Information
                </div>
              </div>
              <div className='dataContainer'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>
                    NFTs power
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect'>
                          Without ratio and bonus
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                  </span>
                  <span className='dataAttribut'>
                    {selectedArea.NFTsPowerPercent} %
                  </span>
                </div>
              </div>
            </div>
          </div>

          {showPlayer && inventory.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Inventory</div>
                  </div>
                  <div className='textImportant'>
                    Click on your items to deploy them to the&nbsp;
                    <span className='textImportantRed'>
                      {selectedArea.name}
                    </span>
                    &nbsp;for the Battle.
                    <br />
                    You can only deploy items in areas where your faction has at
                    least&nbsp;
                    <span className='textImportantRed'>{deployPercent}%</span>
                    &nbsp;of the total NFTs power deployed.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  <div className='itemBlock'>
                    {inventory.map((item, i) => {
                      const divStyle = {
                        '--rarityColor': item.itemInfos.item_rarityColor
                      } as React.CSSProperties;

                      let attack = false;
                      let defense = false;
                      switch (item.itemInfos.flg_use) {
                        case 1:
                          attack = true;
                          break;
                        case 2:
                          defense = true;
                          break;
                        default:
                          attack = true;
                          defense = true;
                          break;
                      }

                      let available = true;
                      if (attack && defense) {
                        available = true;
                      } else {
                        if (
                          attack &&
                          user.id_faction == selectedArea.id_faction
                        ) {
                          available = false;
                        }
                        if (
                          defense &&
                          user.id_faction != selectedArea.id_faction
                        ) {
                          available = false;
                        }
                      }

                      let idItem = 0;
                      let itemIdentifier = '';
                      if (item.itemsAvailableCount > 0) {
                        idItem = item.id_item;
                        itemIdentifier = item.itemsAvailable[0].identifier;
                      } else {
                        available = false;
                      }

                      let countArea = 0;
                      let areaFactionDeployedPower = 0;
                      let areaDeployable = false;
                      conquestFactionInfos.conquests.map((conquest) => {
                        if (
                          conquest.nft_type == 1 &&
                          conquest.id_faction == user.id_faction &&
                          conquest.id_area == selectedArea.id_area
                        ) {
                          countArea++;
                          if (conquest.attribut != null) {
                            areaFactionDeployedPower +=
                              conquest.attribut?.nft_power;
                          }
                        }
                      });

                      let areaFactionPowerPercent = 0;
                      if (
                        areaFactionDeployedPower > 0 &&
                        factionDeployedPower > 0
                      ) {
                        areaFactionPowerPercent =
                          (areaFactionDeployedPower / factionDeployedPower) *
                          100;
                      }
                      const areaFactionPowerPercentRounded = parseFloat(
                        areaFactionPowerPercent.toFixed(2)
                      );

                      if (areaFactionPowerPercentRounded >= deployPercent) {
                        areaDeployable = true;
                      }

                      const NFTscoreRounded =
                        item.itemInfos.item_power.toFixed(2);

                      const nameT = item.itemInfos.item_name.split(' ');

                      let item_name_1;
                      let item_name_2;
                      switch (nameT.length) {
                        case 1:
                          item_name_1 = nameT[0];
                          break;
                        case 2:
                          item_name_1 = nameT[0];
                          item_name_2 = nameT[1];
                          break;
                        default:
                          item_name_1 = nameT[0] + ' ' + nameT[1];
                          item_name_2 = nameT[2];
                          break;
                      }

                      if (available && areaDeployable) {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      {item.itemInfos.item_rarity}
                                    </div>
                                    <div className='cardItemPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardItemPictureBox'
                                onClick={() =>
                                  selectNFT(idItem, itemIdentifier)
                                }
                              >
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Deploy
                                  </div>
                                </div>
                                <div className='overlayNumber'>
                                  <span className='overlayNumberText'>x</span>
                                  <span className='overlayNumberTextNumber'>
                                    {item.itemsAvailableCount}
                                  </span>
                                </div>
                                <img
                                  src={`${item.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!item_name_2 ? (
                                  <div className='cardItemTitle'>
                                    {item_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {item_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {item_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className='cardItemUsage'>
                                {attack && (
                                  <IconContext.Provider
                                    value={{
                                      className: 'cardUsageAttack'
                                    }}
                                  >
                                    <GiCrossedSwords />
                                  </IconContext.Provider>
                                )}
                                {defense && (
                                  <IconContext.Provider
                                    value={{
                                      className: 'cardUsageDef'
                                    }}
                                  >
                                    <GiBorderedShield />
                                  </IconContext.Provider>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showPlayer && inventory.length == 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Inventory</div>
                  </div>
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any Item at the
                      moment !
                    </p>
                    <p className='textImportant'>
                      But ! you can find one by going here :
                    </p>
                  </div>
                  <div className='buttonContainer'>
                    <ReactButton
                      className='btnStandardReverse'
                      onClick={() => navigate(routeNames.market)}
                    >
                      Market
                    </ReactButton>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showFaction && userLeader && factionInventory.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Faction Inventory</div>
                  </div>
                  <div className='text-center'>
                    {showPower ? (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    )}
                  </div>
                  <div className='textImportant margin30'>
                    Click on your faction items to deploy them to the&nbsp;
                    <span className='textImportantRed'>
                      {selectedArea.name}
                    </span>
                    &nbsp;for the Battle.
                    <br />
                    You can only deploy items in areas where your faction has at
                    least&nbsp;
                    <span className='textImportantRed'>{deployPercent}%</span>
                    &nbsp;of the total NFTs power deployed.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  <div className='itemBlock'>
                    {factionInventory.map((item, i) => {
                      const divStyle = {
                        '--rarityColor': item.itemInfos.item_rarityColor
                      } as React.CSSProperties;

                      let attack = false;
                      let defense = false;
                      switch (item.itemInfos.flg_use) {
                        case 1:
                          attack = true;
                          break;
                        case 2:
                          defense = true;
                          break;
                        default:
                          attack = true;
                          defense = true;
                          break;
                      }

                      let available = true;
                      let deployable = true;
                      let countArea = 0;
                      let areaFactionDeployedPower = 0;
                      let areaDeployable = false;

                      let idItem = 0;
                      let itemIdentifier = '';
                      let bonusLabel = '';
                      let bonusValue = '';
                      let item_name_1;
                      let item_name_2;

                      if (attack && defense) {
                        available = true;
                      } else {
                        if (
                          attack &&
                          user.id_faction == selectedArea.id_faction
                        ) {
                          available = false;
                        }
                        if (
                          defense &&
                          user.id_faction != selectedArea.id_faction
                        ) {
                          available = false;
                        }
                      }

                      if (item.itemsAvailableCount > 0) {
                        idItem = item.id_item;
                        itemIdentifier = item.itemsAvailable[0].identifier;
                      } else {
                        available = false;
                      }

                      if (available) {
                        conquestFactionInfos.conquests.map((conquest) => {
                          if (
                            conquest.nft_type == 1 &&
                            conquest.id_faction == user.id_faction &&
                            conquest.id_area == selectedArea.id_area
                          ) {
                            countArea++;
                            if (conquest.attribut != null) {
                              areaFactionDeployedPower +=
                                conquest.attribut?.nft_power;
                            }
                          }

                          if (
                            conquest.nft_type == 2 &&
                            conquest.id_faction == user.id_faction &&
                            conquest.id_item == item.id_item &&
                            conquest.item?.item_type == 4
                          ) {
                            deployable = false;
                          }
                        });

                        let areaFactionPowerPercent = 0;
                        if (
                          areaFactionDeployedPower > 0 &&
                          factionDeployedPower > 0
                        ) {
                          areaFactionPowerPercent =
                            (areaFactionDeployedPower / factionDeployedPower) *
                            100;
                        }
                        const areaFactionPowerPercentRounded = parseFloat(
                          areaFactionPowerPercent.toFixed(2)
                        );

                        if (areaFactionPowerPercentRounded >= deployPercent) {
                          areaDeployable = true;
                        }

                        if (item.itemInfos.bonus_value > 0) {
                          bonusLabel = 'Faction Power';
                          bonusValue = '+' + item.itemInfos.bonus_value + '%';
                        } else {
                          bonusLabel = 'Enemy Power';
                          bonusValue = item.itemInfos.bonus_value + '%';
                        }

                        const nameT = item.itemInfos.item_name.split(' ');

                        switch (nameT.length) {
                          case 1:
                            item_name_1 = nameT[0];
                            break;
                          case 2:
                            item_name_1 = nameT[0];
                            item_name_2 = nameT[1];
                            break;
                          default:
                            item_name_1 = nameT[0] + ' ' + nameT[1];
                            item_name_2 = nameT[2];
                            break;
                        }
                      }

                      const NFTscoreRounded =
                        item.itemInfos.item_power.toFixed(2);

                      const item_type = item.itemInfos.item_type;

                      if (available && areaDeployable) {
                        if (deployable) {
                          return (
                            <div
                              key={i}
                              style={divStyle}
                              className='cardItemBlock'
                            >
                              <div className='cardItemContainer'>
                                <div className='cardItemBox'>
                                  <div className='cardItemBoxIn'>
                                    <div className='cardItemInfos'>
                                      <div className='cardItemHierarchy'>
                                        {item.itemInfos.item_rarity}
                                      </div>
                                      {item_type == 2 && (
                                        <div className='cardItemPower'>
                                          Power : {NFTscoreRounded}
                                        </div>
                                      )}
                                      {item_type == 4 && (
                                        <div className='cardItemPower'>
                                          {bonusLabel} : {bonusValue}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='cardItemPictureBox'
                                  onClick={() =>
                                    selectNFT(idItem, itemIdentifier)
                                  }
                                >
                                  <div className='overlayHoverA'>
                                    <div className='overlayHoverTextA'>
                                      Deploy
                                    </div>
                                  </div>
                                  <div className='overlayNumber'>
                                    <span className='overlayNumberText'>x</span>
                                    <span className='overlayNumberTextNumber'>
                                      {item.itemsAvailableCount}
                                    </span>
                                  </div>
                                  <img
                                    src={`${item.img}`}
                                    className='cardItemPicture'
                                  />
                                </div>
                                <div className='cardItemInfosBis'>
                                  {!item_name_2 ? (
                                    <div className='cardItemTitle'>
                                      {item_name_1}
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <div className='cardItemNumber'>
                                        {item_name_1}
                                      </div>
                                      <div className='cardItemName'>
                                        {item_name_2}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                {item_type == 2 && (
                                  <div className='cardItemUsage'>
                                    {attack && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageAttack'
                                        }}
                                      >
                                        <GiCrossedSwords />
                                      </IconContext.Provider>
                                    )}
                                    {defense && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageDef'
                                        }}
                                      >
                                        <GiBorderedShield />
                                      </IconContext.Provider>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={i}
                              style={divStyle}
                              className='cardItemBlock'
                            >
                              <div className='cardItemContainer'>
                                <div className='cardItemBox'>
                                  <div className='cardItemBoxIn'>
                                    <div className='cardItemInfos'>
                                      <div className='cardItemHierarchy'>
                                        {item.itemInfos.item_rarity}
                                      </div>
                                      {item_type == 2 && (
                                        <div className='cardItemPower'>
                                          Power : {NFTscoreRounded}
                                        </div>
                                      )}
                                      {item_type == 4 && (
                                        <div className='cardItemPower'>
                                          {bonusLabel} : {bonusValue}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='cardItemPictureBox'>
                                  <div className='overlay'>
                                    <div className='overlayText'>Deployed</div>
                                  </div>
                                  <div className='overlayNumber'>
                                    <span className='overlayNumberText'>x</span>
                                    <span className='overlayNumberTextNumber'>
                                      {item.itemsAvailableCount}
                                    </span>
                                  </div>
                                  <img
                                    src={`${item.img}`}
                                    className='cardItemPicture'
                                  />
                                </div>
                                <div className='cardItemInfosBis'>
                                  {!item_name_2 ? (
                                    <div className='cardItemTitle'>
                                      {item_name_1}
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <div className='cardItemNumber'>
                                        {item_name_1}
                                      </div>
                                      <div className='cardItemName'>
                                        {item_name_2}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                {item_type == 2 && (
                                  <div className='cardItemUsage'>
                                    {attack && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageAttack'
                                        }}
                                      >
                                        <GiCrossedSwords />
                                      </IconContext.Provider>
                                    )}
                                    {defense && (
                                      <IconContext.Provider
                                        value={{
                                          className: 'cardUsageDef'
                                        }}
                                      >
                                        <GiBorderedShield />
                                      </IconContext.Provider>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showFaction && userLeader && factionInventory.length == 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Faction Inventory</div>
                  </div>
                  <div className='text-center'>
                    {showPower ? (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ReactButton
                          className='navStandardSmall'
                          onClick={handleShowPower}
                        >
                          Power
                        </ReactButton>
                        <ReactButton
                          className='navStandardSmallReverse'
                          onClick={handleShowBonus}
                        >
                          Bonus
                        </ReactButton>
                      </React.Fragment>
                    )}
                  </div>
                  <div className='text-center margin30'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like your faction don&apos;t have any
                      Item at the moment !
                    </p>
                    <p className='textImportant'>
                      But ! you can find one by going here :
                    </p>
                  </div>
                  <div className='buttonContainer'>
                    <ReactButton
                      className='btnStandardReverse'
                      onClick={() => navigate(routeNames.factionmarket)}
                    >
                      Faction Market
                    </ReactButton>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleCloseDeploy}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showArea}
        onHide={handleCloseArea}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>
              Deployment of {selectedItem.item_name}
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='itemBlock'>
                <div style={selectedItem.divStyle} className='cardItemBlock'>
                  <div className='cardItemContainer'>
                    <div className='cardItemBox'>
                      <div className='cardItemBoxIn'>
                        <div className='cardItemInfos'>
                          <div className='cardItemHierarchy'>
                            {selectedItem.rarity}
                          </div>
                          <div className='cardItemPower'>
                            {selectedItem.item_label} :&nbsp;
                            {selectedItem.item_value}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='cardItemPictureBox'>
                      {massiveItems && (
                        <div className='overlayNumber'>
                          <span className='overlayNumberText'>x</span>
                          <span className='overlayNumberTextNumber'>
                            {selectedItem.item_qty}
                          </span>
                        </div>
                      )}
                      <img
                        src={`${selectedItem.item_img}`}
                        className='cardItemPicture'
                      />
                    </div>
                    <div className='cardItemInfosBis'>
                      {selectedItem.item_name_2 == '' ? (
                        <div className='cardItemTitle'>
                          {selectedItem.item_name_1}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className='cardItemNumber'>
                            {selectedItem.item_name_1}
                          </div>
                          <div className='cardItemName'>
                            {selectedItem.item_name_2}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {selectedItem.item_type == 2 && (
                      <div className='cardItemUsage'>
                        {selectedItem.attack && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageAttack' }}
                          >
                            <GiCrossedSwords />
                          </IconContext.Provider>
                        )}
                        {selectedItem.defense && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageDef' }}
                          >
                            <GiBorderedShield />
                          </IconContext.Provider>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {massiveItems && maxQty == 1 && (
              <div className='card-body text-center p-2'>
                <div className='containerItem'>
                  <div className='contentItemActions'>
                    <div className='contentItemBox'>
                      <div className='textImportant'>Quantity</div>
                      <ButtonGroup
                        size='small'
                        className='contentItemBoxButtons'
                      >
                        <Button disabled>{itemQty}</Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {massiveItems && maxQty > 1 && (
              <div className='card-body text-center p-2'>
                <div className='containerItem'>
                  <div className='contentItemActions'>
                    <div className='contentItemBox'>
                      <div className='textImportant'>Quantity</div>
                      <ButtonGroup
                        size='small'
                        className='contentItemBoxButtons'
                      >
                        {itemQty == 1 ? (
                          <Button className='btnDisabled' disabled>
                            -
                          </Button>
                        ) : (
                          <Button onClick={() => handleDecrementQuantity()}>
                            -
                          </Button>
                        )}
                        <Button disabled>{itemQty}</Button>
                        {itemQty == maxQty ? (
                          <Button className='btnDisabled' disabled>
                            +
                          </Button>
                        ) : (
                          <Button onClick={() => handleIncrementQuantity()}>
                            +
                          </Button>
                        )}
                      </ButtonGroup>
                    </div>
                    <div>
                      <Slider
                        size='small'
                        value={itemQty}
                        aria-label='Small'
                        min={1}
                        max={maxQty}
                        onChange={(e) => handleChangeQuantity(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Area Selection</div>
              </div>
              <div className='textImportant'>
                Click on an area to deploy&nbsp;
                <span className='textImportantRed'>
                  {selectedItem.item_name}
                </span>
                &nbsp;for the Battle.
                <br />
                You can only deploy items in areas where your faction has at
                least&nbsp;
                <span className='textImportantRed'>{deployPercent}%</span>
                &nbsp;of the total NFTs power deployed.
              </div>
            </div>
          </div>

          {selectedItem.attack ? (
            <div className='boxContainer'>
              <div className='attackBox'>
                <div className='card-body text-center p-2'>
                  <div className='infosBox'>
                    <div className='deployBoxTitle'>Attack</div>
                  </div>
                </div>
              </div>

              <div className='text-center margin10'>
                {areas.map((area, i) => {
                  let visible = false;
                  switch (area.id_area) {
                    case 1:
                    case 2:
                    case 3:
                      visible = false;
                      break;
                    default:
                      if (
                        !area.flg_battle &&
                        user.id_faction != area.id_faction
                      ) {
                        visible = true;
                      }
                      break;
                  }

                  let countArea = 0;
                  let areaFactionDeployedPower = 0;
                  let areaDeployable = false;
                  conquestFactionInfos.conquests.map((conquest) => {
                    if (
                      conquest.nft_type == 1 &&
                      conquest.id_faction == user.id_faction &&
                      conquest.id_area == area.id_area
                    ) {
                      countArea++;
                      if (conquest.attribut != null) {
                        areaFactionDeployedPower +=
                          conquest.attribut?.nft_power;
                      }
                    }
                  });

                  let areaFactionPowerPercent = 0;
                  if (
                    areaFactionDeployedPower > 0 &&
                    factionDeployedPower > 0
                  ) {
                    areaFactionPowerPercent =
                      (areaFactionDeployedPower / factionDeployedPower) * 100;
                  }
                  const areaFactionPowerPercentRounded = parseFloat(
                    areaFactionPowerPercent.toFixed(2)
                  );

                  if (areaFactionPowerPercentRounded >= deployPercent) {
                    areaDeployable = true;
                  }

                  if (visible && areaDeployable) {
                    return (
                      <ReactButton
                        className='navStandardSmall'
                        key={i}
                        onClick={() => deployNFT(area.id_area)}
                      >
                        {area.name}
                      </ReactButton>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}

          {selectedItem.defense ? (
            <div className='boxContainer'>
              <div className='defenseBox'>
                <div className='card-body text-center p-2'>
                  <div className='infosBox'>
                    <div className='deployBoxTitle'>Defend</div>
                  </div>
                </div>
              </div>

              <div className='text-center margin10'>
                {areas.map((area, i) => {
                  let visible = false;
                  switch (area.id_area) {
                    case 1:
                    case 2:
                    case 3:
                      visible = false;
                      break;
                    default:
                      if (
                        !area.flg_battle &&
                        user.id_faction == area.id_faction
                      ) {
                        visible = true;
                      }
                      break;
                  }

                  let countArea = 0;
                  let areaFactionDeployedPower = 0;
                  let areaDeployable = false;
                  conquestFactionInfos.conquests.map((conquest) => {
                    if (
                      conquest.nft_type == 1 &&
                      conquest.id_faction == user.id_faction &&
                      conquest.id_area == area.id_area
                    ) {
                      countArea++;
                      if (conquest.attribut != null) {
                        areaFactionDeployedPower +=
                          conquest.attribut?.nft_power;
                      }
                    }
                  });

                  let areaFactionPowerPercent = 0;
                  if (
                    areaFactionDeployedPower > 0 &&
                    factionDeployedPower > 0
                  ) {
                    areaFactionPowerPercent =
                      (areaFactionDeployedPower / factionDeployedPower) * 100;
                  }
                  const areaFactionPowerPercentRounded = parseFloat(
                    areaFactionPowerPercent.toFixed(2)
                  );

                  if (areaFactionPowerPercentRounded >= deployPercent) {
                    areaDeployable = true;
                  }

                  if (visible && areaDeployable) {
                    return (
                      <ReactButton
                        className='navStandardSmall'
                        key={i}
                        onClick={() => deployNFT(area.id_area)}
                      >
                        {area.name}
                      </ReactButton>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleCloseArea}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showMapBonus}
        onHide={handleCloseMapBonus}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Information</div>
          </div>

          {merchantBonus.merchant || emperorVisitBonus.emperorVisit ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Game Bonuses</div>
                </div>
                {merchantBonus.merchant ? (
                  <div className='text-center'>
                    <div className='textImportant'>
                      Merchants have appeared in the&nbsp;
                      <span className='textImportantRed'>
                        {merchantBonus.merchantAreas[0]}
                      </span>
                      &nbsp;and in the&nbsp;
                      <span className='textImportantRed'>
                        {merchantBonus.merchantAreas[1]}
                      </span>
                      &nbsp;!
                      <br />
                      <span className='textImportantRed'>
                        +{merchantBonus.merchantBonusValue}% JCORP
                      </span>
                      &nbsp;rewards for your entire faction for each area with
                      merchants you conquer
                    </div>
                  </div>
                ) : null}
                {emperorVisitBonus.emperorVisit ? (
                  <div className='text-center margin10'>
                    <div className='textImportant'>
                      The Emperor visits the&nbsp;
                      <span className='textImportantRed'>
                        {emperorVisitBonus.emperorVisitAreas[0]}
                      </span>
                      , Leaderboard points won are doubled !
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {merchantBonus.merchant || emperorVisitBonus.emperorVisit ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Legend</div>
                </div>
                <div className='legendContainer'>
                  {merchantBonus.merchant ? (
                    <div className='legendBox'>
                      <div className='legendBoxIcon'>
                        <img
                          src={`${merchantIcon}`}
                          className='legendMapIcon'
                        />
                      </div>
                      <div className='legendBoxText'>Merchant</div>
                    </div>
                  ) : null}
                  {emperorVisitBonus.emperorVisit ? (
                    <div className='legendBox'>
                      <div className='legendBoxIcon'>
                        <img src={`${emperorIcon}`} className='legendEmpIcon' />
                      </div>
                      <div className='legendBoxText'>Emperor</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='statusWarning'>
                The Battle Phase has already begun, you must wait for the next
                Conquest to deploy your NFTs.
                <br />
                You can deploy your items from your Inventory to influence
                upcoming battles.
                <br />
                You can only deploy items in areas where your faction has at
                least&nbsp;
                <span className='textRed'>{deployPercent}%</span>
                &nbsp;of the total NFTs power deployed.
                <div className='margin10'>
                  You can see your NFTs in your&nbsp;
                  <a href={routeNames.nftswallet} rel='noreferrer'>
                    NFTs Wallet
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleCloseMapBonus}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSettings}
        onHide={handleCloseSettings}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Conquest Menu</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>Conquest</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.conquestboard)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Deployment</div>
                  </div>
                </div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.historyboard)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>History</div>
                  </div>
                </div>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.rewards)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Rewards</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>NFTs</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.squads)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Squads</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>Miscellaneous</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.rules)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Rules</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleCloseSettings}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Battle;

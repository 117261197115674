import * as React from 'react';
import { Pagination } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { network, tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import ItemsDataService from '../../../services/items.service';
import ShopsDataService from '../../../services/shops.service';
import { ItemsStateType } from '../../../types/items.type';
import { ShopsStateType } from '../../../types/shops.type';

const MarketplaceHistory = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { explorerAddress } = network;
  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const pageSize = 50;

  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });
  const [shopsInfos, setShopsInfos] = React.useState<ShopsStateType>({
    totalItems: 0,
    totalPages: 0,
    shops: [],
    message: '',
    status: ''
  });

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const fetchShopsData = () => {
    const params: any = {};
    params['page'] = page - 1;
    params['size'] = pageSize;

    ShopsDataService.findAllByUser(userSession.userID, params).then(
      ({ data }) => {
        if (data.status == 'success') {
          setShopsInfos({
            totalItems: data.totalItems,
            totalPages: data.totalPages,
            shops: data.shops,
            status: data.status
          });
        }
      }
    );
  };

  React.useEffect(fetchItemsData, []);
  React.useEffect(() => {
    fetchShopsData();
  }, [page]);

  const { items } = itemsInfos;
  const { totalPages, shops } = shopsInfos;

  return (
    <>
      <div className='boxContainer topContainer' ref={refToTop}>
        <div className='card-body text-center p-2'>
          {userSession.isBlockchainAccount ? (
            <div className='table-responsive'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Date</th>
                    <th className='border-0'>Item</th>
                    <th className='border-0'>Quantity</th>
                    <th className='border-0'>Amount</th>
                    <th className='border-0'>Status</th>
                    <th className='border-0'>Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  {shops.map((shop, i) => {
                    let token_name = '';
                    let item_name = '';

                    if (shop.token_identifier == 'EGLD') {
                      token_name = 'EGLD';
                    } else {
                      tokensListNames.map((token) => {
                        if (shop.token_identifier == token.token_identifier) {
                          token_name = token.name;
                        }
                      });
                    }
                    items.map((item) => {
                      if (item.id_item == shop.id_item) {
                        item_name = item.item_name;
                      }
                    });

                    let timeDate = '';
                    if (shop.createdAt != null) {
                      const time = new Date(shop.createdAt);
                      timeDate =
                        time.toLocaleDateString() +
                        ' ' +
                        time.toLocaleTimeString();
                    }

                    const price = shop.amount.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 3
                    });
                    const link = explorerAddress + '/transactions/' + shop.tx;
                    const flg_blockchain = shop.flg_blockchain;

                    let badgeClass = '';
                    switch (shop.status) {
                      case 'pending':
                        badgeClass = 'badgePending';
                        break;
                      case 'success':
                        badgeClass = 'badgeSuccess';
                        break;
                      case 'fail':
                      case 'failed':
                        badgeClass = 'badgeFail';
                        break;
                      default:
                        badgeClass = 'badgeDefault';
                        break;
                    }

                    return (
                      <tr key={i} className='rowTableContent textResult'>
                        <td>
                          <span>{timeDate}</span>
                        </td>
                        <td>
                          <span>{item_name}</span>
                        </td>
                        <td>
                          <span>{shop.quantity}</span>
                        </td>
                        <td>
                          <span>
                            {price} {token_name}
                          </span>
                        </td>
                        <td>
                          <span className={badgeClass}>
                            <span className='badgeInfos'>{shop.status}</span>
                          </span>
                        </td>
                        <td>
                          {flg_blockchain ? (
                            <a href={link} target='_blank' rel='noreferrer'>
                              <div className='buttonContentTab buttonBlue'>
                                See transaction
                              </div>
                            </a>
                          ) : (
                            <span>/</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {shops.length == 0 && (
                    <tr className='rowTableContent textResult'>
                      <td colSpan={6}>
                        <span>No market purchase</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='transactions table pb-3'>
                <thead>
                  <tr className='headTable'>
                    <th className='border-0'>Date</th>
                    <th className='border-0'>Item</th>
                    <th className='border-0'>Quantity</th>
                    <th className='border-0'>Amount</th>
                    <th className='border-0'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shops.map((shop, i) => {
                    let token_name = '';
                    let item_name = '';

                    if (shop.token_identifier == 'EGLD') {
                      token_name = 'EGLD';
                    } else {
                      tokensListNames.map((token) => {
                        if (shop.token_identifier == token.token_identifier) {
                          token_name = token.name;
                        }
                      });
                    }
                    items.map((item) => {
                      if (item.id_item == shop.id_item) {
                        item_name = item.item_name;
                      }
                    });

                    let timeDate = '';
                    if (shop.createdAt != null) {
                      const time = new Date(shop.createdAt);
                      timeDate = time.toLocaleDateString();
                    }

                    const price = shop.amount.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 3
                    });

                    let badgeClass = '';
                    switch (shop.status) {
                      case 'pending':
                        badgeClass = 'badgePending';
                        break;
                      case 'success':
                        badgeClass = 'badgeSuccess';
                        break;
                      case 'fail':
                      case 'failed':
                        badgeClass = 'badgeFail';
                        break;
                      default:
                        badgeClass = 'badgeDefault';
                        break;
                    }

                    return (
                      <tr key={i} className='rowTableContent textResult'>
                        <td>
                          <span>{timeDate}</span>
                        </td>
                        <td>
                          <span>{item_name}</span>
                        </td>
                        <td>
                          <span>{shop.quantity}</span>
                        </td>
                        <td>
                          <span>
                            {price} {token_name}
                          </span>
                        </td>
                        <td>
                          <span className={badgeClass}>
                            <span className='badgeInfos'>{shop.status}</span>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  {shops.length == 0 && (
                    <tr className='rowTableContent textResult'>
                      <td colSpan={5}>
                        <span>No market purchase</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <React.Fragment>
          {totalPages > 1 && (
            <div className='paginBoxBottom'>
              <Pagination
                className='paginContainer'
                count={totalPages}
                defaultPage={1}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                shape='rounded'
                onChange={handlePageChange}
              />
            </div>
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default MarketplaceHistory;

import * as React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import Inventory from './Inventory';
import NFTsWallet from './NFTsWallet';
import Profile from './Profile';
import Wallet from './Wallet';

const defaultContent = 'profile';

const navigationAccount = [
  { title: 'Profile', content: 'profile', href: '/account/profile' },
  { title: 'Wallet & Operations', content: 'wallet', href: '/account/wallet' },
  { title: 'Inventory', content: 'inventory', href: '/account/inventory' },
  { title: 'My NFTs', content: 'nfts', href: '/account/nfts' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Account = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();

  const { handleLogOut } = useAuthContext();

  const editMode = Boolean(new URLSearchParams(search).get('edit'));
  const [accountContent, setAccountContent] = React.useState(defaultContent);

  const [displayMenu, setDisplayMenu] = React.useState(true);
  const [displayContent, setDisplayContent] = React.useState(false);

  const handleChangeContent = (parameters: any) => {
    if (parameters.category != null) {
      setAccountContent(parameters.category);
      setDisplayMenu(false);
      setDisplayContent(true);
    } else {
      setAccountContent(defaultContent);
      setDisplayMenu(true);
      setDisplayContent(false);
    }
  };

  React.useEffect(() => {
    handleChangeContent(params);
  }, [params]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div
            className={classNames(
              displayMenu ? 'contentMenuShow' : 'contentMenuHide'
            )}
          >
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='pageHeading'>
                  <span>My Account</span>
                </div>

                <div className='bigBox'>
                  <div className='btnDisconnectContainer'>
                    <div onClick={handleLogOut} className='btnDisconnect'>
                      <div className='btnDisconnectIn'>
                        <div className='btnDisconnectText'>Disconnect</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='boxWrapperContainer'>
                  <div className='tabsWrapper'>
                    <div className='tabsWrapperIn'>
                      <ul className='tabsWrapperContainer'>
                        {navigationAccount.map((item) => (
                          <li
                            className={classNames(
                              item.content == accountContent
                                ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                                : 'tabsWrapperContainerItems'
                            )}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            key={item.content}
                          >
                            {item.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className='tabsWrapperInMobileAccount'>
                    <div className='tabsWrapperContainerMobile'>
                      {navigationAccount.map((item) => (
                        <div
                          className='tabsWrapperContainerItems'
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          <div className='wrapperItem'>{item.title}</div>

                          <div className='wrapperIcon'>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className='boxContainer'>
                      <div onClick={handleLogOut} className='btnDisconnect'>
                        <div className='btnDisconnectIn'>
                          <div className='btnDisconnectText'>Disconnect</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              displayContent ? 'contentWrapperShow' : 'contentWrapperHide'
            )}
          >
            {accountContent != null ? (
              <React.Fragment>
                {accountContent == '' ? <Profile editMode={false} /> : null}
                {accountContent == 'profile' ? (
                  <Profile editMode={editMode} />
                ) : null}
                {accountContent == 'wallet' ? <Wallet /> : null}
                {accountContent == 'inventory' ? <Inventory /> : null}
                {accountContent == 'nfts' ? <NFTsWallet /> : null}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;

import * as React from 'react';
import { faInfoCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slider, TextField, MenuItem } from '@mui/material';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import FactionDataService from '../../../services/faction.service';
import UserDataService from '../../../services/user.service';
import { FactionsStateType } from '../../../types/faction.type';
import { IUserData } from '../../../types/user.type';
import angelic from './../../../assets/img/angelic.png';
import meiyo from './../../../assets/img/meiyo.png';
import night from './../../../assets/img/night.png';

const Profile = ({ editMode }: { editMode: boolean }) => {
  const { userSession, userInfos, setUserInfos } = useAuthContext();

  const [flgUpdateProfile, setFlgUpdateProfile] =
    React.useState<boolean>(editMode);

  const navigate = useNavigate();

  const [message, setMessage] = React.useState<string>();

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });

  const [factionImg, setFactionImg] = React.useState<string>('');

  const [userPseudo, setUserPseudo] = React.useState<string>('');
  const [userFaction, setUserFaction] = React.useState<number>(0);
  const [userDelegation, setUserDelegation] = React.useState<boolean>(false);
  const [userContribution, setUserContribution] = React.useState<number>(5);

  const marks = [
    {
      value: 5,
      label: '5 %'
    },
    {
      value: 10
    },
    {
      value: 15
    },
    {
      value: 20
    },
    {
      value: 25
    },
    {
      value: 30
    },
    {
      value: 35
    },
    {
      value: 40
    },
    {
      value: 45
    },
    {
      value: 50
    },
    {
      value: 55
    },
    {
      value: 60
    },
    {
      value: 65
    },
    {
      value: 70
    },
    {
      value: 75
    },
    {
      value: 80
    },
    {
      value: 85
    },
    {
      value: 90
    },
    {
      value: 95
    },
    {
      value: 100,
      label: '100 %'
    }
  ];

  const handleChangeContribution = (e: any) => {
    setUserContribution(e.target.value);
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchUserInfos = () => {
    setUserPseudo(userInfos.user.pseudo);
    setUserFaction(userInfos.user.id_faction);
    setUserDelegation(userInfos.user.flg_delegation);
    setUserContribution(userInfos.user.faction_contribution);

    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const manageProfile = (user: IUserData) => {
    setUserPseudo(user.pseudo);
    setUserFaction(user.id_faction);
    setUserDelegation(user.flg_delegation);
    setUserContribution(user.faction_contribution);

    setFlgUpdateProfile(true);
  };

  const updateUserData = () => {
    setMessage('');

    const dataUser: any = {
      pseudo: userPseudo,
      id_faction: userFaction,
      flg_delegation: userDelegation,
      faction_contribution: userContribution
    };

    UserDataService.update(dataUser, userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setUserInfos({
          user: data.user,
          status: data.status
        });
        if (userInfos.user.id_faction == 0) {
          navigate(routeNames.conquest);
        } else {
          setFlgUpdateProfile(false);
        }
      } else {
        setMessage(data.message);
      }
    });
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleBack = () => {
    setFlgUpdateProfile(false);

    handleScrollTop();
  };

  React.useEffect(fetchFactionsData, []);

  React.useEffect(() => {
    if (userInfos.user.id_user != 0) {
      fetchUserInfos();
    }
  }, [userInfos]);

  const { user } = userInfos;
  const { factions } = factionsInfos;

  return (
    <>
      <div className='smallBox'>
        <div className='boxContainer'>
          <div className='card-body p-2'>
            <div className='menuWrapper'>
              <div className='menuBackTitle'>
                <span>Profile</span>
              </div>

              <div
                className='menuBack'
                onClick={() => navigate(routeNames.account)}
              >
                <span className='menuBackIcon'>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>

      {!flgUpdateProfile ? (
        <>
          {userInfos.status == 'failed' ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{userInfos.message}</div>
              </div>
            </div>
          ) : null}

          {userInfos.status == 'success' ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <p className='separtorLine'>
                  <span className='textTitle'>Pseudo : </span>
                  <span className='textAttribut'>{user.pseudo}</span>
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>Faction : </span>
                  <span className='textAttribut'>{user.faction?.name}</span>
                  <img src={`${factionImg}`} className='imgFactionProfile' />
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>
                    Delegation
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          If you activate it, you&apos;ll give your faction
                          leaders the ability to strategically redeploy to
                          another area your deployed NFTs.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                    &nbsp;&nbsp;:
                  </span>
                  &nbsp;
                  {user.flg_delegation ? (
                    <span className='textAttribut'>YES</span>
                  ) : (
                    <span className='textAttribut'>NO</span>
                  )}
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>
                    Faction contribution
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          Percentage of conquest rewards you share with your
                          faction.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                    &nbsp;&nbsp;:
                  </span>
                  &nbsp;
                  <span className='textAttribut'>
                    {user.faction_contribution} %
                  </span>
                </p>

                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={() => manageProfile(user)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      {flgUpdateProfile ? (
        <>
          {userInfos.status == 'failed' ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{userInfos.message}</div>
              </div>
            </div>
          ) : null}

          {userInfos.status == 'success' ? (
            <>
              {message ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='statusAlert'>
                      An error has occurred while updating profile :
                      <br />
                      {message}
                    </div>
                  </div>
                </div>
              ) : null}

              {user.address != '' && user.id_faction == 0 ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='statusWarning'>
                      You have to choose your faction.
                      <br />
                      Take care, this choice is definitive !
                    </div>
                  </div>
                </div>
              ) : null}

              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <Form.Group className='separtorLine'>
                    <Form.Row>
                      <Form.Label className='textTitle' column xs='auto'>
                        Pseudo :
                      </Form.Label>
                      <Col xs='auto'>
                        <Form.Control
                          className='textAttribut'
                          type='text'
                          value={userPseudo}
                          onChange={(e) => setUserPseudo(e.target.value)}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  {user.id_faction != 0 ? (
                    <p className='separtorLine'>
                      <span className='textTitle'>Faction : </span>
                      <span className='textAttribut'>{user.faction?.name}</span>
                      <img
                        src={`${factionImg}`}
                        className='imgFactionProfile'
                      />
                    </p>
                  ) : (
                    <Form.Group>
                      <Form.Row className='separtorLine'>
                        <Form.Label className='textTitle' column xs='auto'>
                          Faction :
                        </Form.Label>
                        <Col xs='auto'>
                          <TextField
                            select
                            value={userFaction}
                            variant='standard'
                            onChange={(e) =>
                              setUserFaction(parseInt(e.target.value))
                            }
                          >
                            <MenuItem value='0'>Select a faction...</MenuItem>
                            {factions.map((faction, i) => {
                              return (
                                <MenuItem key={i} value={faction.id_faction}>
                                  {faction.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  )}

                  <Form.Group className='separtorLine'>
                    <Form.Row>
                      <Form.Label className='textTitle' column xs='auto'>
                        Delegation
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip
                              id='tooltipConnect'
                              className='textTooltip'
                            >
                              If you activate it, you&apos;ll give your faction
                              leaders the ability to strategically redeploy to
                              another area your deployed NFTs.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className='iconInfo'
                          />
                        </OverlayTrigger>
                        &nbsp;&nbsp;:
                      </Form.Label>
                      <Col xs='auto'>
                        <div className='switchBtn'>
                          <BootstrapSwitchButton
                            checked={userDelegation}
                            onChange={(checked: boolean) =>
                              setUserDelegation(checked)
                            }
                            width={60}
                            onlabel='YES'
                            offlabel='NO'
                          />
                        </div>
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  <Form.Group className='separtorLine'>
                    <Form.Row>
                      <Form.Label column xs='auto'>
                        <span className='textTitle'>
                          Faction contribution
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip
                                id='tooltipConnect'
                                className='textTooltip'
                              >
                                Percentage of conquest rewards you share with
                                your faction.
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className='iconInfo'
                            />
                          </OverlayTrigger>
                          &nbsp;&nbsp;:
                        </span>
                        <span className='textAttribut'>
                          &nbsp;{userContribution} %
                        </span>
                      </Form.Label>
                    </Form.Row>
                    <Form.Row>
                      <div className='ProfileSlider'>
                        <Slider
                          value={userContribution}
                          aria-label='Small'
                          step={5}
                          marks={marks}
                          min={5}
                          max={100}
                          onChange={(e) => handleChangeContribution(e)}
                        />
                      </div>
                    </Form.Row>
                  </Form.Group>

                  <div className='buttonContainer'>
                    <Button className='btnStandardSmall' onClick={handleBack}>
                      Cancel
                    </Button>
                    <Button
                      className='btnStandardSmallReverse'
                      onClick={updateUserData}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Profile;

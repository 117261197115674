import * as React from 'react';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import FactionDataService from '../../services/faction.service';
import FactionsInventoriesDataService from '../../services/factionsinventories.service';
import { FactionStateType, factionEmpty } from '../../types/faction.type';
import {
  FactionInventoryStateType,
  FactionInventoryInfos,
  factionInventoryEmpty
} from '../../types/factionsinventories.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const defaultContent = 'factioninventory';

const navigationConquest = [
  { title: 'Inventory', content: 'inventory', href: '/inventory' },
  {
    title: 'Faction Inventory',
    content: 'factioninventory',
    href: '/factioninventory'
  }
];

const tabItems = [
  { id: 0, title: 'Power' },
  { id: 1, title: 'Bonus' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const FactionInventory = () => {
  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [accountContent, setAccountContent] = React.useState(defaultContent);

  const [activeTab, setActiveTab] = React.useState(0);
  const [showPower, setShowPower] = React.useState(true);
  const [showBonus, setShowBonus] = React.useState(false);
  const handleShow = (id: number) => {
    setActiveTab(id);

    switch (id) {
      case 0:
        setShowPower(true);
        setShowBonus(false);
        break;
      case 1:
        setShowPower(false);
        setShowBonus(true);
        break;
    }
  };

  const handleShowPower = () => {
    setShowPower(true);
    setShowBonus(false);
  };
  const handleShowBonus = () => {
    setShowPower(false);
    setShowBonus(true);
  };

  const [factionImg, setFactionImg] = React.useState('');
  const [factionInfos, setFactionInfos] = React.useState<FactionStateType>({
    faction: factionEmpty,
    message: '',
    status: ''
  });
  const [inventoryInfos, setInventoryInfos] =
    React.useState<FactionInventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [inventoryGrpInfos, setInventoryGrpInfos] =
    React.useState<FactionInventoryInfos>({
      factionInventory: []
    });

  const fetchFactionsData = () => {
    FactionDataService.findOneById(userInfos.user.id_faction).then(
      ({ data }) => {
        if (data.status == 'success') {
          setFactionInfos({
            faction: data.faction,
            status: data.status
          });
        }
      }
    );
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const fetchInventoryData = () => {
    FactionsInventoriesDataService.findAllByFaction(
      userInfos.user.id_faction
    ).then(({ data }) => {
      if (data.status == 'success') {
        setInventoryInfos({
          inventory: data.inventory,
          status: data.status
        });
      }
    });
  };

  const handleInventoryGrpInfos = () => {
    let inventory_item_type = 0;
    if (showPower) inventory_item_type = 2;
    if (showBonus) inventory_item_type = 4;

    let prevIdItem = 0;
    let itemCount = 0;
    let dataInventory = factionInventoryEmpty;
    const inventory: FactionInventoryInfos = {
      factionInventory: []
    };
    inventoryInfos.inventory.map((item) => {
      if (item.id_item != prevIdItem) {
        if (prevIdItem != 0 && itemCount > 0) {
          inventory.factionInventory.push(dataInventory);
        }

        prevIdItem = item.id_item;
        itemCount = 0;

        dataInventory = {
          id_item: item.id_item,
          img: item.item.item_img,
          itemInfos: item.item,
          itemsAvailableCount: 0,
          itemsAvailable: []
        };
      }
      if (!item.flg_used && item.item.item_type == inventory_item_type) {
        itemCount++;
        dataInventory.itemsAvailableCount++;
        dataInventory.itemsAvailable.push({
          identifier: item.identifier
        });
      }
    });
    if (prevIdItem != 0 && itemCount > 0) {
      inventory.factionInventory.push(dataInventory);
    }
    setInventoryGrpInfos(inventory);
  };

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchFactionsData();
      fetchFactionInfos();
      fetchInventoryData();
    }
  }, []);

  React.useEffect(() => {
    if (inventoryInfos.status == 'success') {
      handleInventoryGrpInfos();
    }
  }, [inventoryInfos, showPower, showBonus]);

  const { faction } = factionInfos;
  const { factionInventory } = inventoryGrpInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageHeading'>
                <h2>Conquest {faction.name}</h2>
                <span>Faction Inventory</span>
              </div>

              <div className='boxWrapperContainer'>
                <div className='tabsWrapper'>
                  <div className='tabsWrapperIn'>
                    <ul className='tabsWrapperContainer'>
                      {navigationConquest.map((item) => (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='tabsWrapperInMobile'>
                  <div className='tabsWrapperContainerMobile'>
                    {navigationConquest.map((item) => {
                      return (
                        <li
                          className={classNames(
                            item.content == accountContent
                              ? 'tabsWrapperContainerItems tabsWrapperContainerItemsActive'
                              : 'tabsWrapperContainerItems'
                          )}
                          onClick={() => {
                            navigate(item.href);
                          }}
                          key={item.content}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='inventoryTabsWrapper'>
              <div className='inventoryTabsLine'></div>
              <div className='inventoryTabsItemsWrapper'>
                {tabItems.map((item) => (
                  <div
                    className={classNames(
                      item.id == activeTab
                        ? 'inventoryTabsItems inventoryTabsItemsActive'
                        : 'inventoryTabsItems'
                    )}
                    onClick={() => handleShow(item.id)}
                    key={item.id}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
              <div className='inventoryTabsLine'></div>
            </div>
          </div>

          {inventoryInfos.status == 'success' && (
            <React.Fragment>
              {factionInventory.length > 0 ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='itemBlock'>
                      {factionInventory.map((item, i) => {
                        const divStyle = {
                          '--rarityColor': item.itemInfos.item_rarityColor
                        } as React.CSSProperties;

                        let attack = false;
                        let defense = false;
                        switch (item.itemInfos.flg_use) {
                          case 1:
                            attack = true;
                            break;
                          case 2:
                            defense = true;
                            break;
                          default:
                            attack = true;
                            defense = true;
                            break;
                        }

                        const NFTscoreRounded =
                          item.itemInfos.item_power.toFixed(2);

                        let bonusLabel = '';
                        let bonusValue = '';
                        if (item.itemInfos.bonus_value > 0) {
                          bonusLabel = 'Faction Power';
                          bonusValue = '+' + item.itemInfos.bonus_value + '%';
                        } else {
                          bonusLabel = 'Enemy Power';
                          bonusValue = item.itemInfos.bonus_value + '%';
                        }

                        const nameT = item.itemInfos.item_name.split(' ');

                        let item_name_1;
                        let item_name_2;
                        switch (nameT.length) {
                          case 1:
                            item_name_1 = nameT[0];
                            break;
                          case 2:
                            item_name_1 = nameT[0];
                            item_name_2 = nameT[1];
                            break;
                          default:
                            item_name_1 = nameT[0] + ' ' + nameT[1];
                            item_name_2 = nameT[2];
                            break;
                        }

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      {item.itemInfos.item_rarity}
                                    </div>
                                    {item.itemInfos.item_type == 2 && (
                                      <div className='cardItemPower'>
                                        Power : {NFTscoreRounded}
                                      </div>
                                    )}
                                    {item.itemInfos.item_type == 4 && (
                                      <div className='cardItemPower'>
                                        {bonusLabel} : {bonusValue}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='cardItemPictureBox'>
                                <div className='overlayNumber'>
                                  <span className='overlayNumberText'>x</span>
                                  <span className='overlayNumberTextNumber'>
                                    {item.itemsAvailableCount}
                                  </span>
                                </div>
                                <img
                                  src={`../${item.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!item_name_2 ? (
                                  <div className='cardItemTitle'>
                                    {item_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {item_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {item_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              {item.itemInfos.item_type == 2 && (
                                <div className='cardItemUsage'>
                                  {attack && (
                                    <IconContext.Provider
                                      value={{ className: 'cardUsageAttack' }}
                                    >
                                      <GiCrossedSwords />
                                    </IconContext.Provider>
                                  )}
                                  {defense && (
                                    <IconContext.Provider
                                      value={{ className: 'cardUsageDef' }}
                                    >
                                      <GiBorderedShield />
                                    </IconContext.Provider>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxContainer topContainer'>
                  <div className='card-body text-center p-2'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p>
                      Oops... it looks like your faction don&apos;t have any
                      Item at the moment !
                    </p>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default FactionInventory;
